
.da-testimonial-container {
    overflow: hidden;
  }
  .da-testimonial-container .testimonial-body {
    padding: 30px;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
  .da-testimonial-container .author-image {
    width: 90px;
    height: 90px;
    margin: auto;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
  }
  .da-testimonial-container .author-image img {
    width: 100%;
  }
  @media (min-width: 576px) {
    .da-testimonial-container .author-image {
      margin: unset;
    }
  }
  .da-testimonial-container .testimonial-content {
    width: calc(100% - 0px);
    text-align: center;
    padding-left: 20px;
    position: relative;
  }
  .da-testimonial-container .testimonial-content .qoute-icon {
    position: absolute;
    left: 20px;
    top: -15px;
    fill: #008dd2;
    opacity: 0.2;
    font-size: 50px;
  }
  .da-testimonial-container .testimonial-content .rating {
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
    margin-top: 15px;
  }
  .da-testimonial-container .testimonial-content .rating svg {
    fill: #008dd2;
  }
  .da-testimonial-container .testimonial-content .text {
    margin-bottom: 10px;
  }
  .da-testimonial-container .testimonial-content .name {
    font-weight: 600;
  }
  .da-testimonial-container .testimonial-content .designation {
    font-size: 14px;
    color: #d1d1d1;
  }
  @media (min-width: 576px) {
    .da-testimonial-container .testimonial-content {
      width: calc(100% - 90px);
      text-align: left;
    }
    .da-testimonial-container .testimonial-content .rating {
      margin-top: 0;
    }
  }