



.header-top {
    padding-left: 110px;
    padding-right: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #76777d;
  }

  .site-info {
    -webkit-display: flex;
    display: flex;
}

.site-info {
    -webkit-display: flex;
    display: flex;
  }
  .site-info li {
    padding: 10px 0px;
    margin-right: 45px;
    color: #898989;
  }
  .site-info li a {
    color: inherit;
    font-size: 14px;
  }
  .site-info li a:hover {
    color: #008dd2;
  }
  .site-info li:last-child {
    margin-right: 0;
  }
  .site-info li i {
    margin-right: 7px;
    color: #898989;
  }
  
  .signin-option {
    margin-right: 25px;
  }
  .signin-option a {
    color: #898989;
    font-size: 14px;
  }
  .signin-option a:hover {
    color: #008dd2;
  }
  .signin-option span {
    color: #555555;
    margin: 0 6px;
  }
  
  .language-option {
    margin-right: 20px;
    position: relative;
  }
  .language-option a {
    color: #898989;
    outline: none;
    border: none;
    background: transparent;
    font-size: 14px;
  }
  .language-option:hover>a {
    color: #008dd2;
  }

  .language-container {
    position: absolute;
    left: 0;
    top: 150%;
    min-width: 120px;
    background-color: white;
    box-shadow: 0 0 3px 0 rgba(black, 0.1);
    z-index: 2;
    border-radius: 3px;
    padding: 10px 0;
    text-align: left;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
  .language-container a {
    display: block;
    padding: 7px 15px;
    transition: all 0.3s ease;
    font-size: 14px;
  }
  .language-container a:hover {
    color: #008dd2;
    background-color: #f8f8f8;
    padding-left: 20px;
  }
  .language-option:hover .language-container {
    opacity: 1;
    visibility: visible;
    top: 140%;
  }

  
  .search-option {
    padding: 0 10px;
    color: #898989;
    margin-right: 10px;
    cursor: pointer;
    font-size: 14px;
  }

  .social-media-list li a {
    padding: 10px;
    color: #898989;
    font-size: 14px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .social-media-list li a:hover {
    color: #008dd2;
  }
  .social-media-list li:last-child a {
    padding-right: 0px;
  }


