

.bz-pricing-section {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (min-width: 576px) {
    .bz-pricing-section {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .bz-pricing-section .section-header {
    padding-bottom: 80px;
  }
  .bz-pricing-section .section-header .title:after {
    left: 50%;
    margin-left: -17px;
  }
  .bz-pricing-section .bz-pricing-item .pricing-footer .custom-btn1:hover {
    color: #fff !important;
  }
  
  .bz-pricing-item {
    background-color: #fff;
    padding: 60px 20px;
    border: 1px solid #ececec;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(46, 60, 64, 0.05);
    margin-bottom: 30px;
    text-align: center;
  }
  @media (min-width: 768px) {
    .bz-pricing-item {
      padding: 40px 50px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      text-align: left;
    }
  }
  .bz-pricing-item:last-child {
    margin-bottom: 0;
  }
  .bz-pricing-item .pricing-head {
    margin-bottom: 20px;
    flex-grow: 1;
  }
  @media (min-width: 768px) {
    .bz-pricing-item .pricing-head {
      margin-bottom: 0;
    }
  }
  .bz-pricing-item .pricing-head .name {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 1.5;
    color: #33414a;
  }
  .bz-pricing-item .pricing-head .price {
    font-size: 48px;
    margin-bottom: 0;
    font-weight: 400;
  }
  .bz-pricing-item .pricing-head .per-time {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.5;
    color: #33414a;
  }
  .bz-pricing-item .pricing-body {
    margin-bottom: 20px;
  }
  @media (min-width: 768px) {
    .bz-pricing-item .pricing-body {
      flex-grow: 2;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-bottom: 0;
    }
  }
  .bz-pricing-item .pricing-body .item-listing {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
  }
  @media (min-width: 992px) {
    .bz-pricing-item .pricing-body .item-listing {
      width: 280px;
    }
  }
  .bz-pricing-item .pricing-body .item-listing li {
    line-height: 2.5;
  }
  .bz-pricing-item .pricing-body .item-listing li svg {
    margin-right: 10px;
    fill: #008dd2;
  }
  @media (min-width: 768px) {
    .bz-pricing-item .pricing-footer {
      flex-grow: 1;
      text-align: right;
    }
  }
  .bz-pricing-item .pricing-footer .custom-btn1 {
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 500;
  }
  .bz-pricing-item .pricing-footer .custom-btn1:hover {
    color: #fff !important;
  }