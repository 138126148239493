.bz-section-header.center-styled .title:after, .bz-section-header.text-center .title:after {
    left: 50%;
    margin-left: -17px;
}
.project-container {
    padding-left: 30px;
    padding-right: 30px;
}
.project-container .react-tabs__tab-list {
    border: none;
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.project-container .react-tabs__tab-list .react-tabs__tab {
    border: none;
    display: inline-block;
    text-transform: capitalize;
    padding: 5px;
    color: #2e3c40;
    background-color: transparent;
    font-weight: 700;
    cursor: pointer;
    margin: 6px;
    opacity: 0.5;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.project-container .react-tabs__tab-list .react-tabs__tab:first-child {
  padding-left: 0;
}
.project-container .react-tabs__tab-list .react-tabs__tab:focus {
  box-shadow: none;
}
.project-container .react-tabs__tab-list .react-tabs__tab:last-child {
  padding-right: 0;
}

.project-container .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
    opacity: 1;
}

.project-container .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected:after {
    content: "";
    position: absolute;
    left: 15px;
    bottom: 5px;
    width: 23px;
    height: 2px;
    background-color: #008dd2;
}
@media (min-width: 992px) {
  .project-container .react-tabs__tab-list .react-tabs__tab {
    padding: 15px;
  }
}

.project-container .project-item {
    margin-bottom: 30px;
}
.project-container .project-item .project-thumb {
    position: relative;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    overflow: hidden;
  }
  .project-container .project-item .project-thumb:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
  .project-container .project-item .project-thumb img {
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .project-container .project-item .project-thumb .project-content {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    padding: 20px;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    text-align: left;
  }
  .project-container .project-item .project-thumb .project-content .project-content-inner {
    background-color: #fff;
    padding: 35px 25px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
  .project-container .project-item .project-thumb .project-content .project-content-inner .cata {
    margin-left: 32px;
    position: relative;
    margin-bottom: 0px;
    font-size: 18px;
    color: #151a1b;
  }
  .project-container .project-item .project-thumb .project-content .project-content-inner .cata:after {
    content: "";
    position: absolute;
    top: 50%;
    left: -31px;
    width: 24px;
    height: 1px;
    background-color: #008dd2;
    z-index: 1;
  }
  .project-container .project-item .project-thumb .project-content .project-content-inner .title {
    font-weight: 600;
    margin-top: 0;
    font-size: 18px;
  }
  .project-container .project-item .project-thumb .project-content .project-content-inner .title a {
    font-weight: inherit;
    color: inherit;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .project-container .project-item .project-thumb .project-content .project-content-inner .title a:hover {
    color: #008dd2;
  }
  .project-container .project-item:hover .project-content {
    top: 0;
    opacity: 1;
  }
  .project-container .project-item:hover .project-thumb:after {
    opacity: 1;
  }
  .project-container .project-item:hover .project-thumb img {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
  }