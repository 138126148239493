
.contact-info {
    padding: 40px 60px;
  }
  .contact-item {
      text-align: left;
  }
  .contact-item .contact-icon {
    float: left;
    color: #fff;
    font-size: 20px;
  }
  .contact-item .contact-content {
    margin-left: 40px;
  }
  .contact-item .contact-content .name {
    font-size: 16px;
    color: #fff;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .contact-item .contact-content p {
    margin-bottom: 0;
    color: #fff;
    line-height: 1.7;
  }