
.footer.footer1 .footertop .container {
    border-bottom: 1px solid #ececec;
    padding-top: 60px;
    padding-bottom: 0px;
  }
  @media (min-width: 992px) {
    .footer.footer1 .footertop .container {
      padding-top: 25px;
      padding-bottom: 0px;
    }
  }
  .footer.footer1 .footerbottom {
    padding: 15px 20px;
  }
  @media (min-width: 768px) {
    .footer.footer1 .footerbottom {
      padding: 20px 0;
    }
  }
  .footer.footer1 .footerbottom .copy-right {
    color: #2e3c40;
    text-align: center;
  }
  .footer.footer1 .footerbottom .copy-right a {
    color: #2e3c40;
    font-weight: 100;
  }
  .footer.footer1 .footerbottom .copy-right a:hover {
    color: #008dd2;
  }
  .footer.footer1 .footerbottom .social-media-list li a {
    color: #2e3c40;
  }
  .footer.footer1 .footerbottom .social-media-list li a:hover {
    color: #008dd2;
  }
  .footer.footer1 .footer-widget {
    margin-bottom: 20px;
    text-align: center;
    padding: 0 200px;
  }
  .footer.footer1 .footer-widget p {
    margin-bottom: 0;
  }
  @media (min-width: 768px) {
    .footer.footer1 .footer-widget {
      text-align: left;
      padding: 0;
    }
  }
  @media (min-width: 992px) {
    .footer.footer1 .footer-widget {
      margin-bottom: 0;
    }
  }
  .footer.footer1 .footer-widget .footer-logo {
    margin-bottom: 15px;
    margin-top: 0px;
    display: block;
  }
  @media (min-width: 992px) {
    .footer.footer1 .footer-widget .footer-logo {
      margin-bottom: 10px;
    }
  }
  .footer.footer1 .footer-widget .address {
    font-weight: 400;
    margin-bottom: 15px;
  }
  @media (min-width: 992px) {
    .footer.footer1 .footer-widget .address {
      padding-right: 30px;
      margin-bottom: 25px;
      font-size: large;
    }
  }
  .footer.footer1 .footer-widget .footer-site-info {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 30px;
  }
  .footer.footer1 .footer-widget .footer-site-info li {
    line-height: 1.6;
    font-weight: 400;
    font-size: large;
  }
  .footer.footer1 .footer-widget .googlemap {
    text-decoration: underline;
    font-weight: 400;
  }
  .footer.footer1 .footer-widget .googlemap:hover {
    color: #008dd2;
  }
  .footer.footer1 .footer-widget .footer-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  @media (min-width: 992px) {
    .footer.footer1 .footer-widget .footer-title {
      margin-bottom: 30px;
    }
  }
  .footer.footer1 .footer-widget .footer-menu, .footer.footer1 .footer-widget .linklist {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
  }
  .footer.footer1 .footer-widget .footer-menu li a, .footer.footer1 .footer-widget .linklist li a {
    font-size: 14px;
    font-weight: 400;
    color: #444;
    line-height: 2.5;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .footer.footer1 .footer-widget .footer-menu li a:hover, .footer.footer1 .footer-widget .linklist li a:hover {
    color: #008dd2;
  }
  .footer.footer1 .footer-widget form {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
  }
  .footer.footer1 .footer-widget form input {
    border: none;
    border-bottom: 1px solid #ececec;
    padding: 15px 50px 15px 20px;
    outline: none;
    width: 100%;
    text-align: center;
  }
  .footer.footer1 .footer-widget form input::placeholder {
    color: #2e3c40;
    font-weight: 400;
  }
  .footer.footer1 .footer-widget form input:active, .footer.footer1 .footer-widget form input:focus {
    border-color: #008dd2;
  }
  .footer.footer1 .footer-widget form .submit {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -13px;
    color: #2e3c40;
    font-size: 20px;
    outline: none;
  }
  .footer.footer1 .footer-widget form input:active ~ .submit {
    color: #008dd2;
  }
  .footer.footer1 .footer-widget form input:focus ~ .submit {
    color: #008dd2;
  }

  @media (min-width: 768px) {
    .footer.footer1 .footer-widget form input {
      text-align: left;
    }
  }