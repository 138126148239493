
.entry-content {
    background-color: #fff;
    position: relative;
    z-index: 1;
  }
  @media (min-width: 768px) {
    .entry-content {
      -webkit-transform: translateY(-120px);
      -ms-transform: translateY(-120px);
      transform: translateY(-120px);
    }
  }
  .entry-content .post-content {
    padding: 40px 20px;
  }
  @media (min-width: 992px) {
    .entry-content .post-content {
      padding: 60px;
    }
  }
  .entry-content .post-content .title {
    font-size: 30px;
    position: relative;
    margin-bottom: 50px;
  }
  @media (min-width: 768px) {
    .entry-content .post-content .title {
      font-size: 42px;
    }
  }
  .entry-content .post-content .title:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -20px;
    width: 30px;
    height: 1px;
    background-color: #008dd2;
  }
  .entry-content blockquote {
    padding: 55px 20px 55px 70px;
    position: relative;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
    margin-bottom: 40px;
  }
  @media (min-width: 768px) {
    .entry-content blockquote {
      padding: 55px 65px 55px 100px;
    }
  }
  .entry-content blockquote p {
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 400;
    color: #2e3c40;
  }
  .entry-content blockquote .b-au {
    color: #008dd2;
    font-weight: 400;
  }
  .entry-content blockquote .b-au span {
    color: #a6a6a6;
    margin-left: 7px;
  }
  .entry-content blockquote .qoute-icon {
    position: absolute;
    left: 10px;
    top: 40px;
    font-size: 56px;
    color: #008dd2;
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  @media (min-width: 768px) {
    .entry-content blockquote .qoute-icon {
      left: 35px;
    }
  }
  
  .entry-footer {
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media (min-width: 992px) {
    .entry-footer {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
  .entry-footer .tags li:first-child {
    font-size: 14px;
    font-weight: 600;
    margin-right: 15px;
  }
  .entry-footer .tags li {
    margin: 10px 5px;
  }
  .entry-footer .tags li a {
    padding: 5px 20px;
    border: 1px solid #ececec;
    background-color: #fafafa;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .entry-footer .tags li a:hover {
    background-color: #008dd2;
    border-color: #008dd2;
    color: #fff;
  }
  .entry-footer .like-share li {
    margin: 0 40px;
  }
  .entry-footer .like-share li:first-child {
    padding-left: 0;
  }
  .entry-footer .like-share li:first-child a {
    color: #008dd2;
  }
  .entry-footer .like-share li:last-child {
    padding-right: 0;
  }
  .entry-footer .like-share li a {
    font-size: 20px;
    color: #2e3c40;
  }
  
  .comments-list {
    padding: 0;
    list-style: none;
    background-color: #fff;
  }
  .comments-list .comments {
    margin-bottom: 20px;
    list-style: none;
  }
  .comments-list .comments .thumb {
    width: 80px;
    height: 80px;
    float: left;
    margin-right: 20px;
    overflow: hidden;
    display: none;
  }
  @media (min-width: 768px) {
    .comments-list .comments .thumb {
      display: block;
    }
  }
  .comments-list .comments .content {
    background-color: transparent;
    border-bottom: 1px solid #ececec;
    display: table;
    margin-bottom: 30px;
    padding-bottom: 20px;
  }
  .comments-list .comments .content .comment-heading {
    position: relative;
  }
  .comments-list .comments .content .comment-heading .name {
    font-size: 18px;
    color: #2e3c40;
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
  }
  .comments-list .comments .content .comment-heading span {
    font-size: 14px;
    color: #959494;
    display: block;
    font-weight: 600;
  }
  .comments-list .comments .content .comment-heading .reply {
    position: absolute;
    right: 0;
    top: -6px;
    border: 1px solid #4e5a62;
    padding: 2px 18px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .comments-list .comments .content .comment-heading .reply:hover {
    background-color: #008dd2;
    border-color: #008dd2;
    color: #fff;
  }
  .comments-list .comments .content p {
    margin-bottom: 0px;
  }
  .comments-list .comments .comment-sub-list {
    padding: 0;
    margin-left: 20px;
    list-style: none;
    margin-top: 10px;
  }
  @media (min-width: 992px) {
    .comments-list .comments .comment-sub-list {
      margin-left: 70px;
    }
  }
  .comments-list .comments .comment-sub-list li {
    padding-left: 0;
  }
  
  .mas-section {
    background-color: #fff;
    padding: 30px;
  }
  @media (min-width: 768px) {
    .mas-section {
      padding-top: 0;
      margin-bottom: 0px;
      transform: translateY(-50px);
    }
  }
  .mas-section .section-title {
    text-align: left;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: 30px;
  }
  .mas-section .section-title span {
    color: #008dd2;
  }
  .mas-section .section-title p {
    margin-top: 7px;
    margin-bottom: 0px;
    font-weight: 400;
    color: #7a7878;
  }
  .mas-section .section-title p > span {
    color: #008dd2;
  }
  
  .comment-response {
    background-color: #fff;
  }
  .comment-response form label {
    color: #646568;
    font-size: 13px;
    font-weight: 500;
  }
  .comment-response form input {
    padding: 15px 25px;
    border: 1px solid #ececec;
    margin-bottom: 20px;
    width: 100%;
    font-size: 14px;
    font-weight: 300;
  }
  .comment-response form input:focus, .comment-response form input:active {
    outline: none;
    border: 1px solid #008dd2;
  }
  .comment-response form textarea {
    padding: 30px;
    min-height: 200px;
    width: 100%;
    border: 1px solid #ececec;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 20px;
  }
  .comment-response form textarea:focus, .comment-response form textarea:active {
    outline: none;
    border: 1px solid #008dd2;
  }
  .comment-response form input[type=submit] {
    background-color: transparent;
    margin-top: 20px;
    padding: 13px 55px;
    margin-bottom: 0;
    width: auto;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border: 1px solid #ececec;
    font-weight: 500;
  }
  .comment-response form input[type=submit]:hover {
    background-color: #008dd2;
    color: #fff;
    border-color: #008dd2;
  }
  
  .thumb-slider-container {
    width: 100%;
    overflow: hidden;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
  }
  .thumb-slider-container .swiper-slide {
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
  }
  .thumb-slider-container img {
    width: 100%;
  }
  
  .entry-single .thumb {
    margin-bottom: 20px;
  }
  