.working-process-section {
    overflow: hidden;
}
.working-process-section .bz-working-process {
    position: relative;
  }
  .working-process-section .bz-working-process:after {
    content: "";
    position: absolute;
    top: -10px;
    left: 100%;
    width: 50%;
    height: 110%;
    background-color: rgba(249, 249, 249, 0.7);
    z-index: 1;
  }
  .working-process-section .bz-working-process:before {
    content: "";
    position: absolute;
    top: -10px;
    right: 100.8%;
    width: 50%;
    height: 110%;
    background-color: #f9f9f9;
    z-index: 2;
  }
.bz-process-item {
    width: 100%;
    height: 200px;
    position: relative;
    overflow: hidden;
  }
  .bz-process-item .flippy-container {
    width: 100%;
    height: 100%;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    overflow: hidden;
  }
  .bz-process-item .flippy-container .flippy-front, .bz-process-item .flippy-container .flippy-back {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 20px;
  }
  .bz-process-item .flippy-container .flippy-front .process-count, .bz-process-item .flippy-container .flippy-back .process-count {
    position: absolute;
    left: 20px;
    top: 20px;
  }
  .bz-process-item .flippy-container .flippy-front {
    background-color: #fff;
  }
  .bz-process-item .flippy-container .flippy-front .name {
    color: #2e3c40;
    font-size: 20px;
  }
  .bz-process-item .flippy-container .flippy-back {
    background-color: #008dd2;
  }
  .bz-process-item .flippy-container .flippy-back .process-count {
    color: #fff;
  }
  .bz-process-item .flippy-container .flippy-back .desc {
    margin-bottom: 0;
    color: #fff;
    line-height: 1.7;
  }




  
.bz-working-process-container {
  padding-bottom: 20px;
}
@media (min-width: 992px) {
  .bz-working-process-container {
    padding-bottom: 80px;
  }
  .bz-working-process-container .swiper-wrapper {
    cursor: ew-resize;
  }
}
.bz-working-process-container .swiper-pagination-progressbar {
  width: 75%;
  top: 110%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #eeeeee;
  height: 2px;
  margin-top: -1px;
}

.bz-working-process-container .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #d2d2d2;
  height: 2px;
}


.bz-working-process-container .swiper-button-prev {
    width: 35px;
    height: 35px;
    top: auto;
    bottom: -39px;
    left: 15px;
    outline: none;
}

.bz-working-process-container .swiper-button-next {
    width: 35px;
    height: 35px;
    top: auto;
    bottom: -39px;
    right: 15px;
    left: auto;
    outline: none;
}

.bz-working-process-container .swiper-button-prev:after,.bz-working-process-container .swiper-button-next:after {
    font-size: 30px;
    color: #444;
}
.bz-working-process-container .swiper-button-prev:focus:after,
.bz-working-process-container .swiper-button-next:focus:after,
.bz-working-process-container .swiper-button-prev:active:after,
.bz-working-process-container .swiper-button-next:active:after,
.bz-working-process-container .swiper-button-prev:hover:after,
.bz-working-process-container .swiper-button-next:hover:after {
    color: #008dd2;
}

@media (min-width: 560px) {
  .bz-working-process-container .swiper-pagination-progressbar {
    width: 85%;
    top: 110%;
  }
  .bz-working-process-container .swiper-button-prev {
    left: 15px;
  }
  .bz-working-process-container .swiper-button-next {
    right: 15px;
  }
}


@media (min-width: 768px) {
  .bz-working-process-container .swiper-pagination-progressbar {
    width: 85%;
    top: 110%;
  }
  .bz-working-process-container .swiper-button-prev {
    left: 30px;
  }
  .bz-working-process-container .swiper-button-next {
    right: 30px;
  }
}

@media (min-width: 990px) {
  .bz-working-process-container .swiper-pagination-progressbar {
    width: 85%;
    top: 108%;
  }
  .bz-working-process-container .swiper-button-prev {
    left: 40px;
  }
  .bz-working-process-container .swiper-button-next {
    right: 40px;
  }
}



@media (min-width: 1200px) {
  .bz-working-process-container .swiper-pagination-progressbar {
    width: 80%;
  }
  .bz-working-process-container .swiper-button-prev {
    left: 95px;
  }
  .bz-working-process-container .swiper-button-next {
    right: 95px;
  }
}

