@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,600i,700|Rubik:400,500,500i,700&display=swap);
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: "Open Sans", sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scroll-behavior: smooth;
}

@-ms-viewport {
  width: device-width;
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  color: #444;
  text-align: left;
  letter-spacing: 0.03px;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border-top: 1px solid #ccc;
  margin: 0;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
  margin: 5px 0;
}

h6 {
  font-size: 18px;
  margin: 5px 0;
}

h1, h2, h3, h4, h5, h6 {
  color: #021225;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
  line-height: 1.312;
}

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
  color: inherit;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
}

p {
  font-size: 14px;
  margin: 0 0 1.75em;
  color: #444;
  line-height: 2.25;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #444;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}



.nav-menu li.nav-item > a {
  font-size: 16px;
  font-family: "Rubik", sans-serif;
}



.pt--5 {
  padding-top: 5px; }

.pt--10 {
  padding-top: 10px; }

.pt--15 {
  padding-top: 15px; }

.pt--20 {
  padding-top: 20px; }

.pt--25 {
  padding-top: 25px; }

.pt--30 {
  padding-top: 30px; }

.pt--35 {
  padding-top: 35px; }

.pt--40 {
  padding-top: 40px; }

.pt--45 {
  padding-top: 45px; }

.pt--50 {
  padding-top: 50px; }

.pt--55 {
  padding-top: 55px; }

.pt--60 {
  padding-top: 60px; }

.pt--65 {
  padding-top: 65px; }

.pt--70 {
  padding-top: 70px; }

.pt--75 {
  padding-top: 75px; }

.pt--80 {
  padding-top: 80px; }

.pt--85 {
  padding-top: 85px; }

.pt--90 {
  padding-top: 90px; }

.pt--95 {
  padding-top: 95px; }

.pt--100 {
  padding-top: 100px; }

.pt--105 {
  padding-top: 105px; }

.pt--110 {
  padding-top: 110px; }

.pt--115 {
  padding-top: 115px; }

.pt--120 {
  padding-top: 120px; }

.pt--125 {
  padding-top: 125px; }

.pt--130 {
  padding-top: 130px; }

.pt--135 {
  padding-top: 135px; }

.pt--140 {
  padding-top: 140px; }

.pt--145 {
  padding-top: 145px; }

.pt--150 {
  padding-top: 150px; }

.pt--155 {
  padding-top: 155px; }

.pt--160 {
  padding-top: 160px; }

.pt--165 {
  padding-top: 165px; }

.pt--170 {
  padding-top: 170px; }

.pt--175 {
  padding-top: 175px; }

.pt--180 {
  padding-top: 180px; }

.pt--185 {
  padding-top: 185px; }

.pt--190 {
  padding-top: 190px; }

.pt--195 {
  padding-top: 195px; }

.pt--200 {
  padding-top: 200px; }

.pt--205 {
  padding-top: 205px; }

.pt--210 {
  padding-top: 210px; }

.pt--215 {
  padding-top: 215px; }

.pt--220 {
  padding-top: 220px; }

.pt--225 {
  padding-top: 225px; }

.pt--230 {
  padding-top: 230px; }

.pt--235 {
  padding-top: 235px; }

.pt--240 {
  padding-top: 240px; }

.pt--245 {
  padding-top: 245px; }

.pt--250 {
  padding-top: 250px; }

@media (min-width: 576px) {
  .pt_sm--5 {
    padding-top: 5px; }

  .pt_sm--10 {
    padding-top: 10px; }

  .pt_sm--15 {
    padding-top: 15px; }

  .pt_sm--20 {
    padding-top: 20px; }

  .pt_sm--25 {
    padding-top: 25px; }

  .pt_sm--30 {
    padding-top: 30px; }

  .pt_sm--35 {
    padding-top: 35px; }

  .pt_sm--40 {
    padding-top: 40px; }

  .pt_sm--45 {
    padding-top: 45px; }

  .pt_sm--50 {
    padding-top: 50px; }

  .pt_sm--55 {
    padding-top: 55px; }

  .pt_sm--60 {
    padding-top: 60px; }

  .pt_sm--65 {
    padding-top: 65px; }

  .pt_sm--70 {
    padding-top: 70px; }

  .pt_sm--75 {
    padding-top: 75px; }

  .pt_sm--80 {
    padding-top: 80px; }

  .pt_sm--85 {
    padding-top: 85px; }

  .pt_sm--90 {
    padding-top: 90px; }

  .pt_sm--95 {
    padding-top: 95px; }

  .pt_sm--100 {
    padding-top: 100px; }

  .pt_sm--105 {
    padding-top: 105px; }

  .pt_sm--110 {
    padding-top: 110px; }

  .pt_sm--115 {
    padding-top: 115px; }

  .pt_sm--120 {
    padding-top: 120px; }

  .pt_sm--125 {
    padding-top: 125px; }

  .pt_sm--130 {
    padding-top: 130px; }

  .pt_sm--135 {
    padding-top: 135px; }

  .pt_sm--140 {
    padding-top: 140px; }

  .pt_sm--145 {
    padding-top: 145px; }

  .pt_sm--150 {
    padding-top: 150px; }

  .pt_sm--155 {
    padding-top: 155px; }

  .pt_sm--160 {
    padding-top: 160px; }

  .pt_sm--165 {
    padding-top: 165px; }

  .pt_sm--170 {
    padding-top: 170px; }

  .pt_sm--175 {
    padding-top: 175px; }

  .pt_sm--180 {
    padding-top: 180px; }

  .pt_sm--185 {
    padding-top: 185px; }

  .pt_sm--190 {
    padding-top: 190px; }

  .pt_sm--195 {
    padding-top: 195px; }

  .pt_sm--200 {
    padding-top: 200px; }

  .pt_sm--205 {
    padding-top: 205px; }

  .pt_sm--210 {
    padding-top: 210px; }

  .pt_sm--215 {
    padding-top: 215px; }

  .pt_sm--220 {
    padding-top: 220px; }

  .pt_sm--225 {
    padding-top: 225px; }

  .pt_sm--230 {
    padding-top: 230px; }

  .pt_sm--235 {
    padding-top: 235px; }

  .pt_sm--240 {
    padding-top: 240px; }

  .pt_sm--245 {
    padding-top: 245px; }

  .pt_sm--250 {
    padding-top: 250px; } }
@media (min-width: 768px) {
  .pt_md--5 {
    padding-top: 5px; }

  .pt_md--10 {
    padding-top: 10px; }

  .pt_md--15 {
    padding-top: 15px; }

  .pt_md--20 {
    padding-top: 20px; }

  .pt_md--25 {
    padding-top: 25px; }

  .pt_md--30 {
    padding-top: 30px; }

  .pt_md--35 {
    padding-top: 35px; }

  .pt_md--40 {
    padding-top: 40px; }

  .pt_md--45 {
    padding-top: 45px; }

  .pt_md--50 {
    padding-top: 50px; }

  .pt_md--55 {
    padding-top: 55px; }

  .pt_md--60 {
    padding-top: 60px; }

  .pt_md--65 {
    padding-top: 65px; }

  .pt_md--70 {
    padding-top: 70px; }

  .pt_md--75 {
    padding-top: 75px; }

  .pt_md--80 {
    padding-top: 80px; }

  .pt_md--85 {
    padding-top: 85px; }

  .pt_md--90 {
    padding-top: 90px; }

  .pt_md--95 {
    padding-top: 95px; }

  .pt_md--100 {
    padding-top: 100px; }

  .pt_md--105 {
    padding-top: 105px; }

  .pt_md--110 {
    padding-top: 110px; }

  .pt_md--115 {
    padding-top: 115px; }

  .pt_md--120 {
    padding-top: 120px; }

  .pt_md--125 {
    padding-top: 125px; }

  .pt_md--130 {
    padding-top: 130px; }

  .pt_md--135 {
    padding-top: 135px; }

  .pt_md--140 {
    padding-top: 140px; }

  .pt_md--145 {
    padding-top: 145px; }

  .pt_md--150 {
    padding-top: 150px; }

  .pt_md--155 {
    padding-top: 155px; }

  .pt_md--160 {
    padding-top: 160px; }

  .pt_md--165 {
    padding-top: 165px; }

  .pt_md--170 {
    padding-top: 170px; }

  .pt_md--175 {
    padding-top: 175px; }

  .pt_md--180 {
    padding-top: 180px; }

  .pt_md--185 {
    padding-top: 185px; }

  .pt_md--190 {
    padding-top: 190px; }

  .pt_md--195 {
    padding-top: 195px; }

  .pt_md--200 {
    padding-top: 200px; }

  .pt_md--205 {
    padding-top: 205px; }

  .pt_md--210 {
    padding-top: 210px; }

  .pt_md--215 {
    padding-top: 215px; }

  .pt_md--220 {
    padding-top: 220px; }

  .pt_md--225 {
    padding-top: 225px; }

  .pt_md--230 {
    padding-top: 230px; }

  .pt_md--235 {
    padding-top: 235px; }

  .pt_md--240 {
    padding-top: 240px; }

  .pt_md--245 {
    padding-top: 245px; }

  .pt_md--250 {
    padding-top: 250px; } }
@media (min-width: 992px) {
  .pt_lg--5 {
    padding-top: 5px; }

  .pt_lg--10 {
    padding-top: 10px; }

  .pt_lg--15 {
    padding-top: 15px; }

  .pt_lg--20 {
    padding-top: 20px; }

  .pt_lg--25 {
    padding-top: 25px; }

  .pt_lg--30 {
    padding-top: 30px; }

  .pt_lg--35 {
    padding-top: 35px; }

  .pt_lg--40 {
    padding-top: 40px; }

  .pt_lg--45 {
    padding-top: 45px; }

  .pt_lg--50 {
    padding-top: 50px; }

  .pt_lg--55 {
    padding-top: 55px; }

  .pt_lg--60 {
    padding-top: 60px; }

  .pt_lg--65 {
    padding-top: 65px; }

  .pt_lg--70 {
    padding-top: 70px; }

  .pt_lg--75 {
    padding-top: 75px; }

  .pt_lg--80 {
    padding-top: 80px; }

  .pt_lg--85 {
    padding-top: 85px; }

  .pt_lg--90 {
    padding-top: 90px; }

  .pt_lg--95 {
    padding-top: 95px; }

  .pt_lg--100 {
    padding-top: 100px; }

  .pt_lg--105 {
    padding-top: 105px; }

  .pt_lg--110 {
    padding-top: 110px; }

  .pt_lg--115 {
    padding-top: 115px; }

  .pt_lg--120 {
    padding-top: 120px; }

  .pt_lg--125 {
    padding-top: 125px; }

  .pt_lg--130 {
    padding-top: 130px; }

  .pt_lg--135 {
    padding-top: 135px; }

  .pt_lg--140 {
    padding-top: 140px; }

  .pt_lg--145 {
    padding-top: 145px; }

  .pt_lg--150 {
    padding-top: 150px; }

  .pt_lg--155 {
    padding-top: 155px; }

  .pt_lg--160 {
    padding-top: 160px; }

  .pt_lg--165 {
    padding-top: 165px; }

  .pt_lg--170 {
    padding-top: 170px; }

  .pt_lg--175 {
    padding-top: 175px; }

  .pt_lg--180 {
    padding-top: 180px; }

  .pt_lg--185 {
    padding-top: 185px; }

  .pt_lg--190 {
    padding-top: 190px; }

  .pt_lg--195 {
    padding-top: 195px; }

  .pt_lg--200 {
    padding-top: 200px; }

  .pt_lg--205 {
    padding-top: 205px; }

  .pt_lg--210 {
    padding-top: 210px; }

  .pt_lg--215 {
    padding-top: 215px; }

  .pt_lg--220 {
    padding-top: 220px; }

  .pt_lg--225 {
    padding-top: 225px; }

  .pt_lg--230 {
    padding-top: 230px; }

  .pt_lg--235 {
    padding-top: 235px; }

  .pt_lg--240 {
    padding-top: 240px; }

  .pt_lg--245 {
    padding-top: 245px; }

  .pt_lg--250 {
    padding-top: 250px; } }
@media (min-width: 1200px) {
  .pt_xl--5 {
    padding-top: 5px; }

  .pt_xl--10 {
    padding-top: 10px; }

  .pt_xl--15 {
    padding-top: 15px; }

  .pt_xl--20 {
    padding-top: 20px; }

  .pt_xl--25 {
    padding-top: 25px; }

  .pt_xl--30 {
    padding-top: 30px; }

  .pt_xl--35 {
    padding-top: 35px; }

  .pt_xl--40 {
    padding-top: 40px; }

  .pt_xl--45 {
    padding-top: 45px; }

  .pt_xl--50 {
    padding-top: 50px; }

  .pt_xl--55 {
    padding-top: 55px; }

  .pt_xl--60 {
    padding-top: 60px; }

  .pt_xl--65 {
    padding-top: 65px; }

  .pt_xl--70 {
    padding-top: 70px; }

  .pt_xl--75 {
    padding-top: 75px; }

  .pt_xl--80 {
    padding-top: 80px; }

  .pt_xl--85 {
    padding-top: 85px; }

  .pt_xl--90 {
    padding-top: 90px; }

  .pt_xl--95 {
    padding-top: 95px; }

  .pt_xl--100 {
    padding-top: 100px; }

  .pt_xl--105 {
    padding-top: 105px; }

  .pt_xl--110 {
    padding-top: 110px; }

  .pt_xl--115 {
    padding-top: 115px; }

  .pt_xl--120 {
    padding-top: 120px; }

  .pt_xl--125 {
    padding-top: 125px; }

  .pt_xl--130 {
    padding-top: 130px; }

  .pt_xl--135 {
    padding-top: 135px; }

  .pt_xl--140 {
    padding-top: 140px; }

  .pt_xl--145 {
    padding-top: 145px; }

  .pt_xl--150 {
    padding-top: 150px; }

  .pt_xl--155 {
    padding-top: 155px; }

  .pt_xl--160 {
    padding-top: 160px; }

  .pt_xl--165 {
    padding-top: 165px; }

  .pt_xl--170 {
    padding-top: 170px; }

  .pt_xl--175 {
    padding-top: 175px; }

  .pt_xl--180 {
    padding-top: 180px; }

  .pt_xl--185 {
    padding-top: 185px; }

  .pt_xl--190 {
    padding-top: 190px; }

  .pt_xl--195 {
    padding-top: 195px; }

  .pt_xl--200 {
    padding-top: 200px; }

  .pt_xl--205 {
    padding-top: 205px; }

  .pt_xl--210 {
    padding-top: 210px; }

  .pt_xl--215 {
    padding-top: 215px; }

  .pt_xl--220 {
    padding-top: 220px; }

  .pt_xl--225 {
    padding-top: 225px; }

  .pt_xl--230 {
    padding-top: 230px; }

  .pt_xl--235 {
    padding-top: 235px; }

  .pt_xl--240 {
    padding-top: 240px; }

  .pt_xl--245 {
    padding-top: 245px; }

  .pt_xl--250 {
    padding-top: 250px; } }
.pb--5 {
  padding-bottom: 5px; }

.pb--10 {
  padding-bottom: 10px; }

.pb--15 {
  padding-bottom: 15px; }

.pb--20 {
  padding-bottom: 20px; }

.pb--25 {
  padding-bottom: 25px; }

.pb--30 {
  padding-bottom: 30px; }

.pb--35 {
  padding-bottom: 35px; }

.pb--40 {
  padding-bottom: 40px; }

.pb--45 {
  padding-bottom: 45px; }

.pb--50 {
  padding-bottom: 50px; }

.pb--55 {
  padding-bottom: 55px; }

.pb--60 {
  padding-bottom: 60px; }

.pb--65 {
  padding-bottom: 65px; }

.pb--70 {
  padding-bottom: 70px; }

.pb--75 {
  padding-bottom: 75px; }

.pb--80 {
  padding-bottom: 80px; }

.pb--85 {
  padding-bottom: 85px; }

.pb--90 {
  padding-bottom: 90px; }

.pb--95 {
  padding-bottom: 95px; }

.pb--100 {
  padding-bottom: 100px; }

.pb--105 {
  padding-bottom: 105px; }

.pb--110 {
  padding-bottom: 110px; }

.pb--115 {
  padding-bottom: 115px; }

.pb--120 {
  padding-bottom: 120px; }

.pb--125 {
  padding-bottom: 125px; }

.pb--130 {
  padding-bottom: 130px; }

.pb--135 {
  padding-bottom: 135px; }

.pb--140 {
  padding-bottom: 140px; }

.pb--145 {
  padding-bottom: 145px; }

.pb--150 {
  padding-bottom: 150px; }

.pb--155 {
  padding-bottom: 155px; }

.pb--160 {
  padding-bottom: 160px; }

.pb--165 {
  padding-bottom: 165px; }

.pb--170 {
  padding-bottom: 170px; }

.pb--175 {
  padding-bottom: 175px; }

.pb--180 {
  padding-bottom: 180px; }

.pb--185 {
  padding-bottom: 185px; }

.pb--190 {
  padding-bottom: 190px; }

.pb--195 {
  padding-bottom: 195px; }

.pb--200 {
  padding-bottom: 200px; }

.pb--205 {
  padding-bottom: 205px; }

.pb--210 {
  padding-bottom: 210px; }

.pb--215 {
  padding-bottom: 215px; }

.pb--220 {
  padding-bottom: 220px; }

.pb--225 {
  padding-bottom: 225px; }

.pb--230 {
  padding-bottom: 230px; }

.pb--235 {
  padding-bottom: 235px; }

.pb--240 {
  padding-bottom: 240px; }

.pb--245 {
  padding-bottom: 245px; }

.pb--250 {
  padding-bottom: 250px; }

@media (min-width: 576px) {
  .pb_sm--5 {
    padding-bottom: 5px; }

  .pb_sm--10 {
    padding-bottom: 10px; }

  .pb_sm--15 {
    padding-bottom: 15px; }

  .pb_sm--20 {
    padding-bottom: 20px; }

  .pb_sm--25 {
    padding-bottom: 25px; }

  .pb_sm--30 {
    padding-bottom: 30px; }

  .pb_sm--35 {
    padding-bottom: 35px; }

  .pb_sm--40 {
    padding-bottom: 40px; }

  .pb_sm--45 {
    padding-bottom: 45px; }

  .pb_sm--50 {
    padding-bottom: 50px; }

  .pb_sm--55 {
    padding-bottom: 55px; }

  .pb_sm--60 {
    padding-bottom: 60px; }

  .pb_sm--65 {
    padding-bottom: 65px; }

  .pb_sm--70 {
    padding-bottom: 70px; }

  .pb_sm--75 {
    padding-bottom: 75px; }

  .pb_sm--80 {
    padding-bottom: 80px; }

  .pb_sm--85 {
    padding-bottom: 85px; }

  .pb_sm--90 {
    padding-bottom: 90px; }

  .pb_sm--95 {
    padding-bottom: 95px; }

  .pb_sm--100 {
    padding-bottom: 100px; }

  .pb_sm--105 {
    padding-bottom: 105px; }

  .pb_sm--110 {
    padding-bottom: 110px; }

  .pb_sm--115 {
    padding-bottom: 115px; }

  .pb_sm--120 {
    padding-bottom: 120px; }

  .pb_sm--125 {
    padding-bottom: 125px; }

  .pb_sm--130 {
    padding-bottom: 130px; }

  .pb_sm--135 {
    padding-bottom: 135px; }

  .pb_sm--140 {
    padding-bottom: 140px; }

  .pb_sm--145 {
    padding-bottom: 145px; }

  .pb_sm--150 {
    padding-bottom: 150px; }

  .pb_sm--155 {
    padding-bottom: 155px; }

  .pb_sm--160 {
    padding-bottom: 160px; }

  .pb_sm--165 {
    padding-bottom: 165px; }

  .pb_sm--170 {
    padding-bottom: 170px; }

  .pb_sm--175 {
    padding-bottom: 175px; }

  .pb_sm--180 {
    padding-bottom: 180px; }

  .pb_sm--185 {
    padding-bottom: 185px; }

  .pb_sm--190 {
    padding-bottom: 190px; }

  .pb_sm--195 {
    padding-bottom: 195px; }

  .pb_sm--200 {
    padding-bottom: 200px; }

  .pb_sm--205 {
    padding-bottom: 205px; }

  .pb_sm--210 {
    padding-bottom: 210px; }

  .pb_sm--215 {
    padding-bottom: 215px; }

  .pb_sm--220 {
    padding-bottom: 220px; }

  .pb_sm--225 {
    padding-bottom: 225px; }

  .pb_sm--230 {
    padding-bottom: 230px; }

  .pb_sm--235 {
    padding-bottom: 235px; }

  .pb_sm--240 {
    padding-bottom: 240px; }

  .pb_sm--245 {
    padding-bottom: 245px; }

  .pb_sm--250 {
    padding-bottom: 250px; } }
@media (min-width: 768px) {
  .pb_md--5 {
    padding-bottom: 5px; }

  .pb_md--10 {
    padding-bottom: 10px; }

  .pb_md--15 {
    padding-bottom: 15px; }

  .pb_md--20 {
    padding-bottom: 20px; }

  .pb_md--25 {
    padding-bottom: 25px; }

  .pb_md--30 {
    padding-bottom: 30px; }

  .pb_md--35 {
    padding-bottom: 35px; }

  .pb_md--40 {
    padding-bottom: 40px; }

  .pb_md--45 {
    padding-bottom: 45px; }

  .pb_md--50 {
    padding-bottom: 50px; }

  .pb_md--55 {
    padding-bottom: 55px; }

  .pb_md--60 {
    padding-bottom: 60px; }

  .pb_md--65 {
    padding-bottom: 65px; }

  .pb_md--70 {
    padding-bottom: 70px; }

  .pb_md--75 {
    padding-bottom: 75px; }

  .pb_md--80 {
    padding-bottom: 80px; }

  .pb_md--85 {
    padding-bottom: 85px; }

  .pb_md--90 {
    padding-bottom: 90px; }

  .pb_md--95 {
    padding-bottom: 95px; }

  .pb_md--100 {
    padding-bottom: 100px; }

  .pb_md--105 {
    padding-bottom: 105px; }

  .pb_md--110 {
    padding-bottom: 110px; }

  .pb_md--115 {
    padding-bottom: 115px; }

  .pb_md--120 {
    padding-bottom: 120px; }

  .pb_md--125 {
    padding-bottom: 125px; }

  .pb_md--130 {
    padding-bottom: 130px; }

  .pb_md--135 {
    padding-bottom: 135px; }

  .pb_md--140 {
    padding-bottom: 140px; }

  .pb_md--145 {
    padding-bottom: 145px; }

  .pb_md--150 {
    padding-bottom: 150px; }

  .pb_md--155 {
    padding-bottom: 155px; }

  .pb_md--160 {
    padding-bottom: 160px; }

  .pb_md--165 {
    padding-bottom: 165px; }

  .pb_md--170 {
    padding-bottom: 170px; }

  .pb_md--175 {
    padding-bottom: 175px; }

  .pb_md--180 {
    padding-bottom: 180px; }

  .pb_md--185 {
    padding-bottom: 185px; }

  .pb_md--190 {
    padding-bottom: 190px; }

  .pb_md--195 {
    padding-bottom: 195px; }

  .pb_md--200 {
    padding-bottom: 200px; }

  .pb_md--205 {
    padding-bottom: 205px; }

  .pb_md--210 {
    padding-bottom: 210px; }

  .pb_md--215 {
    padding-bottom: 215px; }

  .pb_md--220 {
    padding-bottom: 220px; }

  .pb_md--225 {
    padding-bottom: 225px; }

  .pb_md--230 {
    padding-bottom: 230px; }

  .pb_md--235 {
    padding-bottom: 235px; }

  .pb_md--240 {
    padding-bottom: 240px; }

  .pb_md--245 {
    padding-bottom: 245px; }

  .pb_md--250 {
    padding-bottom: 250px; } }
@media (min-width: 992px) {
  .pb_lg--5 {
    padding-bottom: 5px; }

  .pb_lg--10 {
    padding-bottom: 10px; }

  .pb_lg--15 {
    padding-bottom: 15px; }

  .pb_lg--20 {
    padding-bottom: 20px; }

  .pb_lg--25 {
    padding-bottom: 25px; }

  .pb_lg--30 {
    padding-bottom: 30px; }

  .pb_lg--35 {
    padding-bottom: 35px; }

  .pb_lg--40 {
    padding-bottom: 40px; }

  .pb_lg--45 {
    padding-bottom: 45px; }

  .pb_lg--50 {
    padding-bottom: 50px; }

  .pb_lg--55 {
    padding-bottom: 55px; }

  .pb_lg--60 {
    padding-bottom: 60px; }

  .pb_lg--65 {
    padding-bottom: 65px; }

  .pb_lg--70 {
    padding-bottom: 70px; }

  .pb_lg--75 {
    padding-bottom: 75px; }

  .pb_lg--80 {
    padding-bottom: 80px; }

  .pb_lg--85 {
    padding-bottom: 85px; }

  .pb_lg--90 {
    padding-bottom: 90px; }

  .pb_lg--95 {
    padding-bottom: 95px; }

  .pb_lg--100 {
    padding-bottom: 100px; }

  .pb_lg--105 {
    padding-bottom: 105px; }

  .pb_lg--110 {
    padding-bottom: 110px; }

  .pb_lg--115 {
    padding-bottom: 115px; }

  .pb_lg--120 {
    padding-bottom: 120px; }

  .pb_lg--125 {
    padding-bottom: 125px; }

  .pb_lg--130 {
    padding-bottom: 130px; }

  .pb_lg--135 {
    padding-bottom: 135px; }

  .pb_lg--140 {
    padding-bottom: 140px; }

  .pb_lg--145 {
    padding-bottom: 145px; }

  .pb_lg--150 {
    padding-bottom: 150px; }

  .pb_lg--155 {
    padding-bottom: 155px; }

  .pb_lg--160 {
    padding-bottom: 160px; }

  .pb_lg--165 {
    padding-bottom: 165px; }

  .pb_lg--170 {
    padding-bottom: 170px; }

  .pb_lg--175 {
    padding-bottom: 175px; }

  .pb_lg--180 {
    padding-bottom: 180px; }

  .pb_lg--185 {
    padding-bottom: 185px; }

  .pb_lg--190 {
    padding-bottom: 190px; }

  .pb_lg--195 {
    padding-bottom: 195px; }

  .pb_lg--200 {
    padding-bottom: 200px; }

  .pb_lg--205 {
    padding-bottom: 205px; }

  .pb_lg--210 {
    padding-bottom: 210px; }

  .pb_lg--215 {
    padding-bottom: 215px; }

  .pb_lg--220 {
    padding-bottom: 220px; }

  .pb_lg--225 {
    padding-bottom: 225px; }

  .pb_lg--230 {
    padding-bottom: 230px; }

  .pb_lg--235 {
    padding-bottom: 235px; }

  .pb_lg--240 {
    padding-bottom: 240px; }

  .pb_lg--245 {
    padding-bottom: 245px; }

  .pb_lg--250 {
    padding-bottom: 250px; } }
@media (min-width: 1200px) {
  .pb_xl--5 {
    padding-bottom: 5px; }

  .pb_xl--10 {
    padding-bottom: 10px; }

  .pb_xl--15 {
    padding-bottom: 15px; }

  .pb_xl--20 {
    padding-bottom: 20px; }

  .pb_xl--25 {
    padding-bottom: 25px; }

  .pb_xl--30 {
    padding-bottom: 30px; }

  .pb_xl--35 {
    padding-bottom: 35px; }

  .pb_xl--40 {
    padding-bottom: 40px; }

  .pb_xl--45 {
    padding-bottom: 45px; }

  .pb_xl--50 {
    padding-bottom: 50px; }

  .pb_xl--55 {
    padding-bottom: 55px; }

  .pb_xl--60 {
    padding-bottom: 60px; }

  .pb_xl--65 {
    padding-bottom: 65px; }

  .pb_xl--70 {
    padding-bottom: 70px; }

  .pb_xl--75 {
    padding-bottom: 75px; }

  .pb_xl--80 {
    padding-bottom: 80px; }

  .pb_xl--85 {
    padding-bottom: 85px; }

  .pb_xl--90 {
    padding-bottom: 90px; }

  .pb_xl--95 {
    padding-bottom: 95px; }

  .pb_xl--100 {
    padding-bottom: 100px; }

  .pb_xl--105 {
    padding-bottom: 105px; }

  .pb_xl--110 {
    padding-bottom: 110px; }

  .pb_xl--115 {
    padding-bottom: 115px; }

  .pb_xl--120 {
    padding-bottom: 120px; }

  .pb_xl--125 {
    padding-bottom: 125px; }

  .pb_xl--130 {
    padding-bottom: 130px; }

  .pb_xl--135 {
    padding-bottom: 135px; }

  .pb_xl--140 {
    padding-bottom: 140px; }

  .pb_xl--145 {
    padding-bottom: 145px; }

  .pb_xl--150 {
    padding-bottom: 150px; }

  .pb_xl--155 {
    padding-bottom: 155px; }

  .pb_xl--160 {
    padding-bottom: 160px; }

  .pb_xl--165 {
    padding-bottom: 165px; }

  .pb_xl--170 {
    padding-bottom: 170px; }

  .pb_xl--175 {
    padding-bottom: 175px; }

  .pb_xl--180 {
    padding-bottom: 180px; }

  .pb_xl--185 {
    padding-bottom: 185px; }

  .pb_xl--190 {
    padding-bottom: 190px; }

  .pb_xl--195 {
    padding-bottom: 195px; }

  .pb_xl--200 {
    padding-bottom: 200px; }

  .pb_xl--205 {
    padding-bottom: 205px; }

  .pb_xl--210 {
    padding-bottom: 210px; }

  .pb_xl--215 {
    padding-bottom: 215px; }

  .pb_xl--220 {
    padding-bottom: 220px; }

  .pb_xl--225 {
    padding-bottom: 225px; }

  .pb_xl--230 {
    padding-bottom: 230px; }

  .pb_xl--235 {
    padding-bottom: 235px; }

  .pb_xl--240 {
    padding-bottom: 240px; }

  .pb_xl--245 {
    padding-bottom: 245px; }

  .pb_xl--250 {
    padding-bottom: 250px; } }
.pl--5 {
  padding-left: 5px; }

.pl--10 {
  padding-left: 10px; }

.pl--15 {
  padding-left: 15px; }

.pl--20 {
  padding-left: 20px; }

.pl--25 {
  padding-left: 25px; }

.pl--30 {
  padding-left: 30px; }

.pl--35 {
  padding-left: 35px; }

.pl--40 {
  padding-left: 40px; }

.pl--45 {
  padding-left: 45px; }

.pl--50 {
  padding-left: 50px; }

.pl--55 {
  padding-left: 55px; }

.pl--60 {
  padding-left: 60px; }

.pl--65 {
  padding-left: 65px; }

.pl--70 {
  padding-left: 70px; }

.pl--75 {
  padding-left: 75px; }

.pl--80 {
  padding-left: 80px; }

.pl--85 {
  padding-left: 85px; }

.pl--90 {
  padding-left: 90px; }

.pl--95 {
  padding-left: 95px; }

.pl--100 {
  padding-left: 100px; }

.pl--105 {
  padding-left: 105px; }

.pl--110 {
  padding-left: 110px; }

.pl--115 {
  padding-left: 115px; }

.pl--120 {
  padding-left: 120px; }

.pl--125 {
  padding-left: 125px; }

.pl--130 {
  padding-left: 130px; }

.pl--135 {
  padding-left: 135px; }

.pl--140 {
  padding-left: 140px; }

.pl--145 {
  padding-left: 145px; }

.pl--150 {
  padding-left: 150px; }

.pl--155 {
  padding-left: 155px; }

.pl--160 {
  padding-left: 160px; }

.pl--165 {
  padding-left: 165px; }

.pl--170 {
  padding-left: 170px; }

.pl--175 {
  padding-left: 175px; }

.pl--180 {
  padding-left: 180px; }

.pl--185 {
  padding-left: 185px; }

.pl--190 {
  padding-left: 190px; }

.pl--195 {
  padding-left: 195px; }

.pl--200 {
  padding-left: 200px; }

.pl--205 {
  padding-left: 205px; }

.pl--210 {
  padding-left: 210px; }

.pl--215 {
  padding-left: 215px; }

.pl--220 {
  padding-left: 220px; }

.pl--225 {
  padding-left: 225px; }

.pl--230 {
  padding-left: 230px; }

.pl--235 {
  padding-left: 235px; }

.pl--240 {
  padding-left: 240px; }

.pl--245 {
  padding-left: 245px; }

.pl--250 {
  padding-left: 250px; }

@media (min-width: 576px) {
  .pl_sm--5 {
    padding-left: 5px; }

  .pl_sm--10 {
    padding-left: 10px; }

  .pl_sm--15 {
    padding-left: 15px; }

  .pl_sm--20 {
    padding-left: 20px; }

  .pl_sm--25 {
    padding-left: 25px; }

  .pl_sm--30 {
    padding-left: 30px; }

  .pl_sm--35 {
    padding-left: 35px; }

  .pl_sm--40 {
    padding-left: 40px; }

  .pl_sm--45 {
    padding-left: 45px; }

  .pl_sm--50 {
    padding-left: 50px; }

  .pl_sm--55 {
    padding-left: 55px; }

  .pl_sm--60 {
    padding-left: 60px; }

  .pl_sm--65 {
    padding-left: 65px; }

  .pl_sm--70 {
    padding-left: 70px; }

  .pl_sm--75 {
    padding-left: 75px; }

  .pl_sm--80 {
    padding-left: 80px; }

  .pl_sm--85 {
    padding-left: 85px; }

  .pl_sm--90 {
    padding-left: 90px; }

  .pl_sm--95 {
    padding-left: 95px; }

  .pl_sm--100 {
    padding-left: 100px; }

  .pl_sm--105 {
    padding-left: 105px; }

  .pl_sm--110 {
    padding-left: 110px; }

  .pl_sm--115 {
    padding-left: 115px; }

  .pl_sm--120 {
    padding-left: 120px; }

  .pl_sm--125 {
    padding-left: 125px; }

  .pl_sm--130 {
    padding-left: 130px; }

  .pl_sm--135 {
    padding-left: 135px; }

  .pl_sm--140 {
    padding-left: 140px; }

  .pl_sm--145 {
    padding-left: 145px; }

  .pl_sm--150 {
    padding-left: 150px; }

  .pl_sm--155 {
    padding-left: 155px; }

  .pl_sm--160 {
    padding-left: 160px; }

  .pl_sm--165 {
    padding-left: 165px; }

  .pl_sm--170 {
    padding-left: 170px; }

  .pl_sm--175 {
    padding-left: 175px; }

  .pl_sm--180 {
    padding-left: 180px; }

  .pl_sm--185 {
    padding-left: 185px; }

  .pl_sm--190 {
    padding-left: 190px; }

  .pl_sm--195 {
    padding-left: 195px; }

  .pl_sm--200 {
    padding-left: 200px; }

  .pl_sm--205 {
    padding-left: 205px; }

  .pl_sm--210 {
    padding-left: 210px; }

  .pl_sm--215 {
    padding-left: 215px; }

  .pl_sm--220 {
    padding-left: 220px; }

  .pl_sm--225 {
    padding-left: 225px; }

  .pl_sm--230 {
    padding-left: 230px; }

  .pl_sm--235 {
    padding-left: 235px; }

  .pl_sm--240 {
    padding-left: 240px; }

  .pl_sm--245 {
    padding-left: 245px; }

  .pl_sm--250 {
    padding-left: 250px; } }
@media (min-width: 768px) {
  .pl_md--5 {
    padding-left: 5px; }

  .pl_md--10 {
    padding-left: 10px; }

  .pl_md--15 {
    padding-left: 15px; }

  .pl_md--20 {
    padding-left: 20px; }

  .pl_md--25 {
    padding-left: 25px; }

  .pl_md--30 {
    padding-left: 30px; }

  .pl_md--35 {
    padding-left: 35px; }

  .pl_md--40 {
    padding-left: 40px; }

  .pl_md--45 {
    padding-left: 45px; }

  .pl_md--50 {
    padding-left: 50px; }

  .pl_md--55 {
    padding-left: 55px; }

  .pl_md--60 {
    padding-left: 60px; }

  .pl_md--65 {
    padding-left: 65px; }

  .pl_md--70 {
    padding-left: 70px; }

  .pl_md--75 {
    padding-left: 75px; }

  .pl_md--80 {
    padding-left: 80px; }

  .pl_md--85 {
    padding-left: 85px; }

  .pl_md--90 {
    padding-left: 90px; }

  .pl_md--95 {
    padding-left: 95px; }

  .pl_md--100 {
    padding-left: 100px; }

  .pl_md--105 {
    padding-left: 105px; }

  .pl_md--110 {
    padding-left: 110px; }

  .pl_md--115 {
    padding-left: 115px; }

  .pl_md--120 {
    padding-left: 120px; }

  .pl_md--125 {
    padding-left: 125px; }

  .pl_md--130 {
    padding-left: 130px; }

  .pl_md--135 {
    padding-left: 135px; }

  .pl_md--140 {
    padding-left: 140px; }

  .pl_md--145 {
    padding-left: 145px; }

  .pl_md--150 {
    padding-left: 150px; }

  .pl_md--155 {
    padding-left: 155px; }

  .pl_md--160 {
    padding-left: 160px; }

  .pl_md--165 {
    padding-left: 165px; }

  .pl_md--170 {
    padding-left: 170px; }

  .pl_md--175 {
    padding-left: 175px; }

  .pl_md--180 {
    padding-left: 180px; }

  .pl_md--185 {
    padding-left: 185px; }

  .pl_md--190 {
    padding-left: 190px; }

  .pl_md--195 {
    padding-left: 195px; }

  .pl_md--200 {
    padding-left: 200px; }

  .pl_md--205 {
    padding-left: 205px; }

  .pl_md--210 {
    padding-left: 210px; }

  .pl_md--215 {
    padding-left: 215px; }

  .pl_md--220 {
    padding-left: 220px; }

  .pl_md--225 {
    padding-left: 225px; }

  .pl_md--230 {
    padding-left: 230px; }

  .pl_md--235 {
    padding-left: 235px; }

  .pl_md--240 {
    padding-left: 240px; }

  .pl_md--245 {
    padding-left: 245px; }

  .pl_md--250 {
    padding-left: 250px; } }
@media (min-width: 992px) {
  .pl_lg--5 {
    padding-left: 5px; }

  .pl_lg--10 {
    padding-left: 10px; }

  .pl_lg--15 {
    padding-left: 15px; }

  .pl_lg--20 {
    padding-left: 20px; }

  .pl_lg--25 {
    padding-left: 25px; }

  .pl_lg--30 {
    padding-left: 30px; }

  .pl_lg--35 {
    padding-left: 35px; }

  .pl_lg--40 {
    padding-left: 40px; }

  .pl_lg--45 {
    padding-left: 45px; }

  .pl_lg--50 {
    padding-left: 50px; }

  .pl_lg--55 {
    padding-left: 55px; }

  .pl_lg--60 {
    padding-left: 60px; }

  .pl_lg--65 {
    padding-left: 65px; }

  .pl_lg--70 {
    padding-left: 70px; }

  .pl_lg--75 {
    padding-left: 75px; }

  .pl_lg--80 {
    padding-left: 80px; }

  .pl_lg--85 {
    padding-left: 85px; }

  .pl_lg--90 {
    padding-left: 90px; }

  .pl_lg--95 {
    padding-left: 95px; }

  .pl_lg--100 {
    padding-left: 100px; }

  .pl_lg--105 {
    padding-left: 105px; }

  .pl_lg--110 {
    padding-left: 110px; }

  .pl_lg--115 {
    padding-left: 115px; }

  .pl_lg--120 {
    padding-left: 120px; }

  .pl_lg--125 {
    padding-left: 125px; }

  .pl_lg--130 {
    padding-left: 130px; }

  .pl_lg--135 {
    padding-left: 135px; }

  .pl_lg--140 {
    padding-left: 140px; }

  .pl_lg--145 {
    padding-left: 145px; }

  .pl_lg--150 {
    padding-left: 150px; }

  .pl_lg--155 {
    padding-left: 155px; }

  .pl_lg--160 {
    padding-left: 160px; }

  .pl_lg--165 {
    padding-left: 165px; }

  .pl_lg--170 {
    padding-left: 170px; }

  .pl_lg--175 {
    padding-left: 175px; }

  .pl_lg--180 {
    padding-left: 180px; }

  .pl_lg--185 {
    padding-left: 185px; }

  .pl_lg--190 {
    padding-left: 190px; }

  .pl_lg--195 {
    padding-left: 195px; }

  .pl_lg--200 {
    padding-left: 200px; }

  .pl_lg--205 {
    padding-left: 205px; }

  .pl_lg--210 {
    padding-left: 210px; }

  .pl_lg--215 {
    padding-left: 215px; }

  .pl_lg--220 {
    padding-left: 220px; }

  .pl_lg--225 {
    padding-left: 225px; }

  .pl_lg--230 {
    padding-left: 230px; }

  .pl_lg--235 {
    padding-left: 235px; }

  .pl_lg--240 {
    padding-left: 240px; }

  .pl_lg--245 {
    padding-left: 245px; }

  .pl_lg--250 {
    padding-left: 250px; } }
@media (min-width: 1200px) {
  .pl_xl--5 {
    padding-left: 5px; }

  .pl_xl--10 {
    padding-left: 10px; }

  .pl_xl--15 {
    padding-left: 15px; }

  .pl_xl--20 {
    padding-left: 20px; }

  .pl_xl--25 {
    padding-left: 25px; }

  .pl_xl--30 {
    padding-left: 30px; }

  .pl_xl--35 {
    padding-left: 35px; }

  .pl_xl--40 {
    padding-left: 40px; }

  .pl_xl--45 {
    padding-left: 45px; }

  .pl_xl--50 {
    padding-left: 50px; }

  .pl_xl--55 {
    padding-left: 55px; }

  .pl_xl--60 {
    padding-left: 60px; }

  .pl_xl--65 {
    padding-left: 65px; }

  .pl_xl--70 {
    padding-left: 70px; }

  .pl_xl--75 {
    padding-left: 75px; }

  .pl_xl--80 {
    padding-left: 80px; }

  .pl_xl--85 {
    padding-left: 85px; }

  .pl_xl--90 {
    padding-left: 90px; }

  .pl_xl--95 {
    padding-left: 95px; }

  .pl_xl--100 {
    padding-left: 100px; }

  .pl_xl--105 {
    padding-left: 105px; }

  .pl_xl--110 {
    padding-left: 110px; }

  .pl_xl--115 {
    padding-left: 115px; }

  .pl_xl--120 {
    padding-left: 120px; }

  .pl_xl--125 {
    padding-left: 125px; }

  .pl_xl--130 {
    padding-left: 130px; }

  .pl_xl--135 {
    padding-left: 135px; }

  .pl_xl--140 {
    padding-left: 140px; }

  .pl_xl--145 {
    padding-left: 145px; }

  .pl_xl--150 {
    padding-left: 150px; }

  .pl_xl--155 {
    padding-left: 155px; }

  .pl_xl--160 {
    padding-left: 160px; }

  .pl_xl--165 {
    padding-left: 165px; }

  .pl_xl--170 {
    padding-left: 170px; }

  .pl_xl--175 {
    padding-left: 175px; }

  .pl_xl--180 {
    padding-left: 180px; }

  .pl_xl--185 {
    padding-left: 185px; }

  .pl_xl--190 {
    padding-left: 190px; }

  .pl_xl--195 {
    padding-left: 195px; }

  .pl_xl--200 {
    padding-left: 200px; }

  .pl_xl--205 {
    padding-left: 205px; }

  .pl_xl--210 {
    padding-left: 210px; }

  .pl_xl--215 {
    padding-left: 215px; }

  .pl_xl--220 {
    padding-left: 220px; }

  .pl_xl--225 {
    padding-left: 225px; }

  .pl_xl--230 {
    padding-left: 230px; }

  .pl_xl--235 {
    padding-left: 235px; }

  .pl_xl--240 {
    padding-left: 240px; }

  .pl_xl--245 {
    padding-left: 245px; }

  .pl_xl--250 {
    padding-left: 250px; } }
.pr--5 {
  padding-right: 5px; }

.pr--10 {
  padding-right: 10px; }

.pr--15 {
  padding-right: 15px; }

.pr--20 {
  padding-right: 20px; }

.pr--25 {
  padding-right: 25px; }

.pr--30 {
  padding-right: 30px; }

.pr--35 {
  padding-right: 35px; }

.pr--40 {
  padding-right: 40px; }

.pr--45 {
  padding-right: 45px; }

.pr--50 {
  padding-right: 50px; }

.pr--55 {
  padding-right: 55px; }

.pr--60 {
  padding-right: 60px; }

.pr--65 {
  padding-right: 65px; }

.pr--70 {
  padding-right: 70px; }

.pr--75 {
  padding-right: 75px; }

.pr--80 {
  padding-right: 80px; }

.pr--85 {
  padding-right: 85px; }

.pr--90 {
  padding-right: 90px; }

.pr--95 {
  padding-right: 95px; }

.pr--100 {
  padding-right: 100px; }

.pr--105 {
  padding-right: 105px; }

.pr--110 {
  padding-right: 110px; }

.pr--115 {
  padding-right: 115px; }

.pr--120 {
  padding-right: 120px; }

.pr--125 {
  padding-right: 125px; }

.pr--130 {
  padding-right: 130px; }

.pr--135 {
  padding-right: 135px; }

.pr--140 {
  padding-right: 140px; }

.pr--145 {
  padding-right: 145px; }

.pr--150 {
  padding-right: 150px; }

.pr--155 {
  padding-right: 155px; }

.pr--160 {
  padding-right: 160px; }

.pr--165 {
  padding-right: 165px; }

.pr--170 {
  padding-right: 170px; }

.pr--175 {
  padding-right: 175px; }

.pr--180 {
  padding-right: 180px; }

.pr--185 {
  padding-right: 185px; }

.pr--190 {
  padding-right: 190px; }

.pr--195 {
  padding-right: 195px; }

.pr--200 {
  padding-right: 200px; }

.pr--205 {
  padding-right: 205px; }

.pr--210 {
  padding-right: 210px; }

.pr--215 {
  padding-right: 215px; }

.pr--220 {
  padding-right: 220px; }

.pr--225 {
  padding-right: 225px; }

.pr--230 {
  padding-right: 230px; }

.pr--235 {
  padding-right: 235px; }

.pr--240 {
  padding-right: 240px; }

.pr--245 {
  padding-right: 245px; }

.pr--250 {
  padding-right: 250px; }

@media (min-width: 576px) {
  .pr_sm--5 {
    padding-right: 5px; }

  .pr_sm--10 {
    padding-right: 10px; }

  .pr_sm--15 {
    padding-right: 15px; }

  .pr_sm--20 {
    padding-right: 20px; }

  .pr_sm--25 {
    padding-right: 25px; }

  .pr_sm--30 {
    padding-right: 30px; }

  .pr_sm--35 {
    padding-right: 35px; }

  .pr_sm--40 {
    padding-right: 40px; }

  .pr_sm--45 {
    padding-right: 45px; }

  .pr_sm--50 {
    padding-right: 50px; }

  .pr_sm--55 {
    padding-right: 55px; }

  .pr_sm--60 {
    padding-right: 60px; }

  .pr_sm--65 {
    padding-right: 65px; }

  .pr_sm--70 {
    padding-right: 70px; }

  .pr_sm--75 {
    padding-right: 75px; }

  .pr_sm--80 {
    padding-right: 80px; }

  .pr_sm--85 {
    padding-right: 85px; }

  .pr_sm--90 {
    padding-right: 90px; }

  .pr_sm--95 {
    padding-right: 95px; }

  .pr_sm--100 {
    padding-right: 100px; }

  .pr_sm--105 {
    padding-right: 105px; }

  .pr_sm--110 {
    padding-right: 110px; }

  .pr_sm--115 {
    padding-right: 115px; }

  .pr_sm--120 {
    padding-right: 120px; }

  .pr_sm--125 {
    padding-right: 125px; }

  .pr_sm--130 {
    padding-right: 130px; }

  .pr_sm--135 {
    padding-right: 135px; }

  .pr_sm--140 {
    padding-right: 140px; }

  .pr_sm--145 {
    padding-right: 145px; }

  .pr_sm--150 {
    padding-right: 150px; }

  .pr_sm--155 {
    padding-right: 155px; }

  .pr_sm--160 {
    padding-right: 160px; }

  .pr_sm--165 {
    padding-right: 165px; }

  .pr_sm--170 {
    padding-right: 170px; }

  .pr_sm--175 {
    padding-right: 175px; }

  .pr_sm--180 {
    padding-right: 180px; }

  .pr_sm--185 {
    padding-right: 185px; }

  .pr_sm--190 {
    padding-right: 190px; }

  .pr_sm--195 {
    padding-right: 195px; }

  .pr_sm--200 {
    padding-right: 200px; }

  .pr_sm--205 {
    padding-right: 205px; }

  .pr_sm--210 {
    padding-right: 210px; }

  .pr_sm--215 {
    padding-right: 215px; }

  .pr_sm--220 {
    padding-right: 220px; }

  .pr_sm--225 {
    padding-right: 225px; }

  .pr_sm--230 {
    padding-right: 230px; }

  .pr_sm--235 {
    padding-right: 235px; }

  .pr_sm--240 {
    padding-right: 240px; }

  .pr_sm--245 {
    padding-right: 245px; }

  .pr_sm--250 {
    padding-right: 250px; } }
@media (min-width: 768px) {
  .pr_md--5 {
    padding-right: 5px; }

  .pr_md--10 {
    padding-right: 10px; }

  .pr_md--15 {
    padding-right: 15px; }

  .pr_md--20 {
    padding-right: 20px; }

  .pr_md--25 {
    padding-right: 25px; }

  .pr_md--30 {
    padding-right: 30px; }

  .pr_md--35 {
    padding-right: 35px; }

  .pr_md--40 {
    padding-right: 40px; }

  .pr_md--45 {
    padding-right: 45px; }

  .pr_md--50 {
    padding-right: 50px; }

  .pr_md--55 {
    padding-right: 55px; }

  .pr_md--60 {
    padding-right: 60px; }

  .pr_md--65 {
    padding-right: 65px; }

  .pr_md--70 {
    padding-right: 70px; }

  .pr_md--75 {
    padding-right: 75px; }

  .pr_md--80 {
    padding-right: 80px; }

  .pr_md--85 {
    padding-right: 85px; }

  .pr_md--90 {
    padding-right: 90px; }

  .pr_md--95 {
    padding-right: 95px; }

  .pr_md--100 {
    padding-right: 100px; }

  .pr_md--105 {
    padding-right: 105px; }

  .pr_md--110 {
    padding-right: 110px; }

  .pr_md--115 {
    padding-right: 115px; }

  .pr_md--120 {
    padding-right: 120px; }

  .pr_md--125 {
    padding-right: 125px; }

  .pr_md--130 {
    padding-right: 130px; }

  .pr_md--135 {
    padding-right: 135px; }

  .pr_md--140 {
    padding-right: 140px; }

  .pr_md--145 {
    padding-right: 145px; }

  .pr_md--150 {
    padding-right: 150px; }

  .pr_md--155 {
    padding-right: 155px; }

  .pr_md--160 {
    padding-right: 160px; }

  .pr_md--165 {
    padding-right: 165px; }

  .pr_md--170 {
    padding-right: 170px; }

  .pr_md--175 {
    padding-right: 175px; }

  .pr_md--180 {
    padding-right: 180px; }

  .pr_md--185 {
    padding-right: 185px; }

  .pr_md--190 {
    padding-right: 190px; }

  .pr_md--195 {
    padding-right: 195px; }

  .pr_md--200 {
    padding-right: 200px; }

  .pr_md--205 {
    padding-right: 205px; }

  .pr_md--210 {
    padding-right: 210px; }

  .pr_md--215 {
    padding-right: 215px; }

  .pr_md--220 {
    padding-right: 220px; }

  .pr_md--225 {
    padding-right: 225px; }

  .pr_md--230 {
    padding-right: 230px; }

  .pr_md--235 {
    padding-right: 235px; }

  .pr_md--240 {
    padding-right: 240px; }

  .pr_md--245 {
    padding-right: 245px; }

  .pr_md--250 {
    padding-right: 250px; } }
@media (min-width: 992px) {
  .pr_lg--5 {
    padding-right: 5px; }

  .pr_lg--10 {
    padding-right: 10px; }

  .pr_lg--15 {
    padding-right: 15px; }

  .pr_lg--20 {
    padding-right: 20px; }

  .pr_lg--25 {
    padding-right: 25px; }

  .pr_lg--30 {
    padding-right: 30px; }

  .pr_lg--35 {
    padding-right: 35px; }

  .pr_lg--40 {
    padding-right: 40px; }

  .pr_lg--45 {
    padding-right: 45px; }

  .pr_lg--50 {
    padding-right: 50px; }

  .pr_lg--55 {
    padding-right: 55px; }

  .pr_lg--60 {
    padding-right: 60px; }

  .pr_lg--65 {
    padding-right: 65px; }

  .pr_lg--70 {
    padding-right: 70px; }

  .pr_lg--75 {
    padding-right: 75px; }

  .pr_lg--80 {
    padding-right: 80px; }

  .pr_lg--85 {
    padding-right: 85px; }

  .pr_lg--90 {
    padding-right: 90px; }

  .pr_lg--95 {
    padding-right: 95px; }

  .pr_lg--100 {
    padding-right: 100px; }

  .pr_lg--105 {
    padding-right: 105px; }

  .pr_lg--110 {
    padding-right: 110px; }

  .pr_lg--115 {
    padding-right: 115px; }

  .pr_lg--120 {
    padding-right: 120px; }

  .pr_lg--125 {
    padding-right: 125px; }

  .pr_lg--130 {
    padding-right: 130px; }

  .pr_lg--135 {
    padding-right: 135px; }

  .pr_lg--140 {
    padding-right: 140px; }

  .pr_lg--145 {
    padding-right: 145px; }

  .pr_lg--150 {
    padding-right: 150px; }

  .pr_lg--155 {
    padding-right: 155px; }

  .pr_lg--160 {
    padding-right: 160px; }

  .pr_lg--165 {
    padding-right: 165px; }

  .pr_lg--170 {
    padding-right: 170px; }

  .pr_lg--175 {
    padding-right: 175px; }

  .pr_lg--180 {
    padding-right: 180px; }

  .pr_lg--185 {
    padding-right: 185px; }

  .pr_lg--190 {
    padding-right: 190px; }

  .pr_lg--195 {
    padding-right: 195px; }

  .pr_lg--200 {
    padding-right: 200px; }

  .pr_lg--205 {
    padding-right: 205px; }

  .pr_lg--210 {
    padding-right: 210px; }

  .pr_lg--215 {
    padding-right: 215px; }

  .pr_lg--220 {
    padding-right: 220px; }

  .pr_lg--225 {
    padding-right: 225px; }

  .pr_lg--230 {
    padding-right: 230px; }

  .pr_lg--235 {
    padding-right: 235px; }

  .pr_lg--240 {
    padding-right: 240px; }

  .pr_lg--245 {
    padding-right: 245px; }

  .pr_lg--250 {
    padding-right: 250px; } }
@media (min-width: 1200px) {
  .pr_xl--5 {
    padding-right: 5px; }

  .pr_xl--10 {
    padding-right: 10px; }

  .pr_xl--15 {
    padding-right: 15px; }

  .pr_xl--20 {
    padding-right: 20px; }

  .pr_xl--25 {
    padding-right: 25px; }

  .pr_xl--30 {
    padding-right: 30px; }

  .pr_xl--35 {
    padding-right: 35px; }

  .pr_xl--40 {
    padding-right: 40px; }

  .pr_xl--45 {
    padding-right: 45px; }

  .pr_xl--50 {
    padding-right: 50px; }

  .pr_xl--55 {
    padding-right: 55px; }

  .pr_xl--60 {
    padding-right: 60px; }

  .pr_xl--65 {
    padding-right: 65px; }

  .pr_xl--70 {
    padding-right: 70px; }

  .pr_xl--75 {
    padding-right: 75px; }

  .pr_xl--80 {
    padding-right: 80px; }

  .pr_xl--85 {
    padding-right: 85px; }

  .pr_xl--90 {
    padding-right: 90px; }

  .pr_xl--95 {
    padding-right: 95px; }

  .pr_xl--100 {
    padding-right: 100px; }

  .pr_xl--105 {
    padding-right: 105px; }

  .pr_xl--110 {
    padding-right: 110px; }

  .pr_xl--115 {
    padding-right: 115px; }

  .pr_xl--120 {
    padding-right: 120px; }

  .pr_xl--125 {
    padding-right: 125px; }

  .pr_xl--130 {
    padding-right: 130px; }

  .pr_xl--135 {
    padding-right: 135px; }

  .pr_xl--140 {
    padding-right: 140px; }

  .pr_xl--145 {
    padding-right: 145px; }

  .pr_xl--150 {
    padding-right: 150px; }

  .pr_xl--155 {
    padding-right: 155px; }

  .pr_xl--160 {
    padding-right: 160px; }

  .pr_xl--165 {
    padding-right: 165px; }

  .pr_xl--170 {
    padding-right: 170px; }

  .pr_xl--175 {
    padding-right: 175px; }

  .pr_xl--180 {
    padding-right: 180px; }

  .pr_xl--185 {
    padding-right: 185px; }

  .pr_xl--190 {
    padding-right: 190px; }

  .pr_xl--195 {
    padding-right: 195px; }

  .pr_xl--200 {
    padding-right: 200px; }

  .pr_xl--205 {
    padding-right: 205px; }

  .pr_xl--210 {
    padding-right: 210px; }

  .pr_xl--215 {
    padding-right: 215px; }

  .pr_xl--220 {
    padding-right: 220px; }

  .pr_xl--225 {
    padding-right: 225px; }

  .pr_xl--230 {
    padding-right: 230px; }

  .pr_xl--235 {
    padding-right: 235px; }

  .pr_xl--240 {
    padding-right: 240px; }

  .pr_xl--245 {
    padding-right: 245px; }

  .pr_xl--250 {
    padding-right: 250px; } }
.mt--5 {
  margin-top: 5px; }

.mt--10 {
  margin-top: 10px; }

.mt--15 {
  margin-top: 15px; }

.mt--20 {
  margin-top: 20px; }

.mt--25 {
  margin-top: 25px; }

.mt--30 {
  margin-top: 30px; }

.mt--35 {
  margin-top: 35px; }

.mt--40 {
  margin-top: 40px; }

.mt--45 {
  margin-top: 45px; }

.mt--50 {
  margin-top: 50px; }

.mt--55 {
  margin-top: 55px; }

.mt--60 {
  margin-top: 60px; }

.mt--65 {
  margin-top: 65px; }

.mt--70 {
  margin-top: 70px; }

.mt--75 {
  margin-top: 75px; }

.mt--80 {
  margin-top: 80px; }

.mt--85 {
  margin-top: 85px; }

.mt--90 {
  margin-top: 90px; }

.mt--95 {
  margin-top: 95px; }

.mt--100 {
  margin-top: 100px; }

.mt--105 {
  margin-top: 105px; }

.mt--110 {
  margin-top: 110px; }

.mt--115 {
  margin-top: 115px; }

.mt--120 {
  margin-top: 120px; }

.mt--125 {
  margin-top: 125px; }

.mt--130 {
  margin-top: 130px; }

.mt--135 {
  margin-top: 135px; }

.mt--140 {
  margin-top: 140px; }

.mt--145 {
  margin-top: 145px; }

.mt--150 {
  margin-top: 150px; }

.mt--155 {
  margin-top: 155px; }

.mt--160 {
  margin-top: 160px; }

.mt--165 {
  margin-top: 165px; }

.mt--170 {
  margin-top: 170px; }

.mt--175 {
  margin-top: 175px; }

.mt--180 {
  margin-top: 180px; }

.mt--185 {
  margin-top: 185px; }

.mt--190 {
  margin-top: 190px; }

.mt--195 {
  margin-top: 195px; }

.mt--200 {
  margin-top: 200px; }

.mt--205 {
  margin-top: 205px; }

.mt--210 {
  margin-top: 210px; }

.mt--215 {
  margin-top: 215px; }

.mt--220 {
  margin-top: 220px; }

.mt--225 {
  margin-top: 225px; }

.mt--230 {
  margin-top: 230px; }

.mt--235 {
  margin-top: 235px; }

.mt--240 {
  margin-top: 240px; }

.mt--245 {
  margin-top: 245px; }

.mt--250 {
  margin-top: 250px; }

@media (min-width: 576px) {
  .mt_sm--5 {
    margin-top: 5px; }

  .mt_sm--10 {
    margin-top: 10px; }

  .mt_sm--15 {
    margin-top: 15px; }

  .mt_sm--20 {
    margin-top: 20px; }

  .mt_sm--25 {
    margin-top: 25px; }

  .mt_sm--30 {
    margin-top: 30px; }

  .mt_sm--35 {
    margin-top: 35px; }

  .mt_sm--40 {
    margin-top: 40px; }

  .mt_sm--45 {
    margin-top: 45px; }

  .mt_sm--50 {
    margin-top: 50px; }

  .mt_sm--55 {
    margin-top: 55px; }

  .mt_sm--60 {
    margin-top: 60px; }

  .mt_sm--65 {
    margin-top: 65px; }

  .mt_sm--70 {
    margin-top: 70px; }

  .mt_sm--75 {
    margin-top: 75px; }

  .mt_sm--80 {
    margin-top: 80px; }

  .mt_sm--85 {
    margin-top: 85px; }

  .mt_sm--90 {
    margin-top: 90px; }

  .mt_sm--95 {
    margin-top: 95px; }

  .mt_sm--100 {
    margin-top: 100px; }

  .mt_sm--105 {
    margin-top: 105px; }

  .mt_sm--110 {
    margin-top: 110px; }

  .mt_sm--115 {
    margin-top: 115px; }

  .mt_sm--120 {
    margin-top: 120px; }

  .mt_sm--125 {
    margin-top: 125px; }

  .mt_sm--130 {
    margin-top: 130px; }

  .mt_sm--135 {
    margin-top: 135px; }

  .mt_sm--140 {
    margin-top: 140px; }

  .mt_sm--145 {
    margin-top: 145px; }

  .mt_sm--150 {
    margin-top: 150px; }

  .mt_sm--155 {
    margin-top: 155px; }

  .mt_sm--160 {
    margin-top: 160px; }

  .mt_sm--165 {
    margin-top: 165px; }

  .mt_sm--170 {
    margin-top: 170px; }

  .mt_sm--175 {
    margin-top: 175px; }

  .mt_sm--180 {
    margin-top: 180px; }

  .mt_sm--185 {
    margin-top: 185px; }

  .mt_sm--190 {
    margin-top: 190px; }

  .mt_sm--195 {
    margin-top: 195px; }

  .mt_sm--200 {
    margin-top: 200px; }

  .mt_sm--205 {
    margin-top: 205px; }

  .mt_sm--210 {
    margin-top: 210px; }

  .mt_sm--215 {
    margin-top: 215px; }

  .mt_sm--220 {
    margin-top: 220px; }

  .mt_sm--225 {
    margin-top: 225px; }

  .mt_sm--230 {
    margin-top: 230px; }

  .mt_sm--235 {
    margin-top: 235px; }

  .mt_sm--240 {
    margin-top: 240px; }

  .mt_sm--245 {
    margin-top: 245px; }

  .mt_sm--250 {
    margin-top: 250px; } }
@media (min-width: 768px) {
  .mt_md--5 {
    margin-top: 5px; }

  .mt_md--10 {
    margin-top: 10px; }

  .mt_md--15 {
    margin-top: 15px; }

  .mt_md--20 {
    margin-top: 20px; }

  .mt_md--25 {
    margin-top: 25px; }

  .mt_md--30 {
    margin-top: 30px; }

  .mt_md--35 {
    margin-top: 35px; }

  .mt_md--40 {
    margin-top: 40px; }

  .mt_md--45 {
    margin-top: 45px; }

  .mt_md--50 {
    margin-top: 50px; }

  .mt_md--55 {
    margin-top: 55px; }

  .mt_md--60 {
    margin-top: 60px; }

  .mt_md--65 {
    margin-top: 65px; }

  .mt_md--70 {
    margin-top: 70px; }

  .mt_md--75 {
    margin-top: 75px; }

  .mt_md--80 {
    margin-top: 80px; }

  .mt_md--85 {
    margin-top: 85px; }

  .mt_md--90 {
    margin-top: 90px; }

  .mt_md--95 {
    margin-top: 95px; }

  .mt_md--100 {
    margin-top: 100px; }

  .mt_md--105 {
    margin-top: 105px; }

  .mt_md--110 {
    margin-top: 110px; }

  .mt_md--115 {
    margin-top: 115px; }

  .mt_md--120 {
    margin-top: 120px; }

  .mt_md--125 {
    margin-top: 125px; }

  .mt_md--130 {
    margin-top: 130px; }

  .mt_md--135 {
    margin-top: 135px; }

  .mt_md--140 {
    margin-top: 140px; }

  .mt_md--145 {
    margin-top: 145px; }

  .mt_md--150 {
    margin-top: 150px; }

  .mt_md--155 {
    margin-top: 155px; }

  .mt_md--160 {
    margin-top: 160px; }

  .mt_md--165 {
    margin-top: 165px; }

  .mt_md--170 {
    margin-top: 170px; }

  .mt_md--175 {
    margin-top: 175px; }

  .mt_md--180 {
    margin-top: 180px; }

  .mt_md--185 {
    margin-top: 185px; }

  .mt_md--190 {
    margin-top: 190px; }

  .mt_md--195 {
    margin-top: 195px; }

  .mt_md--200 {
    margin-top: 200px; }

  .mt_md--205 {
    margin-top: 205px; }

  .mt_md--210 {
    margin-top: 210px; }

  .mt_md--215 {
    margin-top: 215px; }

  .mt_md--220 {
    margin-top: 220px; }

  .mt_md--225 {
    margin-top: 225px; }

  .mt_md--230 {
    margin-top: 230px; }

  .mt_md--235 {
    margin-top: 235px; }

  .mt_md--240 {
    margin-top: 240px; }

  .mt_md--245 {
    margin-top: 245px; }

  .mt_md--250 {
    margin-top: 250px; } }
@media (min-width: 992px) {
  .mt_lg--5 {
    margin-top: 5px; }

  .mt_lg--10 {
    margin-top: 10px; }

  .mt_lg--15 {
    margin-top: 15px; }

  .mt_lg--20 {
    margin-top: 20px; }

  .mt_lg--25 {
    margin-top: 25px; }

  .mt_lg--30 {
    margin-top: 30px; }

  .mt_lg--35 {
    margin-top: 35px; }

  .mt_lg--40 {
    margin-top: 40px; }

  .mt_lg--45 {
    margin-top: 45px; }

  .mt_lg--50 {
    margin-top: 50px; }

  .mt_lg--55 {
    margin-top: 55px; }

  .mt_lg--60 {
    margin-top: 60px; }

  .mt_lg--65 {
    margin-top: 65px; }

  .mt_lg--70 {
    margin-top: 70px; }

  .mt_lg--75 {
    margin-top: 75px; }

  .mt_lg--80 {
    margin-top: 80px; }

  .mt_lg--85 {
    margin-top: 85px; }

  .mt_lg--90 {
    margin-top: 90px; }

  .mt_lg--95 {
    margin-top: 95px; }

  .mt_lg--100 {
    margin-top: 100px; }

  .mt_lg--105 {
    margin-top: 105px; }

  .mt_lg--110 {
    margin-top: 110px; }

  .mt_lg--115 {
    margin-top: 115px; }

  .mt_lg--120 {
    margin-top: 120px; }

  .mt_lg--125 {
    margin-top: 125px; }

  .mt_lg--130 {
    margin-top: 130px; }

  .mt_lg--135 {
    margin-top: 135px; }

  .mt_lg--140 {
    margin-top: 140px; }

  .mt_lg--145 {
    margin-top: 145px; }

  .mt_lg--150 {
    margin-top: 150px; }

  .mt_lg--155 {
    margin-top: 155px; }

  .mt_lg--160 {
    margin-top: 160px; }

  .mt_lg--165 {
    margin-top: 165px; }

  .mt_lg--170 {
    margin-top: 170px; }

  .mt_lg--175 {
    margin-top: 175px; }

  .mt_lg--180 {
    margin-top: 180px; }

  .mt_lg--185 {
    margin-top: 185px; }

  .mt_lg--190 {
    margin-top: 190px; }

  .mt_lg--195 {
    margin-top: 195px; }

  .mt_lg--200 {
    margin-top: 200px; }

  .mt_lg--205 {
    margin-top: 205px; }

  .mt_lg--210 {
    margin-top: 210px; }

  .mt_lg--215 {
    margin-top: 215px; }

  .mt_lg--220 {
    margin-top: 220px; }

  .mt_lg--225 {
    margin-top: 225px; }

  .mt_lg--230 {
    margin-top: 230px; }

  .mt_lg--235 {
    margin-top: 235px; }

  .mt_lg--240 {
    margin-top: 240px; }

  .mt_lg--245 {
    margin-top: 245px; }

  .mt_lg--250 {
    margin-top: 250px; } }
@media (min-width: 1200px) {
  .mt_xl--5 {
    margin-top: 5px; }

  .mt_xl--10 {
    margin-top: 10px; }

  .mt_xl--15 {
    margin-top: 15px; }

  .mt_xl--20 {
    margin-top: 20px; }

  .mt_xl--25 {
    margin-top: 25px; }

  .mt_xl--30 {
    margin-top: 30px; }

  .mt_xl--35 {
    margin-top: 35px; }

  .mt_xl--40 {
    margin-top: 40px; }

  .mt_xl--45 {
    margin-top: 45px; }

  .mt_xl--50 {
    margin-top: 50px; }

  .mt_xl--55 {
    margin-top: 55px; }

  .mt_xl--60 {
    margin-top: 60px; }

  .mt_xl--65 {
    margin-top: 65px; }

  .mt_xl--70 {
    margin-top: 70px; }

  .mt_xl--75 {
    margin-top: 75px; }

  .mt_xl--80 {
    margin-top: 80px; }

  .mt_xl--85 {
    margin-top: 85px; }

  .mt_xl--90 {
    margin-top: 90px; }

  .mt_xl--95 {
    margin-top: 95px; }

  .mt_xl--100 {
    margin-top: 100px; }

  .mt_xl--105 {
    margin-top: 105px; }

  .mt_xl--110 {
    margin-top: 110px; }

  .mt_xl--115 {
    margin-top: 115px; }

  .mt_xl--120 {
    margin-top: 120px; }

  .mt_xl--125 {
    margin-top: 125px; }

  .mt_xl--130 {
    margin-top: 130px; }

  .mt_xl--135 {
    margin-top: 135px; }

  .mt_xl--140 {
    margin-top: 140px; }

  .mt_xl--145 {
    margin-top: 145px; }

  .mt_xl--150 {
    margin-top: 150px; }

  .mt_xl--155 {
    margin-top: 155px; }

  .mt_xl--160 {
    margin-top: 160px; }

  .mt_xl--165 {
    margin-top: 165px; }

  .mt_xl--170 {
    margin-top: 170px; }

  .mt_xl--175 {
    margin-top: 175px; }

  .mt_xl--180 {
    margin-top: 180px; }

  .mt_xl--185 {
    margin-top: 185px; }

  .mt_xl--190 {
    margin-top: 190px; }

  .mt_xl--195 {
    margin-top: 195px; }

  .mt_xl--200 {
    margin-top: 200px; }

  .mt_xl--205 {
    margin-top: 205px; }

  .mt_xl--210 {
    margin-top: 210px; }

  .mt_xl--215 {
    margin-top: 215px; }

  .mt_xl--220 {
    margin-top: 220px; }

  .mt_xl--225 {
    margin-top: 225px; }

  .mt_xl--230 {
    margin-top: 230px; }

  .mt_xl--235 {
    margin-top: 235px; }

  .mt_xl--240 {
    margin-top: 240px; }

  .mt_xl--245 {
    margin-top: 245px; }

  .mt_xl--250 {
    margin-top: 250px; } }
.mb--5 {
  margin-bottom: 5px; }

.mb--10 {
  margin-bottom: 10px; }

.mb--15 {
  margin-bottom: 15px; }

.mb--20 {
  margin-bottom: 20px; }

.mb--25 {
  margin-bottom: 25px; }

.mb--30 {
  margin-bottom: 30px; }

.mb--35 {
  margin-bottom: 35px; }

.mb--40 {
  margin-bottom: 40px; }

.mb--45 {
  margin-bottom: 45px; }

.mb--50 {
  margin-bottom: 50px; }

.mb--55 {
  margin-bottom: 55px; }

.mb--60 {
  margin-bottom: 60px; }

.mb--65 {
  margin-bottom: 65px; }

.mb--70 {
  margin-bottom: 70px; }

.mb--75 {
  margin-bottom: 75px; }

.mb--80 {
  margin-bottom: 80px; }

.mb--85 {
  margin-bottom: 85px; }

.mb--90 {
  margin-bottom: 90px; }

.mb--95 {
  margin-bottom: 95px; }

.mb--100 {
  margin-bottom: 100px; }

.mb--105 {
  margin-bottom: 105px; }

.mb--110 {
  margin-bottom: 110px; }

.mb--115 {
  margin-bottom: 115px; }

.mb--120 {
  margin-bottom: 120px; }

.mb--125 {
  margin-bottom: 125px; }

.mb--130 {
  margin-bottom: 130px; }

.mb--135 {
  margin-bottom: 135px; }

.mb--140 {
  margin-bottom: 140px; }

.mb--145 {
  margin-bottom: 145px; }

.mb--150 {
  margin-bottom: 150px; }

.mb--155 {
  margin-bottom: 155px; }

.mb--160 {
  margin-bottom: 160px; }

.mb--165 {
  margin-bottom: 165px; }

.mb--170 {
  margin-bottom: 170px; }

.mb--175 {
  margin-bottom: 175px; }

.mb--180 {
  margin-bottom: 180px; }

.mb--185 {
  margin-bottom: 185px; }

.mb--190 {
  margin-bottom: 190px; }

.mb--195 {
  margin-bottom: 195px; }

.mb--200 {
  margin-bottom: 200px; }

.mb--205 {
  margin-bottom: 205px; }

.mb--210 {
  margin-bottom: 210px; }

.mb--215 {
  margin-bottom: 215px; }

.mb--220 {
  margin-bottom: 220px; }

.mb--225 {
  margin-bottom: 225px; }

.mb--230 {
  margin-bottom: 230px; }

.mb--235 {
  margin-bottom: 235px; }

.mb--240 {
  margin-bottom: 240px; }

.mb--245 {
  margin-bottom: 245px; }

.mb--250 {
  margin-bottom: 250px; }

@media (min-width: 576px) {
  .mb_sm--5 {
    margin-bottom: 5px; }

  .mb_sm--10 {
    margin-bottom: 10px; }

  .mb_sm--15 {
    margin-bottom: 15px; }

  .mb_sm--20 {
    margin-bottom: 20px; }

  .mb_sm--25 {
    margin-bottom: 25px; }

  .mb_sm--30 {
    margin-bottom: 30px; }

  .mb_sm--35 {
    margin-bottom: 35px; }

  .mb_sm--40 {
    margin-bottom: 40px; }

  .mb_sm--45 {
    margin-bottom: 45px; }

  .mb_sm--50 {
    margin-bottom: 50px; }

  .mb_sm--55 {
    margin-bottom: 55px; }

  .mb_sm--60 {
    margin-bottom: 60px; }

  .mb_sm--65 {
    margin-bottom: 65px; }

  .mb_sm--70 {
    margin-bottom: 70px; }

  .mb_sm--75 {
    margin-bottom: 75px; }

  .mb_sm--80 {
    margin-bottom: 80px; }

  .mb_sm--85 {
    margin-bottom: 85px; }

  .mb_sm--90 {
    margin-bottom: 90px; }

  .mb_sm--95 {
    margin-bottom: 95px; }

  .mb_sm--100 {
    margin-bottom: 100px; }

  .mb_sm--105 {
    margin-bottom: 105px; }

  .mb_sm--110 {
    margin-bottom: 110px; }

  .mb_sm--115 {
    margin-bottom: 115px; }

  .mb_sm--120 {
    margin-bottom: 120px; }

  .mb_sm--125 {
    margin-bottom: 125px; }

  .mb_sm--130 {
    margin-bottom: 130px; }

  .mb_sm--135 {
    margin-bottom: 135px; }

  .mb_sm--140 {
    margin-bottom: 140px; }

  .mb_sm--145 {
    margin-bottom: 145px; }

  .mb_sm--150 {
    margin-bottom: 150px; }

  .mb_sm--155 {
    margin-bottom: 155px; }

  .mb_sm--160 {
    margin-bottom: 160px; }

  .mb_sm--165 {
    margin-bottom: 165px; }

  .mb_sm--170 {
    margin-bottom: 170px; }

  .mb_sm--175 {
    margin-bottom: 175px; }

  .mb_sm--180 {
    margin-bottom: 180px; }

  .mb_sm--185 {
    margin-bottom: 185px; }

  .mb_sm--190 {
    margin-bottom: 190px; }

  .mb_sm--195 {
    margin-bottom: 195px; }

  .mb_sm--200 {
    margin-bottom: 200px; }

  .mb_sm--205 {
    margin-bottom: 205px; }

  .mb_sm--210 {
    margin-bottom: 210px; }

  .mb_sm--215 {
    margin-bottom: 215px; }

  .mb_sm--220 {
    margin-bottom: 220px; }

  .mb_sm--225 {
    margin-bottom: 225px; }

  .mb_sm--230 {
    margin-bottom: 230px; }

  .mb_sm--235 {
    margin-bottom: 235px; }

  .mb_sm--240 {
    margin-bottom: 240px; }

  .mb_sm--245 {
    margin-bottom: 245px; }

  .mb_sm--250 {
    margin-bottom: 250px; } }
@media (min-width: 768px) {
  .mb_md--5 {
    margin-bottom: 5px; }

  .mb_md--10 {
    margin-bottom: 10px; }

  .mb_md--15 {
    margin-bottom: 15px; }

  .mb_md--20 {
    margin-bottom: 20px; }

  .mb_md--25 {
    margin-bottom: 25px; }

  .mb_md--30 {
    margin-bottom: 30px; }

  .mb_md--35 {
    margin-bottom: 35px; }

  .mb_md--40 {
    margin-bottom: 40px; }

  .mb_md--45 {
    margin-bottom: 45px; }

  .mb_md--50 {
    margin-bottom: 50px; }

  .mb_md--55 {
    margin-bottom: 55px; }

  .mb_md--60 {
    margin-bottom: 60px; }

  .mb_md--65 {
    margin-bottom: 65px; }

  .mb_md--70 {
    margin-bottom: 70px; }

  .mb_md--75 {
    margin-bottom: 75px; }

  .mb_md--80 {
    margin-bottom: 80px; }

  .mb_md--85 {
    margin-bottom: 85px; }

  .mb_md--90 {
    margin-bottom: 90px; }

  .mb_md--95 {
    margin-bottom: 95px; }

  .mb_md--100 {
    margin-bottom: 100px; }

  .mb_md--105 {
    margin-bottom: 105px; }

  .mb_md--110 {
    margin-bottom: 110px; }

  .mb_md--115 {
    margin-bottom: 115px; }

  .mb_md--120 {
    margin-bottom: 120px; }

  .mb_md--125 {
    margin-bottom: 125px; }

  .mb_md--130 {
    margin-bottom: 130px; }

  .mb_md--135 {
    margin-bottom: 135px; }

  .mb_md--140 {
    margin-bottom: 140px; }

  .mb_md--145 {
    margin-bottom: 145px; }

  .mb_md--150 {
    margin-bottom: 150px; }

  .mb_md--155 {
    margin-bottom: 155px; }

  .mb_md--160 {
    margin-bottom: 160px; }

  .mb_md--165 {
    margin-bottom: 165px; }

  .mb_md--170 {
    margin-bottom: 170px; }

  .mb_md--175 {
    margin-bottom: 175px; }

  .mb_md--180 {
    margin-bottom: 180px; }

  .mb_md--185 {
    margin-bottom: 185px; }

  .mb_md--190 {
    margin-bottom: 190px; }

  .mb_md--195 {
    margin-bottom: 195px; }

  .mb_md--200 {
    margin-bottom: 200px; }

  .mb_md--205 {
    margin-bottom: 205px; }

  .mb_md--210 {
    margin-bottom: 210px; }

  .mb_md--215 {
    margin-bottom: 215px; }

  .mb_md--220 {
    margin-bottom: 220px; }

  .mb_md--225 {
    margin-bottom: 225px; }

  .mb_md--230 {
    margin-bottom: 230px; }

  .mb_md--235 {
    margin-bottom: 235px; }

  .mb_md--240 {
    margin-bottom: 240px; }

  .mb_md--245 {
    margin-bottom: 245px; }

  .mb_md--250 {
    margin-bottom: 250px; } }
@media (min-width: 992px) {
  .mb_lg--5 {
    margin-bottom: 5px; }

  .mb_lg--10 {
    margin-bottom: 10px; }

  .mb_lg--15 {
    margin-bottom: 15px; }

  .mb_lg--20 {
    margin-bottom: 20px; }

  .mb_lg--25 {
    margin-bottom: 25px; }

  .mb_lg--30 {
    margin-bottom: 30px; }

  .mb_lg--35 {
    margin-bottom: 35px; }

  .mb_lg--40 {
    margin-bottom: 40px; }

  .mb_lg--45 {
    margin-bottom: 45px; }

  .mb_lg--50 {
    margin-bottom: 50px; }

  .mb_lg--55 {
    margin-bottom: 55px; }

  .mb_lg--60 {
    margin-bottom: 60px; }

  .mb_lg--65 {
    margin-bottom: 65px; }

  .mb_lg--70 {
    margin-bottom: 70px; }

  .mb_lg--75 {
    margin-bottom: 75px; }

  .mb_lg--80 {
    margin-bottom: 80px; }

  .mb_lg--85 {
    margin-bottom: 85px; }

  .mb_lg--90 {
    margin-bottom: 90px; }

  .mb_lg--95 {
    margin-bottom: 95px; }

  .mb_lg--100 {
    margin-bottom: 100px; }

  .mb_lg--105 {
    margin-bottom: 105px; }

  .mb_lg--110 {
    margin-bottom: 110px; }

  .mb_lg--115 {
    margin-bottom: 115px; }

  .mb_lg--120 {
    margin-bottom: 120px; }

  .mb_lg--125 {
    margin-bottom: 125px; }

  .mb_lg--130 {
    margin-bottom: 130px; }

  .mb_lg--135 {
    margin-bottom: 135px; }

  .mb_lg--140 {
    margin-bottom: 140px; }

  .mb_lg--145 {
    margin-bottom: 145px; }

  .mb_lg--150 {
    margin-bottom: 150px; }

  .mb_lg--155 {
    margin-bottom: 155px; }

  .mb_lg--160 {
    margin-bottom: 160px; }

  .mb_lg--165 {
    margin-bottom: 165px; }

  .mb_lg--170 {
    margin-bottom: 170px; }

  .mb_lg--175 {
    margin-bottom: 175px; }

  .mb_lg--180 {
    margin-bottom: 180px; }

  .mb_lg--185 {
    margin-bottom: 185px; }

  .mb_lg--190 {
    margin-bottom: 190px; }

  .mb_lg--195 {
    margin-bottom: 195px; }

  .mb_lg--200 {
    margin-bottom: 200px; }

  .mb_lg--205 {
    margin-bottom: 205px; }

  .mb_lg--210 {
    margin-bottom: 210px; }

  .mb_lg--215 {
    margin-bottom: 215px; }

  .mb_lg--220 {
    margin-bottom: 220px; }

  .mb_lg--225 {
    margin-bottom: 225px; }

  .mb_lg--230 {
    margin-bottom: 230px; }

  .mb_lg--235 {
    margin-bottom: 235px; }

  .mb_lg--240 {
    margin-bottom: 240px; }

  .mb_lg--245 {
    margin-bottom: 245px; }

  .mb_lg--250 {
    margin-bottom: 250px; } }
@media (min-width: 1200px) {
  .mb_xl--5 {
    margin-bottom: 5px; }

  .mb_xl--10 {
    margin-bottom: 10px; }

  .mb_xl--15 {
    margin-bottom: 15px; }

  .mb_xl--20 {
    margin-bottom: 20px; }

  .mb_xl--25 {
    margin-bottom: 25px; }

  .mb_xl--30 {
    margin-bottom: 30px; }

  .mb_xl--35 {
    margin-bottom: 35px; }

  .mb_xl--40 {
    margin-bottom: 40px; }

  .mb_xl--45 {
    margin-bottom: 45px; }

  .mb_xl--50 {
    margin-bottom: 50px; }

  .mb_xl--55 {
    margin-bottom: 55px; }

  .mb_xl--60 {
    margin-bottom: 60px; }

  .mb_xl--65 {
    margin-bottom: 65px; }

  .mb_xl--70 {
    margin-bottom: 70px; }

  .mb_xl--75 {
    margin-bottom: 75px; }

  .mb_xl--80 {
    margin-bottom: 80px; }

  .mb_xl--85 {
    margin-bottom: 85px; }

  .mb_xl--90 {
    margin-bottom: 90px; }

  .mb_xl--95 {
    margin-bottom: 95px; }

  .mb_xl--100 {
    margin-bottom: 100px; }

  .mb_xl--105 {
    margin-bottom: 105px; }

  .mb_xl--110 {
    margin-bottom: 110px; }

  .mb_xl--115 {
    margin-bottom: 115px; }

  .mb_xl--120 {
    margin-bottom: 120px; }

  .mb_xl--125 {
    margin-bottom: 125px; }

  .mb_xl--130 {
    margin-bottom: 130px; }

  .mb_xl--135 {
    margin-bottom: 135px; }

  .mb_xl--140 {
    margin-bottom: 140px; }

  .mb_xl--145 {
    margin-bottom: 145px; }

  .mb_xl--150 {
    margin-bottom: 150px; }

  .mb_xl--155 {
    margin-bottom: 155px; }

  .mb_xl--160 {
    margin-bottom: 160px; }

  .mb_xl--165 {
    margin-bottom: 165px; }

  .mb_xl--170 {
    margin-bottom: 170px; }

  .mb_xl--175 {
    margin-bottom: 175px; }

  .mb_xl--180 {
    margin-bottom: 180px; }

  .mb_xl--185 {
    margin-bottom: 185px; }

  .mb_xl--190 {
    margin-bottom: 190px; }

  .mb_xl--195 {
    margin-bottom: 195px; }

  .mb_xl--200 {
    margin-bottom: 200px; }

  .mb_xl--205 {
    margin-bottom: 205px; }

  .mb_xl--210 {
    margin-bottom: 210px; }

  .mb_xl--215 {
    margin-bottom: 215px; }

  .mb_xl--220 {
    margin-bottom: 220px; }

  .mb_xl--225 {
    margin-bottom: 225px; }

  .mb_xl--230 {
    margin-bottom: 230px; }

  .mb_xl--235 {
    margin-bottom: 235px; }

  .mb_xl--240 {
    margin-bottom: 240px; }

  .mb_xl--245 {
    margin-bottom: 245px; }

  .mb_xl--250 {
    margin-bottom: 250px; } }
.mr--5 {
  margin-right: 5px; }

.mr--10 {
  margin-right: 10px; }

.mr--15 {
  margin-right: 15px; }

.mr--20 {
  margin-right: 20px; }

.mr--25 {
  margin-right: 25px; }

.mr--30 {
  margin-right: 30px; }

.mr--35 {
  margin-right: 35px; }

.mr--40 {
  margin-right: 40px; }

.mr--45 {
  margin-right: 45px; }

.mr--50 {
  margin-right: 50px; }

.mr--55 {
  margin-right: 55px; }

.mr--60 {
  margin-right: 60px; }

.mr--65 {
  margin-right: 65px; }

.mr--70 {
  margin-right: 70px; }

.mr--75 {
  margin-right: 75px; }

.mr--80 {
  margin-right: 80px; }

.mr--85 {
  margin-right: 85px; }

.mr--90 {
  margin-right: 90px; }

.mr--95 {
  margin-right: 95px; }

.mr--100 {
  margin-right: 100px; }

.mr--105 {
  margin-right: 105px; }

.mr--110 {
  margin-right: 110px; }

.mr--115 {
  margin-right: 115px; }

.mr--120 {
  margin-right: 120px; }

.mr--125 {
  margin-right: 125px; }

.mr--130 {
  margin-right: 130px; }

.mr--135 {
  margin-right: 135px; }

.mr--140 {
  margin-right: 140px; }

.mr--145 {
  margin-right: 145px; }

.mr--150 {
  margin-right: 150px; }

.mr--155 {
  margin-right: 155px; }

.mr--160 {
  margin-right: 160px; }

.mr--165 {
  margin-right: 165px; }

.mr--170 {
  margin-right: 170px; }

.mr--175 {
  margin-right: 175px; }

.mr--180 {
  margin-right: 180px; }

.mr--185 {
  margin-right: 185px; }

.mr--190 {
  margin-right: 190px; }

.mr--195 {
  margin-right: 195px; }

.mr--200 {
  margin-right: 200px; }

.mr--205 {
  margin-right: 205px; }

.mr--210 {
  margin-right: 210px; }

.mr--215 {
  margin-right: 215px; }

.mr--220 {
  margin-right: 220px; }

.mr--225 {
  margin-right: 225px; }

.mr--230 {
  margin-right: 230px; }

.mr--235 {
  margin-right: 235px; }

.mr--240 {
  margin-right: 240px; }

.mr--245 {
  margin-right: 245px; }

.mr--250 {
  margin-right: 250px; }

@media (min-width: 576px) {
  .mr_sm--5 {
    margin-right: 5px; }

  .mr_sm--10 {
    margin-right: 10px; }

  .mr_sm--15 {
    margin-right: 15px; }

  .mr_sm--20 {
    margin-right: 20px; }

  .mr_sm--25 {
    margin-right: 25px; }

  .mr_sm--30 {
    margin-right: 30px; }

  .mr_sm--35 {
    margin-right: 35px; }

  .mr_sm--40 {
    margin-right: 40px; }

  .mr_sm--45 {
    margin-right: 45px; }

  .mr_sm--50 {
    margin-right: 50px; }

  .mr_sm--55 {
    margin-right: 55px; }

  .mr_sm--60 {
    margin-right: 60px; }

  .mr_sm--65 {
    margin-right: 65px; }

  .mr_sm--70 {
    margin-right: 70px; }

  .mr_sm--75 {
    margin-right: 75px; }

  .mr_sm--80 {
    margin-right: 80px; }

  .mr_sm--85 {
    margin-right: 85px; }

  .mr_sm--90 {
    margin-right: 90px; }

  .mr_sm--95 {
    margin-right: 95px; }

  .mr_sm--100 {
    margin-right: 100px; }

  .mr_sm--105 {
    margin-right: 105px; }

  .mr_sm--110 {
    margin-right: 110px; }

  .mr_sm--115 {
    margin-right: 115px; }

  .mr_sm--120 {
    margin-right: 120px; }

  .mr_sm--125 {
    margin-right: 125px; }

  .mr_sm--130 {
    margin-right: 130px; }

  .mr_sm--135 {
    margin-right: 135px; }

  .mr_sm--140 {
    margin-right: 140px; }

  .mr_sm--145 {
    margin-right: 145px; }

  .mr_sm--150 {
    margin-right: 150px; }

  .mr_sm--155 {
    margin-right: 155px; }

  .mr_sm--160 {
    margin-right: 160px; }

  .mr_sm--165 {
    margin-right: 165px; }

  .mr_sm--170 {
    margin-right: 170px; }

  .mr_sm--175 {
    margin-right: 175px; }

  .mr_sm--180 {
    margin-right: 180px; }

  .mr_sm--185 {
    margin-right: 185px; }

  .mr_sm--190 {
    margin-right: 190px; }

  .mr_sm--195 {
    margin-right: 195px; }

  .mr_sm--200 {
    margin-right: 200px; }

  .mr_sm--205 {
    margin-right: 205px; }

  .mr_sm--210 {
    margin-right: 210px; }

  .mr_sm--215 {
    margin-right: 215px; }

  .mr_sm--220 {
    margin-right: 220px; }

  .mr_sm--225 {
    margin-right: 225px; }

  .mr_sm--230 {
    margin-right: 230px; }

  .mr_sm--235 {
    margin-right: 235px; }

  .mr_sm--240 {
    margin-right: 240px; }

  .mr_sm--245 {
    margin-right: 245px; }

  .mr_sm--250 {
    margin-right: 250px; } }
@media (min-width: 768px) {
  .mr_md--5 {
    margin-right: 5px; }

  .mr_md--10 {
    margin-right: 10px; }

  .mr_md--15 {
    margin-right: 15px; }

  .mr_md--20 {
    margin-right: 20px; }

  .mr_md--25 {
    margin-right: 25px; }

  .mr_md--30 {
    margin-right: 30px; }

  .mr_md--35 {
    margin-right: 35px; }

  .mr_md--40 {
    margin-right: 40px; }

  .mr_md--45 {
    margin-right: 45px; }

  .mr_md--50 {
    margin-right: 50px; }

  .mr_md--55 {
    margin-right: 55px; }

  .mr_md--60 {
    margin-right: 60px; }

  .mr_md--65 {
    margin-right: 65px; }

  .mr_md--70 {
    margin-right: 70px; }

  .mr_md--75 {
    margin-right: 75px; }

  .mr_md--80 {
    margin-right: 80px; }

  .mr_md--85 {
    margin-right: 85px; }

  .mr_md--90 {
    margin-right: 90px; }

  .mr_md--95 {
    margin-right: 95px; }

  .mr_md--100 {
    margin-right: 100px; }

  .mr_md--105 {
    margin-right: 105px; }

  .mr_md--110 {
    margin-right: 110px; }

  .mr_md--115 {
    margin-right: 115px; }

  .mr_md--120 {
    margin-right: 120px; }

  .mr_md--125 {
    margin-right: 125px; }

  .mr_md--130 {
    margin-right: 130px; }

  .mr_md--135 {
    margin-right: 135px; }

  .mr_md--140 {
    margin-right: 140px; }

  .mr_md--145 {
    margin-right: 145px; }

  .mr_md--150 {
    margin-right: 150px; }

  .mr_md--155 {
    margin-right: 155px; }

  .mr_md--160 {
    margin-right: 160px; }

  .mr_md--165 {
    margin-right: 165px; }

  .mr_md--170 {
    margin-right: 170px; }

  .mr_md--175 {
    margin-right: 175px; }

  .mr_md--180 {
    margin-right: 180px; }

  .mr_md--185 {
    margin-right: 185px; }

  .mr_md--190 {
    margin-right: 190px; }

  .mr_md--195 {
    margin-right: 195px; }

  .mr_md--200 {
    margin-right: 200px; }

  .mr_md--205 {
    margin-right: 205px; }

  .mr_md--210 {
    margin-right: 210px; }

  .mr_md--215 {
    margin-right: 215px; }

  .mr_md--220 {
    margin-right: 220px; }

  .mr_md--225 {
    margin-right: 225px; }

  .mr_md--230 {
    margin-right: 230px; }

  .mr_md--235 {
    margin-right: 235px; }

  .mr_md--240 {
    margin-right: 240px; }

  .mr_md--245 {
    margin-right: 245px; }

  .mr_md--250 {
    margin-right: 250px; } }
@media (min-width: 992px) {
  .mr_lg--5 {
    margin-right: 5px; }

  .mr_lg--10 {
    margin-right: 10px; }

  .mr_lg--15 {
    margin-right: 15px; }

  .mr_lg--20 {
    margin-right: 20px; }

  .mr_lg--25 {
    margin-right: 25px; }

  .mr_lg--30 {
    margin-right: 30px; }

  .mr_lg--35 {
    margin-right: 35px; }

  .mr_lg--40 {
    margin-right: 40px; }

  .mr_lg--45 {
    margin-right: 45px; }

  .mr_lg--50 {
    margin-right: 50px; }

  .mr_lg--55 {
    margin-right: 55px; }

  .mr_lg--60 {
    margin-right: 60px; }

  .mr_lg--65 {
    margin-right: 65px; }

  .mr_lg--70 {
    margin-right: 70px; }

  .mr_lg--75 {
    margin-right: 75px; }

  .mr_lg--80 {
    margin-right: 80px; }

  .mr_lg--85 {
    margin-right: 85px; }

  .mr_lg--90 {
    margin-right: 90px; }

  .mr_lg--95 {
    margin-right: 95px; }

  .mr_lg--100 {
    margin-right: 100px; }

  .mr_lg--105 {
    margin-right: 105px; }

  .mr_lg--110 {
    margin-right: 110px; }

  .mr_lg--115 {
    margin-right: 115px; }

  .mr_lg--120 {
    margin-right: 120px; }

  .mr_lg--125 {
    margin-right: 125px; }

  .mr_lg--130 {
    margin-right: 130px; }

  .mr_lg--135 {
    margin-right: 135px; }

  .mr_lg--140 {
    margin-right: 140px; }

  .mr_lg--145 {
    margin-right: 145px; }

  .mr_lg--150 {
    margin-right: 150px; }

  .mr_lg--155 {
    margin-right: 155px; }

  .mr_lg--160 {
    margin-right: 160px; }

  .mr_lg--165 {
    margin-right: 165px; }

  .mr_lg--170 {
    margin-right: 170px; }

  .mr_lg--175 {
    margin-right: 175px; }

  .mr_lg--180 {
    margin-right: 180px; }

  .mr_lg--185 {
    margin-right: 185px; }

  .mr_lg--190 {
    margin-right: 190px; }

  .mr_lg--195 {
    margin-right: 195px; }

  .mr_lg--200 {
    margin-right: 200px; }

  .mr_lg--205 {
    margin-right: 205px; }

  .mr_lg--210 {
    margin-right: 210px; }

  .mr_lg--215 {
    margin-right: 215px; }

  .mr_lg--220 {
    margin-right: 220px; }

  .mr_lg--225 {
    margin-right: 225px; }

  .mr_lg--230 {
    margin-right: 230px; }

  .mr_lg--235 {
    margin-right: 235px; }

  .mr_lg--240 {
    margin-right: 240px; }

  .mr_lg--245 {
    margin-right: 245px; }

  .mr_lg--250 {
    margin-right: 250px; } }
@media (min-width: 1200px) {
  .mr_xl--5 {
    margin-right: 5px; }

  .mr_xl--10 {
    margin-right: 10px; }

  .mr_xl--15 {
    margin-right: 15px; }

  .mr_xl--20 {
    margin-right: 20px; }

  .mr_xl--25 {
    margin-right: 25px; }

  .mr_xl--30 {
    margin-right: 30px; }

  .mr_xl--35 {
    margin-right: 35px; }

  .mr_xl--40 {
    margin-right: 40px; }

  .mr_xl--45 {
    margin-right: 45px; }

  .mr_xl--50 {
    margin-right: 50px; }

  .mr_xl--55 {
    margin-right: 55px; }

  .mr_xl--60 {
    margin-right: 60px; }

  .mr_xl--65 {
    margin-right: 65px; }

  .mr_xl--70 {
    margin-right: 70px; }

  .mr_xl--75 {
    margin-right: 75px; }

  .mr_xl--80 {
    margin-right: 80px; }

  .mr_xl--85 {
    margin-right: 85px; }

  .mr_xl--90 {
    margin-right: 90px; }

  .mr_xl--95 {
    margin-right: 95px; }

  .mr_xl--100 {
    margin-right: 100px; }

  .mr_xl--105 {
    margin-right: 105px; }

  .mr_xl--110 {
    margin-right: 110px; }

  .mr_xl--115 {
    margin-right: 115px; }

  .mr_xl--120 {
    margin-right: 120px; }

  .mr_xl--125 {
    margin-right: 125px; }

  .mr_xl--130 {
    margin-right: 130px; }

  .mr_xl--135 {
    margin-right: 135px; }

  .mr_xl--140 {
    margin-right: 140px; }

  .mr_xl--145 {
    margin-right: 145px; }

  .mr_xl--150 {
    margin-right: 150px; }

  .mr_xl--155 {
    margin-right: 155px; }

  .mr_xl--160 {
    margin-right: 160px; }

  .mr_xl--165 {
    margin-right: 165px; }

  .mr_xl--170 {
    margin-right: 170px; }

  .mr_xl--175 {
    margin-right: 175px; }

  .mr_xl--180 {
    margin-right: 180px; }

  .mr_xl--185 {
    margin-right: 185px; }

  .mr_xl--190 {
    margin-right: 190px; }

  .mr_xl--195 {
    margin-right: 195px; }

  .mr_xl--200 {
    margin-right: 200px; }

  .mr_xl--205 {
    margin-right: 205px; }

  .mr_xl--210 {
    margin-right: 210px; }

  .mr_xl--215 {
    margin-right: 215px; }

  .mr_xl--220 {
    margin-right: 220px; }

  .mr_xl--225 {
    margin-right: 225px; }

  .mr_xl--230 {
    margin-right: 230px; }

  .mr_xl--235 {
    margin-right: 235px; }

  .mr_xl--240 {
    margin-right: 240px; }

  .mr_xl--245 {
    margin-right: 245px; }

  .mr_xl--250 {
    margin-right: 250px; } }
.ml--5 {
  margin-left: 5px; }

.ml--10 {
  margin-left: 10px; }

.ml--15 {
  margin-left: 15px; }

.ml--20 {
  margin-left: 20px; }

.ml--25 {
  margin-left: 25px; }

.ml--30 {
  margin-left: 30px; }

.ml--35 {
  margin-left: 35px; }

.ml--40 {
  margin-left: 40px; }

.ml--45 {
  margin-left: 45px; }

.ml--50 {
  margin-left: 50px; }

.ml--55 {
  margin-left: 55px; }

.ml--60 {
  margin-left: 60px; }

.ml--65 {
  margin-left: 65px; }

.ml--70 {
  margin-left: 70px; }

.ml--75 {
  margin-left: 75px; }

.ml--80 {
  margin-left: 80px; }

.ml--85 {
  margin-left: 85px; }

.ml--90 {
  margin-left: 90px; }

.ml--95 {
  margin-left: 95px; }

.ml--100 {
  margin-left: 100px; }

.ml--105 {
  margin-left: 105px; }

.ml--110 {
  margin-left: 110px; }

.ml--115 {
  margin-left: 115px; }

.ml--120 {
  margin-left: 120px; }

.ml--125 {
  margin-left: 125px; }

.ml--130 {
  margin-left: 130px; }

.ml--135 {
  margin-left: 135px; }

.ml--140 {
  margin-left: 140px; }

.ml--145 {
  margin-left: 145px; }

.ml--150 {
  margin-left: 150px; }

.ml--155 {
  margin-left: 155px; }

.ml--160 {
  margin-left: 160px; }

.ml--165 {
  margin-left: 165px; }

.ml--170 {
  margin-left: 170px; }

.ml--175 {
  margin-left: 175px; }

.ml--180 {
  margin-left: 180px; }

.ml--185 {
  margin-left: 185px; }

.ml--190 {
  margin-left: 190px; }

.ml--195 {
  margin-left: 195px; }

.ml--200 {
  margin-left: 200px; }

.ml--205 {
  margin-left: 205px; }

.ml--210 {
  margin-left: 210px; }

.ml--215 {
  margin-left: 215px; }

.ml--220 {
  margin-left: 220px; }

.ml--225 {
  margin-left: 225px; }

.ml--230 {
  margin-left: 230px; }

.ml--235 {
  margin-left: 235px; }

.ml--240 {
  margin-left: 240px; }

.ml--245 {
  margin-left: 245px; }

.ml--250 {
  margin-left: 250px; }

@media (min-width: 576px) {
  .ml_sm--5 {
    margin-left: 5px; }

  .ml_sm--10 {
    margin-left: 10px; }

  .ml_sm--15 {
    margin-left: 15px; }

  .ml_sm--20 {
    margin-left: 20px; }

  .ml_sm--25 {
    margin-left: 25px; }

  .ml_sm--30 {
    margin-left: 30px; }

  .ml_sm--35 {
    margin-left: 35px; }

  .ml_sm--40 {
    margin-left: 40px; }

  .ml_sm--45 {
    margin-left: 45px; }

  .ml_sm--50 {
    margin-left: 50px; }

  .ml_sm--55 {
    margin-left: 55px; }

  .ml_sm--60 {
    margin-left: 60px; }

  .ml_sm--65 {
    margin-left: 65px; }

  .ml_sm--70 {
    margin-left: 70px; }

  .ml_sm--75 {
    margin-left: 75px; }

  .ml_sm--80 {
    margin-left: 80px; }

  .ml_sm--85 {
    margin-left: 85px; }

  .ml_sm--90 {
    margin-left: 90px; }

  .ml_sm--95 {
    margin-left: 95px; }

  .ml_sm--100 {
    margin-left: 100px; }

  .ml_sm--105 {
    margin-left: 105px; }

  .ml_sm--110 {
    margin-left: 110px; }

  .ml_sm--115 {
    margin-left: 115px; }

  .ml_sm--120 {
    margin-left: 120px; }

  .ml_sm--125 {
    margin-left: 125px; }

  .ml_sm--130 {
    margin-left: 130px; }

  .ml_sm--135 {
    margin-left: 135px; }

  .ml_sm--140 {
    margin-left: 140px; }

  .ml_sm--145 {
    margin-left: 145px; }

  .ml_sm--150 {
    margin-left: 150px; }

  .ml_sm--155 {
    margin-left: 155px; }

  .ml_sm--160 {
    margin-left: 160px; }

  .ml_sm--165 {
    margin-left: 165px; }

  .ml_sm--170 {
    margin-left: 170px; }

  .ml_sm--175 {
    margin-left: 175px; }

  .ml_sm--180 {
    margin-left: 180px; }

  .ml_sm--185 {
    margin-left: 185px; }

  .ml_sm--190 {
    margin-left: 190px; }

  .ml_sm--195 {
    margin-left: 195px; }

  .ml_sm--200 {
    margin-left: 200px; }

  .ml_sm--205 {
    margin-left: 205px; }

  .ml_sm--210 {
    margin-left: 210px; }

  .ml_sm--215 {
    margin-left: 215px; }

  .ml_sm--220 {
    margin-left: 220px; }

  .ml_sm--225 {
    margin-left: 225px; }

  .ml_sm--230 {
    margin-left: 230px; }

  .ml_sm--235 {
    margin-left: 235px; }

  .ml_sm--240 {
    margin-left: 240px; }

  .ml_sm--245 {
    margin-left: 245px; }

  .ml_sm--250 {
    margin-left: 250px; } }
@media (min-width: 768px) {
  .ml_md--5 {
    margin-left: 5px; }

  .ml_md--10 {
    margin-left: 10px; }

  .ml_md--15 {
    margin-left: 15px; }

  .ml_md--20 {
    margin-left: 20px; }

  .ml_md--25 {
    margin-left: 25px; }

  .ml_md--30 {
    margin-left: 30px; }

  .ml_md--35 {
    margin-left: 35px; }

  .ml_md--40 {
    margin-left: 40px; }

  .ml_md--45 {
    margin-left: 45px; }

  .ml_md--50 {
    margin-left: 50px; }

  .ml_md--55 {
    margin-left: 55px; }

  .ml_md--60 {
    margin-left: 60px; }

  .ml_md--65 {
    margin-left: 65px; }

  .ml_md--70 {
    margin-left: 70px; }

  .ml_md--75 {
    margin-left: 75px; }

  .ml_md--80 {
    margin-left: 80px; }

  .ml_md--85 {
    margin-left: 85px; }

  .ml_md--90 {
    margin-left: 90px; }

  .ml_md--95 {
    margin-left: 95px; }

  .ml_md--100 {
    margin-left: 100px; }

  .ml_md--105 {
    margin-left: 105px; }

  .ml_md--110 {
    margin-left: 110px; }

  .ml_md--115 {
    margin-left: 115px; }

  .ml_md--120 {
    margin-left: 120px; }

  .ml_md--125 {
    margin-left: 125px; }

  .ml_md--130 {
    margin-left: 130px; }

  .ml_md--135 {
    margin-left: 135px; }

  .ml_md--140 {
    margin-left: 140px; }

  .ml_md--145 {
    margin-left: 145px; }

  .ml_md--150 {
    margin-left: 150px; }

  .ml_md--155 {
    margin-left: 155px; }

  .ml_md--160 {
    margin-left: 160px; }

  .ml_md--165 {
    margin-left: 165px; }

  .ml_md--170 {
    margin-left: 170px; }

  .ml_md--175 {
    margin-left: 175px; }

  .ml_md--180 {
    margin-left: 180px; }

  .ml_md--185 {
    margin-left: 185px; }

  .ml_md--190 {
    margin-left: 190px; }

  .ml_md--195 {
    margin-left: 195px; }

  .ml_md--200 {
    margin-left: 200px; }

  .ml_md--205 {
    margin-left: 205px; }

  .ml_md--210 {
    margin-left: 210px; }

  .ml_md--215 {
    margin-left: 215px; }

  .ml_md--220 {
    margin-left: 220px; }

  .ml_md--225 {
    margin-left: 225px; }

  .ml_md--230 {
    margin-left: 230px; }

  .ml_md--235 {
    margin-left: 235px; }

  .ml_md--240 {
    margin-left: 240px; }

  .ml_md--245 {
    margin-left: 245px; }

  .ml_md--250 {
    margin-left: 250px; } }
@media (min-width: 992px) {
  .ml_lg--5 {
    margin-left: 5px; }

  .ml_lg--10 {
    margin-left: 10px; }

  .ml_lg--15 {
    margin-left: 15px; }

  .ml_lg--20 {
    margin-left: 20px; }

  .ml_lg--25 {
    margin-left: 25px; }

  .ml_lg--30 {
    margin-left: 30px; }

  .ml_lg--35 {
    margin-left: 35px; }

  .ml_lg--40 {
    margin-left: 40px; }

  .ml_lg--45 {
    margin-left: 45px; }

  .ml_lg--50 {
    margin-left: 50px; }

  .ml_lg--55 {
    margin-left: 55px; }

  .ml_lg--60 {
    margin-left: 60px; }

  .ml_lg--65 {
    margin-left: 65px; }

  .ml_lg--70 {
    margin-left: 70px; }

  .ml_lg--75 {
    margin-left: 75px; }

  .ml_lg--80 {
    margin-left: 80px; }

  .ml_lg--85 {
    margin-left: 85px; }

  .ml_lg--90 {
    margin-left: 90px; }

  .ml_lg--95 {
    margin-left: 95px; }

  .ml_lg--100 {
    margin-left: 100px; }

  .ml_lg--105 {
    margin-left: 105px; }

  .ml_lg--110 {
    margin-left: 110px; }

  .ml_lg--115 {
    margin-left: 115px; }

  .ml_lg--120 {
    margin-left: 120px; }

  .ml_lg--125 {
    margin-left: 125px; }

  .ml_lg--130 {
    margin-left: 130px; }

  .ml_lg--135 {
    margin-left: 135px; }

  .ml_lg--140 {
    margin-left: 140px; }

  .ml_lg--145 {
    margin-left: 145px; }

  .ml_lg--150 {
    margin-left: 150px; }

  .ml_lg--155 {
    margin-left: 155px; }

  .ml_lg--160 {
    margin-left: 160px; }

  .ml_lg--165 {
    margin-left: 165px; }

  .ml_lg--170 {
    margin-left: 170px; }

  .ml_lg--175 {
    margin-left: 175px; }

  .ml_lg--180 {
    margin-left: 180px; }

  .ml_lg--185 {
    margin-left: 185px; }

  .ml_lg--190 {
    margin-left: 190px; }

  .ml_lg--195 {
    margin-left: 195px; }

  .ml_lg--200 {
    margin-left: 200px; }

  .ml_lg--205 {
    margin-left: 205px; }

  .ml_lg--210 {
    margin-left: 210px; }

  .ml_lg--215 {
    margin-left: 215px; }

  .ml_lg--220 {
    margin-left: 220px; }

  .ml_lg--225 {
    margin-left: 225px; }

  .ml_lg--230 {
    margin-left: 230px; }

  .ml_lg--235 {
    margin-left: 235px; }

  .ml_lg--240 {
    margin-left: 240px; }

  .ml_lg--245 {
    margin-left: 245px; }

  .ml_lg--250 {
    margin-left: 250px; } }
@media (min-width: 1200px) {
  .ml_xl--5 {
    margin-left: 5px; }

  .ml_xl--10 {
    margin-left: 10px; }

  .ml_xl--15 {
    margin-left: 15px; }

  .ml_xl--20 {
    margin-left: 20px; }

  .ml_xl--25 {
    margin-left: 25px; }

  .ml_xl--30 {
    margin-left: 30px; }

  .ml_xl--35 {
    margin-left: 35px; }

  .ml_xl--40 {
    margin-left: 40px; }

  .ml_xl--45 {
    margin-left: 45px; }

  .ml_xl--50 {
    margin-left: 50px; }

  .ml_xl--55 {
    margin-left: 55px; }

  .ml_xl--60 {
    margin-left: 60px; }

  .ml_xl--65 {
    margin-left: 65px; }

  .ml_xl--70 {
    margin-left: 70px; }

  .ml_xl--75 {
    margin-left: 75px; }

  .ml_xl--80 {
    margin-left: 80px; }

  .ml_xl--85 {
    margin-left: 85px; }

  .ml_xl--90 {
    margin-left: 90px; }

  .ml_xl--95 {
    margin-left: 95px; }

  .ml_xl--100 {
    margin-left: 100px; }

  .ml_xl--105 {
    margin-left: 105px; }

  .ml_xl--110 {
    margin-left: 110px; }

  .ml_xl--115 {
    margin-left: 115px; }

  .ml_xl--120 {
    margin-left: 120px; }

  .ml_xl--125 {
    margin-left: 125px; }

  .ml_xl--130 {
    margin-left: 130px; }

  .ml_xl--135 {
    margin-left: 135px; }

  .ml_xl--140 {
    margin-left: 140px; }

  .ml_xl--145 {
    margin-left: 145px; }

  .ml_xl--150 {
    margin-left: 150px; }

  .ml_xl--155 {
    margin-left: 155px; }

  .ml_xl--160 {
    margin-left: 160px; }

  .ml_xl--165 {
    margin-left: 165px; }

  .ml_xl--170 {
    margin-left: 170px; }

  .ml_xl--175 {
    margin-left: 175px; }

  .ml_xl--180 {
    margin-left: 180px; }

  .ml_xl--185 {
    margin-left: 185px; }

  .ml_xl--190 {
    margin-left: 190px; }

  .ml_xl--195 {
    margin-left: 195px; }

  .ml_xl--200 {
    margin-left: 200px; }

  .ml_xl--205 {
    margin-left: 205px; }

  .ml_xl--210 {
    margin-left: 210px; }

  .ml_xl--215 {
    margin-left: 215px; }

  .ml_xl--220 {
    margin-left: 220px; }

  .ml_xl--225 {
    margin-left: 225px; }

  .ml_xl--230 {
    margin-left: 230px; }

  .ml_xl--235 {
    margin-left: 235px; }

  .ml_xl--240 {
    margin-left: 240px; }

  .ml_xl--245 {
    margin-left: 245px; }

  .ml_xl--250 {
    margin-left: 250px; } }
.w-100 {
  width: 100%; }

.w-75 {
  width: 75%; }

.w-50 {
  width: 50%; }

.w-33 {
  width: 33.33%; }

.w-25 {
  width: 25%; }

.w-20 {
  width: 20%; }

.w-10 {
  width: 10%; }

.max-width--600 {
  max-width: 600px;
  width: 100%; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .max-width--600 {
    max-width: 100%; } }
@media only screen and (max-width: 767px) {
  .max-width--600 {
    max-width: 100%; } }
.h-100 {
  height: 100%; }

.h-75 {
  height: 75%; }

.h-50 {
  height: 50%; }

.h-25 {
  height: 25%; }

.h-33 {
  height: 33.33%; }

.h-10 {
  height: 10%; }



.bg-ash {
  background-color: #f9f9f9;
}






.ag-custom-btn {
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  color: #b74acd;
  padding: 15px;
  transition: all 0.3s ease-in-out;
}
@media (min-width: 1200px) {
  .ag-custom-btn {
    padding: 15px 35px;
  }
}
.ag-custom-btn.btn-border-radius40 {
  border-radius: 40px;
}
.ag-custom-btn.btn-border {
  border: 2px solid #b74acd;
  color: #b74acd;
}
.ag-custom-btn.btn-border:hover {
  background-color: #b74acd;
  color: #fff;
}
.ag-custom-btn.btn-gradient {
  background-image: linear-gradient(to top, #7f2799, #a538ba);
}
.ag-custom-btn.btn-gradient:hover {
  background-image: linear-gradient(to top, #a538ba, #7f2799);
}
.ag-custom-btn.btn-solid-white {
  background-color: #fff;
  color: #82299b;
}

.custom-btn-naked {
  font-size: 12px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}
.custom-btn-naked img {
  transition: all 0.3s ease-in-out;
}
.custom-btn-naked:hover {
  color: #b74acd;
}
.custom-btn-naked:hover img {
  margin-left: 6px;
}

.btn-group {
  display: block;
}
@media (min-width: 576px) {
  .btn-group {
    display: inline-flex;
  }
}

.da-custom-btn {
  padding: 12px 30px;
  font-size: 15px;
  display: inline-block;
  border: 2px solid #008dd2;
  color: #008dd2;
  position: relative;
  overflow: hidden;
}
@media (min-width: 992px) {
  .da-custom-btn {
    padding: 15px 30px;
    font-size: 16px;
  }
}
@media (min-width: 1500px) {
  .da-custom-btn {
    padding: 18px 50px;
    font-size: 16px;
  }
}
.da-custom-btn span {
  position: relative;
  z-index: 2;
}
.da-custom-btn.sm-btn {
  padding: 14px 50px;
  font-size: 14px;
  font-weight: 500;
}
.da-custom-btn.sm-btn span {
  font-weight: 500;
}
.da-custom-btn:after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #008dd2;
  transition: all 0.3s ease-in-out;
}
.da-custom-btn:hover {
  color: #fff !important;
}
.da-custom-btn:hover:after {
  color: #fff;
  left: 0;
}
.da-custom-btn.btn-border-radius40 {
  border-radius: 40px;
}
.da-custom-btn.btn-border-radius40:after {
  border-radius: 40px;
}
.da-custom-btn.btn-solid {
  background-color: #2e8efd;
  color: #fff;
}
.da-custom-btn.btn-solid:hover {
  color: #2e8efd !important;
}
.da-custom-btn.btn-solid:hover:after {
  left: 0;
  background-color: #fff;
}

.da-custom-btn2 {
  padding: 12px 30px;
  font-size: 14px;
  display: inline-block;
  border: 2px solid #ffac40;
  color: #ffac40;
  position: relative;
  overflow: hidden;
  background-color: white;
}
@media (min-width: 768px) {
  .da-custom-btn2 {
    padding: 15px 71px;
    font-size: 16px;
  }
}
.da-custom-btn2 span {
  position: relative;
  z-index: 2;
}
.da-custom-btn2.sm-btn {
  padding: 14px 50px;
  font-size: 14px;
  font-weight: 500;
}
.da-custom-btn2.sm-btn span {
  font-weight: 500;
}
.da-custom-btn2:after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: white;
  transition: all 0.3s ease-in-out;
}
.da-custom-btn2:hover {
  color: #ffac40 !important;
}
.da-custom-btn2:hover:after {
  color: #fff;
  left: 0;
}
.da-custom-btn2.btn-border-radius40 {
  border-radius: 40px;
}
.da-custom-btn2.btn-border-radius40:after {
  border-radius: 40px;
}
.da-custom-btn2.btn-solid {
  background-color: #ffac40;
  color: #fff;
}
.da-custom-btn2.btn-solid:hover:after {
  color: #ffac40;
  left: 0;
}

.app-custom-btn {
  padding: 12px 30px;
  font-size: 15px;
  display: inline-block;
  border: 1px solid #5ec0e7;
  color: #5ec0e7;
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .app-custom-btn {
    padding: 18px 71px;
    font-size: 16px;
  }
}
.app-custom-btn span {
  position: relative;
  z-index: 2;
}
.app-custom-btn.sm-btn {
  padding: 14px 30px;
  font-size: 14px;
  font-weight: 500;
}
.app-custom-btn.sm-btn span {
  font-weight: 500;
}
.app-custom-btn:after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #5ec0e7;
  transition: all 0.3s ease-in-out;
}
.app-custom-btn:hover {
  color: #fff;
}
.app-custom-btn:hover:after {
  color: #fff;
  left: 0;
}
.app-custom-btn.btn-border-radius40 {
  border-radius: 40px;
}
.app-custom-btn.btn-border-radius40:after {
  border-radius: 40px;
}
.app-custom-btn.btn-border-radius5 {
  border-radius: 5px;
}
.app-custom-btn.btn-border-radius5:after {
  border-radius: 5px;
}
.app-custom-btn.btn-solid {
  background-color: #5ec0e7;
  color: #fff;
}
.app-custom-btn.btn-solid:hover {
  color: #5ec0e7 !important;
}
.app-custom-btn.btn-solid:hover:after {
  left: 0;
  background-color: #fff;
}

.app-custom-btn2 {
  padding: 12px 30px;
  font-size: 14px;
  display: inline-block;
  border: 1px solid #ffac40;
  color: #ffac40;
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .app-custom-btn2 {
    padding: 18px 71px;
    font-size: 16px;
  }
}
.app-custom-btn2 span {
  position: relative;
  z-index: 2;
}
.app-custom-btn2.sm-btn {
  padding: 14px 50px;
  font-size: 14px;
  font-weight: 500;
}
.app-custom-btn2.sm-btn span {
  font-weight: 500;
}
.app-custom-btn2:after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #ffac40;
  transition: all 0.3s ease-in-out;
}
.app-custom-btn2:hover {
  color: #fff;
}
.app-custom-btn2:hover:after {
  color: #fff;
  left: 0;
}
.app-custom-btn2.btn-border-radius40 {
  border-radius: 40px;
}
.app-custom-btn2.btn-border-radius40:after {
  border-radius: 40px;
}
.app-custom-btn2.btn-solid {
  background-color: #ffac40;
  color: #fff;
}
.app-custom-btn2.btn-solid:hover:after {
  color: #ffac40;
  left: 0;
}

.Okala-button {
  font-size: 14px;
  color: #fff;
  background-color: #fd625e;
  padding: 12px 28px;
  display: inline-block;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}
.Okala-button:hover {
  color: #fff;
}
.Okala-button.xs-button {
  padding: 5px 20px;
}
.Okala-button.sm-button {
  padding: 7px 25px;
}
.Okala-button.md-button {
  padding: 12px 28px;
}
.Okala-button.lg-button {
  padding: 16px 35px;
  font-size: 16px;
}
.Okala-button.xl-button {
  padding: 20px 60px;
  font-size: 20px;
}
.Okala-button.btn-radius3 {
  border-radius: 3px;
}
.Okala-button.btn-radius5 {
  border-radius: 5px;
}
.Okala-button.btn-radius10 {
  border-radius: 10px;
}
.Okala-button.btn-radius20 {
  border-radius: 20px;
}
.Okala-button.btn-radius30 {
  border-radius: 30px;
}
.Okala-button.btn-radius40 {
  border-radius: 40px;
}
.Okala-button.btn-radius50 {
  border-radius: 50px;
}
.Okala-button.btn-radius60 {
  border-radius: 60px;
}
.Okala-button.btn-radius70 {
  border-radius: 70px;
}
.Okala-button.btn-radius80 {
  border-radius: 80px;
}
.Okala-button.red-bg-btn {
  background-color: #fd625e;
  color: #fff;
}
.Okala-button.red-bg-btn:hover {
  background-color: rgba(253, 98, 94, 0.75);
}
.Okala-button.black-bg-btn {
  background-color: #434343;
  color: #fff;
}
.Okala-button.black-bg-btn:hover {
  background-color: rgba(67, 67, 67, 0.75);
}
.Okala-button.cyan-bg-btn {
  background-color: #53b8de;
  color: #fff;
}
.Okala-button.cyan-bg-btn:hover {
  background-color: rgba(83, 184, 222, 0.75);
}
.Okala-button.blue-bg-btn {
  background-color: #2e8efd;
  color: #fff;
}
.Okala-button.blue-bg-btn:hover {
  background-color: rgba(46, 142, 253, 0.75);
}
.Okala-button.green-bg-btn {
  background-color: #48cc2e;
  color: #fff;
}
.Okala-button.green-bg-btn:hover {
  background-color: rgba(72, 204, 46, 0.75);
}
.Okala-button.stroke-button {
  background-color: transparent;
  border: 1px solid #434343;
  color: #434343;
}
.Okala-button.stroke-button:hover {
  background-color: #434343;
  color: #fff;
}
.Okala-button.red-stroke {
  background-color: transparent;
  border: 1px solid #fd625e;
  color: #fd625e;
}
.Okala-button.red-stroke:hover {
  background-color: #fd625e;
  color: #fff;
}
.Okala-button.cyan-stroke {
  background-color: transparent;
  border: 1px solid #53b8de;
  color: #53b8de;
}
.Okala-button.cyan-stroke:hover {
  background-color: #53b8de;
  color: #fff;
}
.Okala-button.blue-stroke {
  background-color: transparent;
  border: 1px solid #2e8efd;
  color: #2e8efd;
}
.Okala-button.blue-stroke:hover {
  background-color: #2e8efd;
  color: #fff;
}
.Okala-button.green-stroke {
  background-color: transparent;
  border: 1px solid #48cc2e;
  color: #48cc2e;
}
.Okala-button.green-stroke:hover {
  background-color: #48cc2e;
  color: #fff;
}
.Okala-button.gradient-button {
  background-image: linear-gradient(to top, #086e97, #5ec0e7);
  background-color: transparent;
}
.Okala-button.gradient-button:hover {
  background-image: linear-gradient(to top, #5ec0e7, #086e97);
}


.btn-transparent:hover {
  color: #008dd2;
}






/*  dark version */

.dark h1, .dark h2, .dark h3, .dark h4, .dark h5, .dark h6, .dark h1 > a, .dark h2 > a, .dark h3 > a, .dark h4 > a, .dark h5 > a, .dark h6 > a,
.dark body, .dark ul li {
  color: #fff;
}
.dark body, .dark section {
  background-color: #181818;
}
.dark p {
  color: #bababa;
}
.dark .aboutus.style1 .col-lg-6:first-child {
  background-color: #181818;
}
.dark .custom-angle-left.black, .dark .custom-angle-right.black {
  border-color: #bababa;
}
.dark .button-control-group .about-slide-next-btn {
  border-color: #bababa;
}
.dark .button-control-group .about-slide-prev-btn {
  border-color: #bababa;
}
.dark .button-control-group .about-slide-prev-btn:active,
.dark .button-control-group .about-slide-prev-btn:focus,
.dark .button-control-group .about-slide-prev-btn:hover {
  border-color: #ff5c5c;
  color: #ff5c5c;
}
.dark .button-control-group .about-slide-prev-btn:active .custom-angle-left.black,
.dark .button-control-group .about-slide-prev-btn:active .custom-angle-right.black,
.dark .button-control-group .about-slide-prev-btn:focus .custom-angle-left.black,
.dark .button-control-group .about-slide-prev-btn:focus .custom-angle-right.black,
.dark .button-control-group .about-slide-prev-btn:hover .custom-angle-left.black,
.dark .button-control-group .about-slide-prev-btn:hover .custom-angle-right.black {
  border-color: #ff5c5c;
}
.dark .button-control-group .about-slide-next-btn:active,
.dark .button-control-group .about-slide-next-btn:focus,
.dark .button-control-group .about-slide-next-btn:hover {
  border-color: #ff5c5c;
  color: #ff5c5c;
}
.dark .button-control-group .about-slide-next-btn:active .custom-angle-left.black,
.dark .button-control-group .about-slide-next-btn:active .custom-angle-right.black,
.dark .button-control-group .about-slide-next-btn:focus .custom-angle-left.black,
.dark .button-control-group .about-slide-next-btn:focus .custom-angle-right.black,
.dark .button-control-group .about-slide-next-btn:hover .custom-angle-left.black,
.dark .button-control-group .about-slide-next-btn:hover .custom-angle-right.black {
  border-color: #ff5c5c;
}
.dark .btn-transparent {
  color: #ff5c5c;
  font-weight: 600;
}
.dark .btn-transparent:hover {
  color: #fff;
}
.dark .borderbottom {
  border-bottom: 1px solid #000 !important;
}
.dark .bz-about-slider .about-image img {
  border-radius: 10px;
}
.dark .working-process-section .bz-working-process:before {
  background-color: #181818;
}
.dark .working-process-section .bz-working-process:after {
  background-color: rgba(0, 0, 0, 0.05);
}
.dark .bz-process-item .process-item-inner .frontface,
.dark .bz-process-item .process-item-inner .backface {
  background-color: #292929;
}
.dark .bz-working-process-container .swiper-pagination-progressbar {
  background-color: rgba(219, 219, 219, 0.4);
}
.dark .bz-working-process-container .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #dbdbdb;
}
.dark .custom-btn1 {
  color: #bababa;
  border: 1px solid #bababa;
}
.dark .bz-service-area .service-tab .nav-item .text {
  color: #bababa;
}
.dark .bz-project-section .button-group li {
  color: #dbdbdb;
}
.dark .bz-pricing-item {
  background-color: #252525;
  border-color: #252525;
}
.dark .bz-pricing-item .pricing-head .name,
.dark .bz-pricing-item .pricing-head .per-time {
  color: #bababa;
}
.dark .bz-testimonial-container .testimonial-thumbs .thumb-content .name {
  color: #bababa;
}
.dark .post-item.flip-style .post-content-inner .frontface {
  background-color: #292929;
}
.dark .bz-project-section .project-item .project-thumb .project-content .project-content-inner .title {
  color: #2e3c40;
}
.dark .bz-action-section {
  background-color: #008dd2;
}

.dark .footertop {
  background-color: #181818;
}
.dark .footerbottom {
  background-color: #000;
}
.dark .footer.footer1 .footer-widget .footer-menu li a, .dark .footer.footer1 .footer-widget .linklist li a {
  color: #bababa;
}
.dark .footer.footer1 .footerbottom .copy-right, .dark .footer.footer1 .footerbottom .copy-right a {
  color: #bababa;
}
.dark .footer.footer1 .footerbottom .social-media-list li a {
  color: #bababa;
}
.dark .footer.footer1 .footer-widget .googlemap {
  color: #bababa;
}
.dark .footer.footer1 .footertop .container {
  border-bottom: none;
}
.dark .bz-process-item .process-item-inner .front {
  background-color: #292929;
}
.dark .bz-process-item .process-item-inner .front .name {
  color: #fff;
}
.dark .bz-process-item .process-item-inner .front .process-count {
  color: #008dd2;
}
.dark .bz-process-item .process-item-inner .back .desc {
  line-height: 1.7;
}
.dark .bz-testimonial-container .testimonial-massage .testimonial-control-btn-group .testimonial-swiper-button-next:active, .dark .bz-testimonial-container .testimonial-massage .testimonial-control-btn-group .testimonial-swiper-button-next:focus, .dark .bz-testimonial-container .testimonial-massage .testimonial-control-btn-group .testimonial-swiper-button-next:hover, .dark .bz-testimonial-container .testimonial-massage .testimonial-control-btn-group .testimonial-swiper-button-prev:active, .dark .bz-testimonial-container .testimonial-massage .testimonial-control-btn-group .testimonial-swiper-button-prev:focus, .dark .bz-testimonial-container .testimonial-massage .testimonial-control-btn-group .testimonial-swiper-button-prev:hover {
  color: #008dd2;
  border-color: #008dd2;
}
.dark .footer-site-info li a {
  color: #bababa;
}
.dark .bz-about-slide-container .swiper-button-prev:after, 
.dark .bz-about-slide-container .swiper-button-next:after {
  color: #ccc;
}
.dark .bz-about-slider .swiper-pagination-fraction .swiper-pagination-current {
  color: #008dd2;
}
.dark .bz-working-process-container .swiper-button-prev:after, 
.dark .bz-working-process-container .swiper-button-next:after {
  color: #ccc;
}
.dark .bz-service-area .react-tabs__tab-list li .text {
  color: #ccc;
}
.dark .project-container .react-tabs__tab-list {
  text-align: center;
}
.dark .project-container .react-tabs__tab-list .react-tabs__tab {
  color: #ccc;
}
.dark .footer.footer1 .footer-widget form input {
  border-radius: 30px;
}



.backto-top>div {
    z-index: 999;
    width: 50px;
    height: 50px;
    line-height: 49px;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
    overflow: hidden;
    z-index: 999!important;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2);
}

.backto-top svg {
  font-size: 14px!important;
  fill: #008dd2;
  width: 20px;
}

.backto-top button {
  outline: none;
}


.dark ~ .search-body {
  background-color: black !important;
}
.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      -webkit-animation: App-logo-spin infinite 20s linear;
              animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: red;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @-webkit-keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  




.header-top {
    padding-left: 110px;
    padding-right: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #76777d;
  }

  .site-info {
    -webkit-display: flex;
    display: flex;
}

.site-info {
    -webkit-display: flex;
    display: flex;
  }
  .site-info li {
    padding: 10px 0px;
    margin-right: 45px;
    color: #898989;
  }
  .site-info li a {
    color: inherit;
    font-size: 14px;
  }
  .site-info li a:hover {
    color: #008dd2;
  }
  .site-info li:last-child {
    margin-right: 0;
  }
  .site-info li i {
    margin-right: 7px;
    color: #898989;
  }
  
  .signin-option {
    margin-right: 25px;
  }
  .signin-option a {
    color: #898989;
    font-size: 14px;
  }
  .signin-option a:hover {
    color: #008dd2;
  }
  .signin-option span {
    color: #555555;
    margin: 0 6px;
  }
  
  .language-option {
    margin-right: 20px;
    position: relative;
  }
  .language-option a {
    color: #898989;
    outline: none;
    border: none;
    background: transparent;
    font-size: 14px;
  }
  .language-option:hover>a {
    color: #008dd2;
  }

  .language-container {
    position: absolute;
    left: 0;
    top: 150%;
    min-width: 120px;
    background-color: white;
    box-shadow: 0 0 3px 0 rgba(black, 0.1);
    z-index: 2;
    border-radius: 3px;
    padding: 10px 0;
    text-align: left;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
  .language-container a {
    display: block;
    padding: 7px 15px;
    transition: all 0.3s ease;
    font-size: 14px;
  }
  .language-container a:hover {
    color: #008dd2;
    background-color: #f8f8f8;
    padding-left: 20px;
  }
  .language-option:hover .language-container {
    opacity: 1;
    visibility: visible;
    top: 140%;
  }

  
  .search-option {
    padding: 0 10px;
    color: #898989;
    margin-right: 10px;
    cursor: pointer;
    font-size: 14px;
  }

  .social-media-list li a {
    padding: 10px;
    color: #898989;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
  }
  .social-media-list li a:hover {
    color: #008dd2;
  }
  .social-media-list li:last-child a {
    padding-right: 0px;
  }




.header.transparent-header {
    background-color: black;
  }
  @media (min-width: 991px) {
    .header.transparent-header {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      z-index: 9;
      background-color:transparent;
    }
  }
  .header.style2 {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9;
    background-color:transparent;
  }
  .header.sticky .header-top {
    display: none !important;
  }
  .header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.75);
  }
  .header.transparent-header .main-menu-area {
    position: relative;
    transition: all 0.3s ease-in-out;
  }
  .header.transparent-header .main-menu-area .logo {
    margin-left: 10px;
    padding: 10px 0;
  }
  .header.transparent-header .main-menu-area .logo .navbar-brand {
    display: none;
  }
  .header.transparent-header .main-menu-area .logo .navbar-brand2 {
    display: block;
  }
  @media (min-width: 576px) {
    .header.transparent-header .main-menu-area .logo {
      margin-left: 30px !important;
    }
    .header.transparent-header .main-menu-area .logo img {
      width: unset;
    }
  }
  @media (min-width: 992px) {
    .header.transparent-header .main-menu-area .logo {
      margin-left: 0px !important;
      padding: 0;
    }
  }
  @media (min-width: 992px) {
    .header.transparent-header .main-menu-area {
      padding: 0 90px 0 90px;
    }
  }
  .header.transparent-header .main-menu-area.fixed-menu {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 99;
    background-color: #fff;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.04);
  }
  .header.transparent-header .main-menu-area.fixed-menu .logo .navbar-brand {
    display: block;
  }
  .header.transparent-header .main-menu-area.fixed-menu .logo .navbar-brand2 {
    display: none;
  }
  .header.transparent-header .main-menu-area.fixed-menu .menu-bar span {
    background-color: #fff;
  }
  .header.transparent-header .main-menu-area.fixed-menu .nav-menu li a {
    color: #2e3c40;
  }
  .header.transparent-header .main-menu-area.fixed-menu .nav-menu li .submenu li a:hover {
    color: #008dd2  !important;
  }
  .header.transparent-header .main-menu-area.fixed-menu .nav-menu li.active > a, .header.transparent-header .main-menu-area.fixed-menu .nav-menu li:hover > a {
    color: #008dd2  !important;
  }
  .header.transparent-header .main-menu-area.fixed-menu .menu-action li a {
    color: #2e3c40;
  }
  .header.transparent-header .main-menu-area.fixed-menu .menu-action li a:hover {
    color: #008dd2;
  }
  .header.transparent-header .main-menu-area.fixed-menu .mobile-menu-area {
    height: 750px;
  }
  .header.transparent-header .nav-menu li a {
    color: #9a9a9a;
  }
  .header.transparent-header .nav-menu li > a.active, .header.transparent-header .nav-menu li:hover > a {
    color: #fff;
  }
  .header.transparent-header .menu-action li a {
    transition: all 0.3s ease-in-out;
  }
  .header.transparent-header .menu-action li a:hover {
    color: #b74acd;
  }
  .header.transparent-header .menu-action li a.join:hover {
    color: #fff !important;
  }
  
  .open-mobile-menu .nav-menu {
    transform: scaleY(1) !important;
  }
  
  .header.style1 .main-menu-area {
    position: relative;
  }
  @media (min-width: 992px) {
    .header.style1 .main-menu-area {
      padding-left: 60px;
      padding-right: 60px;
    }
    .header.transparent-header .main-menu-area .logo {
      padding: 10px 0;
    }
  }
  @media (min-width: 1500px) {
    .header.style1 .main-menu-area {
      padding-left: 110px;
      padding-right: 110px;
    }
  }
  .header.style1 .main-menu-area .menu-bar {
    background-color: #008dd2;
  }
  .header.style1 .nav-menu li a {
    color: #9a9a9a;
  }
  .header.style1 .nav-menu li.active > a, .header.style1 .nav-menu li:hover > a {
    color: #fff;
  }
  .header.style1 .nav-menu li .submenu li a:hover, .header.style1 .nav-menu li .submenu li a.active-submenu {
    color: #008dd2;
  }
  .header.style1 .menu-action li a.discover {
    color: #fff;
  }
  .header.style1 .menu-action li a.join {
    color: #fff;
  }
  .header.style1 .fixed-menu .menu-action li a.da-custom-btn {
    color: #008dd2;
    border-color: #008dd2;
  }
  .header.style1 .fixed-menu .menu-action li a.da-custom-btn:hover {
    color: white;
  }



  .nav-menu li > a {
    padding: 30px 10px 30px 10px;
    font-size: 14px;
    display: block;
  }
  @media (min-width: 1300px) {
    .nav-menu li > a {
      padding: 30px 15px 30px 15px;
      font-size: 16px;
    }
  }
  @media (min-width: 1500px) {
    .nav-menu li > a {
      padding: 30px 25px 30px 25px;
    }
  }
  .nav-menu li > a i {
    margin-left: 5px;
    font-size: 90%;
    opacity: 0.7;
  }
  .nav-menu li.dropdown {
    position: relative;
  }
  .nav-menu li.dropdown .submenu {
    min-width: 100%;
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 150%;
    padding: 7px 0;
    text-align: center;
    list-style-type: none;
    opacity: 0;
    visibility: hidden;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.08);
    z-index: 2;
    transform-origin: left top;
  }
  @media (min-width: 992px) {
    .nav-menu li.dropdown .submenu {
      min-width: 230px;
      text-align: left;
    }
  }
  .nav-menu li.dropdown .submenu .submenu {
    left: 100%;
  }
  .nav-menu li.dropdown .submenu li a {
    padding: 10px 25px;
    display: block;
    font-size: 95%;
    transition: all 0.3s ease-in-out;
    position: relative;
  }
  .nav-menu li.dropdown .submenu li a:hover {
    color: #008dd2;
    padding-left: 35px;
    background-color: #f9f9f9;
  }
  .nav-menu li.dropdown .submenu li a i {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -8px;
    opacity: 0.6;
  }
  .nav-menu li.dropdown:hover > .submenu {
    transition: all 0.3s ease-in-out;
    top: 100%;
    opacity: 1;
    visibility: visible;
  }
  .nav-menu li.dropdown .submenu > li.dropdown:hover > a {
    color: #008dd2;
    padding-left: 35px;
  }
  .nav-menu li.dropdown .submenu > li.dropdown:hover .submenu {
    top: 0;
  }
  .nav-menu li.dropdown.active > .submenu .active > a {
    color: #008dd2;
    padding-left: 35px;
  }
  .nav-menu li.dropdown .submenu .active > a {
    color: #008dd2 !important;
  }




  .menu-action li {
    margin: 0 6px;
  }
  @media (min-width: 1300px) {
    .menu-action li {
      margin: 0 8px;
    }
  }
  .menu-action li:last-child {
    margin-right: 0;
  }
  .menu-action li:first-child {
    margin-left: 0;
  }
  .menu-action li a.da-custom-btn {
    border: 2px solid white;
    color: white !important;
    padding: 12px 30px;
    font-size: 15px;
  }
  .menu-action li a.da-custom-btn:hover {
    border-color: #008dd2;
    background-color: #008dd2;
  }
  .menu-action li a.da-custom-btn:hover:after {
    background-color: #008dd2;
  }
  
  /* .header.style1 .menu-action li a.da-custom-btn:hover:after {
    background-color: white;
  } */
  .header.style1 .menu-action li {
    margin: 0 6px;
  }
  @media (min-width: 1300px) {
    .header.style1 .menu-action li {
      margin: 0 8px;
    }
  }
  .header.style1 .menu-action li:last-child {
    margin-right: 0;
  }
  .header.style1 .menu-action li:first-child {
    margin-left: 0;
  }





  
.menu-bar {
  width: 40px;
  height: 40px;
  background-color: #008dd2;
  padding: 10px;
  position: relative;
  right: 20px;
  cursor: pointer;
  border-radius: 50%;
}
.menu-bar span {
  width: 20px;
  height: 2px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -1px;
  background-color: #fff;
}
.menu-bar span:nth-child(1) {
  margin-top: -7px;
}
.menu-bar span:nth-child(3) {
  margin-top: 5px;
}

.open-mobile-menu .menu-bar span {
  transform-origin: center;
  transition: all 0.3s ease-in-out;
}
.open-mobile-menu .menu-bar span:nth-child(1) {
  transform: rotate(45deg);
  margin-top: 0;
}
.open-mobile-menu .menu-bar span:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: 0;
}
.open-mobile-menu .menu-bar span:nth-child(2) {
  opacity: 0;
}

.close-button {
  width: 40px;
  height: 40px;
  background-color: transparent;
  padding: 10px;
  position: relative;
  border-radius: 50%;
  background-color: #5ec0e7;
  margin-top: 10px;
  right: 10px;
}
.close-button span {
  width: 20px;
  height: 2px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -1px;
  background-color: #fff;
  transform-origin: center;
  transition: all 0.3s ease-in-out;
}
.close-button span:nth-child(1) {
  transform: rotate(45deg);
  margin-top: 0;
}
.close-button span:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: 0;
}
.close-button span:nth-child(2) {
  opacity: 0;
}





.mobile-menu-area {
  max-width: 400px;
  width: 50%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  height: 100%;
  z-index: 999;
  transform: translate3d(-100%, 0, 0) scaleX(0.5);
  transform-origin: left;
  transition: all 0.3s ease-in-out;
}
.mobile-menu-area .m-menu-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
}
.mobile-menu-area .m-menu-header .close-bar {
  position: relative;
  width: 35px;
  height: 35px;
  margin-right: 25px;
  cursor: pointer;
  background-color: #008dd2;
  border-radius: 50%;
  transform: translateX(-30px);
  opacity: 0;
  transition: 0.5s ease;
}
.mobile-menu-area .m-menu-header .close-bar span {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -1px;
  margin-left: 7px;
  width: 60%;
  height: 2.5px;
  background-color: white;
  transform-origin: center;
}
.mobile-menu-area .m-menu-header .close-bar span:first-child {
  transform: rotate(45deg);
}
.mobile-menu-area .m-menu-header .close-bar span:last-child {
  transform: rotate(-45deg);
}
.mobile-menu-area .mobile-menu {
  height: calc(100% - 60px );
  overflow-y: auto;
}
.mobile-menu-area .mobile-menu .m-menu {
  margin: 0;
  padding-bottom: 30px;
  padding-left: 0;
  list-style: none;
}
.mobile-menu-area .mobile-menu .m-menu li a {
  display: block;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 30px;
  position: relative;
  border-bottom: 1px solid #fafafa;
  text-transform: uppercase;
  text-align: left;
  color: #677294;
}
/* .mobile-menu-area .mobile-menu .m-menu > li > a.active {
  color: #008dd2;
} */
.mobile-menu-area .mobile-menu .m-menu li a .arrow {
  width: 30px;
  height: 30px;
  background-color: rgba(46, 60, 64, 0.01);
  text-align: center;
  line-height: 30px;
  color: #2e3c40;
  border-radius: 3px;
  position: absolute;
  right: 25px;
  top: 50%;
  margin-top: -15px;
  font-weight: 200;
}
.mobile-menu-area .mobile-menu .m-menu li a .arrow svg {
  transition: all 0.3s ease-in-out;
}
.mobile-menu-area .mobile-menu .m-menu li .mobile-submenu {
  display: none;
  list-style: none;
  margin: 0;
  padding-left: 20px;
}
.mobile-menu-area .mobile-menu .m-menu li .mobile-submenu.actives {
  display: block;
}
.mobile-menu-area .mobile-menu .m-menu li .mobile-submenu li a {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  font-family: "Rubik", sans-serif;
  color: #677294;
}
.mobile-menu-area .mobile-menu .m-menu li > a.open .arrow svg {
  transform: rotate(90deg);
}
.mobile-menu-area .mobile-menu .m-menu li.open > .mobile-submenu {
  display: block;
}
.mobile-menu-area .mobile-menu .m-menu li.m-active > a {
  color: #008dd2;
}
.mobile-menu-area .mobile-menu .m-menu li.m-active > a .arrow {
  color: #008dd2;
}
.mobile-menu-area .mobile-menu .m-menu > li {
  transform: translateX(-30px);
  transition: 0.7s ease;
  opacity: 0;
}

.open-mobile-menu {
  position: relative;
  overflow: hidden;
}
.open-mobile-menu .menu-bar {
  opacity: 0.1;
}
.open-mobile-menu .close-bar {
  transform: translateX(0px) !important;
  opacity: 1 !important;
  transition-delay: 0.2s !important;
}
.open-mobile-menu .mobile-menu-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(46, 60, 64, 0.9);
  z-index: 99;
  overflow: hidden;
}
.open-mobile-menu .mobile-menu-area {
  transform: translate3d(0%, 0, 0) scaleX(1);
  width: 70%;
  -webkit-animation: scale-easeOutElastic;
          animation: scale-easeOutElastic;
}
.open-mobile-menu .mobile-menu-area .m-menu > li {
  transform: translateX(0px);
  transition-delay: 0s;
  opacity: 1;
}
.open-mobile-menu .mobile-menu-area .m-menu > li:nth-child(1) {
  transition-delay: 0.2s;
}
.open-mobile-menu .mobile-menu-area .m-menu > li:nth-child(2) {
  transition-delay: 0.3s;
}
.open-mobile-menu .mobile-menu-area .m-menu > li:nth-child(3) {
  transition-delay: 0.4s;
}
.open-mobile-menu .mobile-menu-area .m-menu > li:nth-child(4) {
  transition-delay: 0.5s;
}
.open-mobile-menu .mobile-menu-area .m-menu > li:nth-child(5) {
  transition-delay: 0.6s;
}
.open-mobile-menu .mobile-menu-area .m-menu > li:nth-child(6) {
  transition-delay: 0.7s;
}
.open-mobile-menu .mobile-menu-area .m-menu > li:nth-child(7) {
  transition-delay: 0.8s;
}
.open-mobile-menu .mobile-menu-area .m-menu > li:nth-child(8) {
  transition-delay: 0.9s;
}
.open-mobile-menu .mobile-menu-area .m-menu > li:nth-child(9) {
  transition-delay: 1s;
}

@-webkit-keyframes scale-easeOutElastic {
  0% {
    transform: scale(1);
  }
  16% {
    transform: scale(-0.32);
  }
  28% {
    transform: scale(0.13);
  }
  44% {
    transform: scale(-0.05);
  }
  59% {
    transform: scale(0.02);
  }
  73% {
    transform: scale(-0.01);
  }
  88% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scale-easeOutElastic {
  0% {
    transform: scale(1);
  }
  16% {
    transform: scale(-0.32);
  }
  28% {
    transform: scale(0.13);
  }
  44% {
    transform: scale(-0.05);
  }
  59% {
    transform: scale(0.02);
  }
  73% {
    transform: scale(-0.01);
  }
  88% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}
.slider-area {
    position: relative;
  }
  
  .bz-banner-slider-container {
    overflow: hidden;
  }
  .bz-banner-slider-container .banner-slide-next-btn, .bz-banner-slider-container .banner-slide-prev-btn {
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background-color: rgba(255, 99, 96, 0.6);
    position: absolute;
    top: 50%;
    margin-top: -25px;
    z-index: 2;
    outline: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: none;
  }
  @media (min-width: 576px) {
    .bz-banner-slider-container .banner-slide-next-btn, .bz-banner-slider-container .banner-slide-prev-btn {
      display: block;
    }
  }
  @media (min-width: 768px) {
    .bz-banner-slider-container .banner-slide-next-btn, .bz-banner-slider-container .banner-slide-prev-btn {
      width: 50px;
      height: 50px;
      line-height: 50px;
    }
  }
  .bz-banner-slider-container .banner-slide-next-btn.swiper-button-disabled, .bz-banner-slider-container .banner-slide-prev-btn.swiper-button-disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
  .bz-banner-slider-container .banner-slide-next-btn:hover, .bz-banner-slider-container .banner-slide-prev-btn:hover {
    background-color: #008dd2;
  }
  .bz-banner-slider-container .banner-slide-prev-btn {
    right: 0;
  }
  .bz-banner-slider-container .banner-slide-next-btn {
    left: 0;
  }
  .banner-slider .carousel-item {
    overflow: hidden;
    position: relative;
    z-index: 2;
  }
  @media (min-width: 991) {
    .banner-slider .carousel-item {
      height: 100vh;
    }
  }
  .banner-slider .carousel-item:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 2;
  }
  .banner-slider .carousel-item:nth-child(1) slider-content {
    text-align: center;
  }
  @media (min-width: 992px) {
    .banner-slider .carousel-item:nth-child(1) slider-content {
      text-align: left;
    }
  }
  .banner-slider .carousel-item:nth-child(2) slider-content {
    text-align: center;
  }
  @media (min-width: 992px) {
    .banner-slider .carousel-item:nth-child(2) slider-content {
      text-align: left;
    }
  }
  .banner-slider .carousel-item:nth-child(3) slider-content {
    text-align: center;
  }
  @media (min-width: 992px) {
    .banner-slider .carousel-item:nth-child(3) slider-content {
      text-align: left;
    }
  }
  .banner-slider .carousel-item .carousel-caption {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-38%);
    width: 100%;
    z-index: 3;
  }
  @media (min-width: 992px) {
    .banner-slider .carousel-item .carousel-caption {
      transform: translateY(-50%);
    }
  }
  .banner-slider .carousel-item .carousel-caption {
    padding: 0 20px;
    text-align: center;
  }
  @media (min-width: 992px) {
    .banner-slider .carousel-item .carousel-caption {
      text-align: left;
    }
  }
  @media (width: 1024px) {
    .banner-slider .carousel-item .carousel-caption {
      text-align: center;
    }
  }
  @media (min-width: 768px) {
    .banner-slider .carousel-item .carousel-caption {
      padding: 0;
    }
  }
  .banner-slider .carousel-item .carousel-caption .large-text {
    transition: all 0.6s ease-in-out;
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: 600;
    line-height: 1.25;
    color: #fff;
    margin-bottom: 30px;
    opacity: 0;
    transform: translateY(40px);
  }
  @media (min-width:768px) {
    .banner-slider .carousel-item .carousel-caption .large-text {
      font-size: 30px;
    }
  }
  @media (min-width: 992px) {
    .banner-slider .carousel-item .carousel-caption .large-text {
      font-size: 36px;
    }
  }
  @media (min-width: 1300px) {
    .banner-slider .carousel-item .carousel-caption .large-text {
      font-size: 42px;
    }
  }
  @media (min-width: 1500px) {
    .banner-slider .carousel-item .carousel-caption .large-text {
      font-size: 48px;
    }
  }
  .banner-slider .carousel-item .carousel-caption .small-text {
    transition: all 0.6s ease-in-out;
    display: block;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.7;
    margin-bottom: 40px;
    color: #fff;
    opacity: 0;
    transform: translateY(40px);
    display: none;
  }
  @media (min-width: 992px) {
    .banner-slider .carousel-item .carousel-caption .small-text {
      font-size: 18px;
      display: block;
    }
  }
  .banner-slider .carousel-item .carousel-caption .video-button-area {
    opacity: 0;
    transition: all 0.8s ease-in-out;
    transform: translateY(60px);
    display: none;
  }
  @media (min-width: 768px) {
    .banner-slider .carousel-item .carousel-caption .video-button-area {
      display: block;
    }
  }
  .banner-slider .carousel-item .carousel-caption .video-button-area span {
    color: #fff;
  }
  .banner-slider .carousel-item .carousel-caption .video-button-area .video-button:hover {
    color: inherit;
  }
  .banner-slider .carousel-item .carousel-caption .custom-btn {
    padding: 15px 45px;
    background-color: #fff;
    color: #333;
    border-radius: 30px;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    display: none;
  }
  @media (min-width: 768px) {
    .banner-slider .carousel-item .carousel-caption .custom-btn {
      display: inline-block;
    }
  }
  .banner-slider .carousel-item .carousel-caption .video-button:hover,
  .banner-slider .carousel-item .carousel-caption .custom-btn:hover {
    background-color: #008dd2;
    color: #fff;
  }
  .banner-slider .carousel-item .carousel-caption .button-area {
    opacity: 0;
    transition: all 0.8s ease-in-out;
    transform: translateY(60px);
  }
  .banner-slider .carousel-item img{
    transition: all 15s ease;
  }
  .banner-slider .carousel-item.active img {
    transform: scale(1.5);
  }
  .banner-slider .carousel-item.active .large-text {
    opacity: 1;
    transition-delay: 0.6s;
    transform: translateX(0px);
  }
  .banner-slider .carousel-item.active .small-text {
    opacity: 1;
    transition-delay: 0.9s;
    transform: translateX(0px);
  }
  .banner-slider .carousel-item.active .video-button-area {
    padding-top: 30px;
    opacity: 1;
    transition-delay: 1.2s;
    transform: translateX(0px);
  }
  .banner-slider .carousel-item.active .button-area {
    opacity: 1;
    transition-delay: 1.1s;
    transform: translateX(0px);
  }



  .banner-slider .carousel-item .carousel-caption {
    position: absolute;
    left: 0;
    bottom: auto;
    width: 100%;
    transform: translateY(-50%);
    top: 50%;
  }


  
.video-button {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  text-align: center;
  line-height: 82px;
  color: #008dd2;
  background-color: #fff;
  display: inline-block;
  font-size: 24px;
  margin-right: 35px;
  position: relative;
  border: none;
  outline: none;
}
@media (min-width: 1500px) {
  .video-button {
    width: 100px;
    height: 100px;
    line-height: 95px;
  }
  .video-button svg {
    font-size: 30px;
    margin-left: 5px;
  }
}

.effect-dhew {
  overflow: visible;
}
.effect-dhew .anim1 {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #008dd2;
  z-index: -1;
  border-radius: 100%;
  -webkit-animation: dhew 2s infinite;
          animation: dhew 2s infinite;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
.effect-dhew .anim2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #008dd2;
  z-index: -1;
  border-radius: 100%;
  -webkit-animation: dhew 3s infinite;
          animation: dhew 3s infinite;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
.effect-dhew .anim3 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #008dd2;
  z-index: -1;
  border-radius: 100%;
  -webkit-animation: dhew 3s infinite;
          animation: dhew 3s infinite;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.effect-dhew .anim4 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #008dd2;
  z-index: -1;
  border-radius: 100%;
  -webkit-animation: dhew 5s infinite;
          animation: dhew 5s infinite;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

@-webkit-keyframes dhew {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes dhew {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}




/* slider controller  */
.banner-slider .carousel-control-next, .banner-slider .carousel-control-prev {
  width: 50px;
  height: 50px;
  bottom: auto;
  top: 50%;
  margin-top: -25px;
  z-index: 2;
  background-color: #008dd2;
  border-radius: 3px;
  display: none;
}
.banner-slider .carousel-control-next {
  right: 10px;
}
.banner-slider .carousel-control-prev {
  left: 10px;
}



@media (min-width: 991px) {
  .banner-slider .carousel-control-next, .banner-slider .carousel-control-prev {
    display: flex;
  }
  .banner-slider .carousel-indicators {
    display: none;
  }
}
.client-logo-container {
    overflow: hidden;
    padding: 40px 50px 30px 50px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
    border-radius: 20px;
  }
.client-logo-container .client-logo {
    text-align: center;
}
.client-logo-container .client-logo img {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    opacity: 0.3;
}

/* .client-logo-section.style2 {
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.08);
    position: relative;
    background-color: #f6f9f7;
}
.client-logo-section.style2 .client-logo-container {
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0);
    border-radius: 20px;
} */



.da-client-logo-container {
    overflow: hidden;
}

.da-client-logo-container .swiper-wrapper {
    align-items: center;
  }
  .da-client-logo-container .client-logo {
    text-align: center;
  }
  .da-client-logo-container .client-logo img {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    opacity: 0.6;
    transition: all 0.3s ease;
  }
  .da-client-logo-container .client-logo:hover img {
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
    opacity: 1;
  }


.banner {
    width: 100%;
    background-color: black;
    position: relative;
}

@media (min-width: 991px) {
  .banner {
    max-height: 100vh;
    overflow: hidden;
  }
}


.our-client-area {
    width: 100%;
    padding-bottom: 30px;
  }
  /* .our-client-area.style2 {
    margin-top: 50px;
  }
  .our-client-area.style2 .client {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06) !important;
  } */
  .our-client-area .our-client .client {
    background: #fff;
    padding: 10px 30px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
  }
  @media (min-width: 1200px) {
    .our-client-area .our-client .client {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
    }
  }
  .our-client-area .our-client .client .client-container {
    overflow: hidden;
    width: 100%;
  }
  .our-client-area .our-client .client .client-container .client-logo {
    text-align: center;
  }
  .our-client-area .our-client .client .client-container .swiper-slide:after {
    content: "";
    position: absolute;
    left: -20px;
    top: 50%;
    margin-top: -15px;
    width: 1px;
    height: 42px;
    background-color: #d8d8d8;
    display: none;
  }


  @media (min-width: 1200px) {
    .banner-slider .our-client-area {
      position: absolute;
      left: 0;
      bottom: 30px;
      z-index: 2;
    }
  }
  @media (min-width: 1500px) {
    .banner-slider .our-client-area {
      bottom: 60px;
    }
  }
  .banner-slider .our-client-area .our-client {
    max-width: 940px;
    width: 70%;
    margin: auto;
  }
  @media (min-width: 1500px) {
    .banner-slider .our-client-area .our-client {
      max-width: 1090px;
      width: 70%;
      display: flex;
    }
  }
  .banner-slider .our-client-area .our-client .client {
    padding: 0;
  }
  .banner-slider .our-client-area .our-client .name {
    line-height: 50px;
    color: #fff;
    font-size: 17px;
    text-align: center;
    min-width: 110px;
  }
  @media (min-width: 992px) {
    .banner-slider .our-client-area .our-client .name {
      line-height: 80px;
      color: #2e3c40;
      font-size: 17px;
      text-align: center;
      min-width: 110px;
    }
  }
  @media (min-width: 1200px) {
    .banner-slider .our-client-area .our-client .name {
      color: #fff;
    }
  }
  
  .banner-slider .our-client-area .our-client .client .client-container .client-logo img {
    transform: scale(0.9);
  }
  @media (min-width: 1500px) {
    .banner-slider .our-client-area .our-client .client .client-container .client-logo img {
      transform: scale(0.9);
    }
  }
  @media (min-width: 992px) {
    .our-client-area {
      padding-bottom: 0;
    }
  }
  @media (min-width: 1500px) {
    .banner-slider .our-client-area .our-client .client {
      padding: 15px 30px;
      width: calc(100% - 110px);
    }
  }

.about-image {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

.bz-about-slider {
  max-width: 750px;
  width: 100%;
}
@media (width: 1024px) {
  .bz-about-slider {
    width: 650px;
  }
}
@media (min-width: 1200px) {
  .bz-about-slider {
    width: 750px;
    transform: translateX(-140px);
  }
}
@media (min-width: 1300px) {
  .bz-about-slider {
    right: -20px;
  }
}
.bz-about-slider .swiper-pagination-fraction {
  bottom: 0px !important;
  left: 20px !important;
  width: auto !important;
  color: #e9e9e9;
}
@media (min-width: 768px) {
  .bz-about-slider .swiper-pagination-fraction {
    left: 140px !important;
  }
}
.bz-about-slider .swiper-pagination-fraction .swiper-pagination-total {
  color: #d4d4d4;
}
.bz-about-slider .swiper-pagination-fraction .swiper-pagination-current {
  color: #2e3c40;
}

.bz-about-slide-container {
  overflow: hidden;
  padding-bottom: 150px;
  position: relative;
}
@media (min-width: 576px) {
  .bz-about-slide-container {
    padding-bottom: 160px;
  }
}
@media (min-width: 768px) {
  .bz-about-slide-container {
    padding-bottom: 250px;
  }
}
.bz-about-slide-container .swiper-slide {
  transition: all 0.5s ease-in-out;
  opacity: 0.3;
}
.bz-about-slide-container .swiper-slide-prev {
  transform: translateX(40%);
  opacity: 0.3;
}
.bz-about-slide-container .swiper-slide-next {
  transform: translateX(-40%);
  opacity: 0.3;
}
.bz-about-slide-container .swiper-slide-active {
  z-index: 2;
  opacity: 1 !important;
  transform: scale(1.4) translateY(50px);
}
@media (min-width: 768px) {
  .bz-about-slide-container .swiper-slide-active {
    transform: scale(1.4) translateY(100px);
  }
}



.bz-about-slide-container .swiper-button-prev:after, .bz-about-slide-container .swiper-button-next:after {
  font-size: 15px;
  color: black;
}

.bz-about-slide-container .swiper-button-next {
  top: auto;
  bottom: 0px;
  left: auto;
  right: 50%;
  margin-right: -50px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 35px;
  height: 35px;
  outline: none;
  transition: all 0.3s ease;
}

.bz-about-slide-container .swiper-button-prev {
  top: auto;
  bottom: 0px;
  left: auto;
  right: 50%;
  margin-right: 0px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 35px;
  height: 35px;
  outline: none;
  transition: all 0.3s ease;
}

.bz-about-slide-container .swiper-button-next:hover,.bz-about-slide-container .swiper-button-prev:hover {
  border-color: #008dd2;
}
.bz-about-slide-container .swiper-button-prev:hover:after, .bz-about-slide-container .swiper-button-next:hover:after {
  color: #008dd2;
}

@media (min-width: 990px) {
  .bz-about-slide-container .swiper-button-next{
    right: 50px;
    margin-right: 0px;
  }
  .bz-about-slide-container .swiper-button-prev{
    right: 100px;
    margin-right: 0px;
  }
}

.bz-section-header {
    text-align: center;
  }
  .bz-section-header .icon i {
    font-size: 65px;
  }
  .bz-section-header .subtitle {
    color: #008dd2;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
  }
  .bz-section-header .title {
    font-weight: 600;
    margin-bottom: 40px;
    position: relative;
  }
  .bz-section-header .title:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -20px;
    width: 35px;
    margin-left: -17px;
    height: 1px;
    background-color: #008dd2;
  }
  .bz-section-header h2 {
    font-size: 25px;
  }
  @media (min-width: 576px) {
    .bz-section-header h2 {
      font-size: 32px;
    }
  }
  .bz-section-header p {
    margin-bottom: 0;
  }
  @media (min-width: 992px) {
    .bz-section-header {
      text-align: left;
      position: relative;
      z-index: 1;
    }
    .bz-section-header .title:after {
      left: 0;
      margin-left: 0;
    }
  }


  .bz-section-header ul li {
    line-height: 2.1;
  }
  .bz-section-header ul li svg {
    color: #008dd2;
    margin-right: 10px;
  }
.working-process-section {
    overflow: hidden;
}
.working-process-section .bz-working-process {
    position: relative;
  }
  .working-process-section .bz-working-process:after {
    content: "";
    position: absolute;
    top: -10px;
    left: 100%;
    width: 50%;
    height: 110%;
    background-color: rgba(249, 249, 249, 0.7);
    z-index: 1;
  }
  .working-process-section .bz-working-process:before {
    content: "";
    position: absolute;
    top: -10px;
    right: 100.8%;
    width: 50%;
    height: 110%;
    background-color: #f9f9f9;
    z-index: 2;
  }
.bz-process-item {
    width: 100%;
    height: 200px;
    position: relative;
    overflow: hidden;
  }
  .bz-process-item .flippy-container {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
  .bz-process-item .flippy-container .flippy-front, .bz-process-item .flippy-container .flippy-back {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 20px;
  }
  .bz-process-item .flippy-container .flippy-front .process-count, .bz-process-item .flippy-container .flippy-back .process-count {
    position: absolute;
    left: 20px;
    top: 20px;
  }
  .bz-process-item .flippy-container .flippy-front {
    background-color: #fff;
  }
  .bz-process-item .flippy-container .flippy-front .name {
    color: #2e3c40;
    font-size: 20px;
  }
  .bz-process-item .flippy-container .flippy-back {
    background-color: #008dd2;
  }
  .bz-process-item .flippy-container .flippy-back .process-count {
    color: #fff;
  }
  .bz-process-item .flippy-container .flippy-back .desc {
    margin-bottom: 0;
    color: #fff;
    line-height: 1.7;
  }




  
.bz-working-process-container {
  padding-bottom: 20px;
}
@media (min-width: 992px) {
  .bz-working-process-container {
    padding-bottom: 80px;
  }
  .bz-working-process-container .swiper-wrapper {
    cursor: ew-resize;
  }
}
.bz-working-process-container .swiper-pagination-progressbar {
  width: 75%;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #eeeeee;
  height: 2px;
  margin-top: -1px;
}

.bz-working-process-container .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #d2d2d2;
  height: 2px;
}


.bz-working-process-container .swiper-button-prev {
    width: 35px;
    height: 35px;
    top: auto;
    bottom: -39px;
    left: 15px;
    outline: none;
}

.bz-working-process-container .swiper-button-next {
    width: 35px;
    height: 35px;
    top: auto;
    bottom: -39px;
    right: 15px;
    left: auto;
    outline: none;
}

.bz-working-process-container .swiper-button-prev:after,.bz-working-process-container .swiper-button-next:after {
    font-size: 30px;
    color: #444;
}
.bz-working-process-container .swiper-button-prev:focus:after,
.bz-working-process-container .swiper-button-next:focus:after,
.bz-working-process-container .swiper-button-prev:active:after,
.bz-working-process-container .swiper-button-next:active:after,
.bz-working-process-container .swiper-button-prev:hover:after,
.bz-working-process-container .swiper-button-next:hover:after {
    color: #008dd2;
}

@media (min-width: 560px) {
  .bz-working-process-container .swiper-pagination-progressbar {
    width: 85%;
    top: 110%;
  }
  .bz-working-process-container .swiper-button-prev {
    left: 15px;
  }
  .bz-working-process-container .swiper-button-next {
    right: 15px;
  }
}


@media (min-width: 768px) {
  .bz-working-process-container .swiper-pagination-progressbar {
    width: 85%;
    top: 110%;
  }
  .bz-working-process-container .swiper-button-prev {
    left: 30px;
  }
  .bz-working-process-container .swiper-button-next {
    right: 30px;
  }
}

@media (min-width: 990px) {
  .bz-working-process-container .swiper-pagination-progressbar {
    width: 85%;
    top: 108%;
  }
  .bz-working-process-container .swiper-button-prev {
    left: 40px;
  }
  .bz-working-process-container .swiper-button-next {
    right: 40px;
  }
}



@media (min-width: 1200px) {
  .bz-working-process-container .swiper-pagination-progressbar {
    width: 80%;
  }
  .bz-working-process-container .swiper-button-prev {
    left: 95px;
  }
  .bz-working-process-container .swiper-button-next {
    right: 95px;
  }
}


.aboutus.style2 .section-header {
    padding-left: 0;
  }
  .aboutus.style2 .section-header .desc {
    margin-bottom: 30px;
  }
  .aboutus.style2 .video-image {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }
  .aboutus.style2 .video-image:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .aboutus.style2 .video-image img {
    width: 100%;
  }
  @media (min-width: 992px) {
    .aboutus.style2 .video-image {
      transform: translateX(-120px);
    }
  }
  .aboutus.style2 .video-image .video-button {
    /* width: 70px;
    height: 70px; */
    border: none;
    border-radius: 100%;
    text-align: center;
    /* line-height: 72px; */
    color: #2e3c40;
    background-color: #fff;
    display: inline-block;
    margin-right: 35px;
    position: absolute;
    left: 50%;
    top: 50%;
    /* margin-top: -35px;
    margin-left: -35px; */
    transition: all 0.3s ease-in-out;
    z-index: 2;
    width: 100px;
    height: 100px;
    line-height: 98px;
    font-size: 35px;
    margin-left: -50px;
    margin-top: -50px;
  }
  .aboutus.style2 .video-image .video-button:hover {
      background-color: #008dd2
  }
  @media (min-width: 576px) {
    .aboutus.style2 .video-image .video-button {
      width: 100px;
      height: 100px;
      line-height: 98px;
      font-size: 35px;
      margin-left: -50px;
      margin-top: -50px;
    }
  }
  .aboutus.style2 .video-image .video-button i {
    font-size: 26px;
  }
  .aboutus.style2 .video-image .video-button:hover {
    color: #fff !important;
  }






  /*   modal video  */
  .modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(90, 80, 90, 0.99);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-name: modal-video;
    animation-name: modal-video;
    transition: opacity .3s ease-out;
}

.modal-video-body {
  max-width: 940px;
  width: 70%;
  height: 100%;
  margin: 0 auto;
  display: table;
}

.modal-video-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-name: modal-video-inner;
  animation-name: modal-video-inner;
  transform: translate(0);
  transition: transform .3s ease-out;
}

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -20px;
  right: -20px;
  display: inline-block;
  width: 25px;
  height: 25px;
  overflow: hidden;
  border: none;
  background: transparent;
}
.modal-video-close-btn:after, .modal-video-close-btn:before {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  background: #fff;
  border-radius: 5px;
  margin-top: -4px;
}
.modal-video-close-btn:before {
  transform: rotate(45deg);
}
.modal-video-close-btn:after {
  transform: rotate(-45deg);
}
.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.borderbottom {
    border-bottom: 1px solid #ececec !important;
}
.service-section .borderbottom {
    margin-bottom: 30px;
    margin-top: 30px;
  }
 
  
  .bz-service-area .react-tabs__tab-list {
    padding-bottom: 0px;
    border-bottom: none;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (min-width: 992px) {
    .service-section .borderbottom {
      margin-bottom: 0px;
      margin-top: 0px;
    }
    .bz-service-area .react-tabs__tab-list {
      justify-content: start;
    }
  }
  .bz-service-area .react-tabs__tab-list li {
    text-align: center;
    padding: 15px;
    outline: none;
    bottom: 0px;
    border: none;
  }
  .bz-service-area .react-tabs__tab-list li:after {
      display: none;
  }
  .bz-service-area .react-tabs__tab-list li.react-tabs__tab--selected,
  .bz-service-area .react-tabs__tab-list li:focus,
  .bz-service-area .react-tabs__tab-list li:active {
      outline: none;
      box-shadow: none;
      border: none;
      background-color: transparent;
  }
  @media (min-width: 992px) {
    .bz-service-area .react-tabs__tab-list li {
      padding: 25px;
    }
  }
  .bz-service-area .react-tabs__tab-list li:last-child {
    padding-right: 0;
  }
  .bz-service-area .react-tabs__tab-list li:first-child {
    padding-left: 0;
  }
  .bz-service-area .react-tabs__tab-list li .icon {
    display: block;
    font-size: 50px;
    color: #b5b5b6;
    transition: all 0.3s ease-in-out;
  }
  .bz-service-area .react-tabs__tab-list li .text {
    display: block;
    font-size: 14px;
    color: #2e3c40;
    opacity: 0.7;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
  }
  .bz-service-area .react-tabs__tab-list li.active .icon, .bz-service-area .react-tabs__tab-list li:hover .icon {
    color: #008dd2;
  }
  .bz-service-area .react-tabs__tab-list li.active .text, .bz-service-area .react-tabs__tab-list li:hover .text {
    opacity: 1;
  }
  .bz-service-area .react-tabs__tab-panel .service-content {
    overflow: hidden;
    position: relative;
    padding-bottom: 0px;
    text-align: center;
  }
  @media (min-width: 992px) {
    .bz-service-area .react-tabs__tab-panel .service-content {
      padding-bottom: 0;
      text-align: left;
    }
  }
  .bz-service-area .react-tabs__tab-panel .service-content > p:last-child {
    margin-bottom: 0;
  }
  .bz-service-area .react-tabs__tab-panel .service-content .sc-left {
    padding-right: 0px;
    margin-bottom: 40px;
    text-align: center;
  }
  @media (min-width: 992px) {
    .bz-service-area .react-tabs__tab-panel .service-content .sc-left {
      text-align: left;
    }
    .bz-service-area .react-tabs__tab-panel:nth-child(even) .service-content .sc-left {
        order: 2;
    }
    .bz-service-area .react-tabs__tab-panel:nth-child(even) .service-content .sc-left {
        padding-left: 70px;
        padding-right: 0px !important;
    }
  }
  .bz-service-area .react-tabs__tab-panel .service-content .sc-left h5 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  /* .bz-service-area .react-tabs__tab-panel .service-content .sc-right .service-image {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 60px;
  } */
  @media (min-width: 992px) {
    /* .bz-service-area .react-tabs__tab-panel .service-content .sc-right .service-image {
      margin-bottom: 0;
    } */
  }
  /* .bz-service-area .react-tabs__tab-panel .service-content .sc-right .service-image img {
    width: 100%;
  } */
  .bz-service-area .react-tabs__tab-panel .service-content .button-control-group {
    bottom: 0px;
    left: 50%;
    margin-left: -42px;
    width: 85px;
  }
  @media (min-width: 992px) {
    .bz-service-area .react-tabs__tab-panel .service-content .button-control-group {
      bottom: 40px;
      left: 5px;
      margin-left: 0;
    }
  }
  @media (min-width: 992px) {
    .bz-service-area .react-tabs__tab-panel .service-content .sc-left, .bz-service-area .react-tabs__tab-panel .service-content .sc-right {
      width: 50%;
    }
    .bz-service-area .react-tabs__tab-panel .service-content .sc-left {
      padding-right: 70px;
      margin-bottom: 0;
    }
    .bz-service-area .react-tabs__tab-panel .service-content .sc-left .service-image {
      border-radius: 10px;
      overflow: hidden;
    }
  }
.bz-section-header.center-styled .title:after, .bz-section-header.text-center .title:after {
    left: 50%;
    margin-left: -17px;
}
.project-container {
    padding-left: 30px;
    padding-right: 30px;
}
.project-container .react-tabs__tab-list {
    border: none;
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.project-container .react-tabs__tab-list .react-tabs__tab {
    border: none;
    display: inline-block;
    text-transform: capitalize;
    padding: 5px;
    color: #2e3c40;
    background-color: transparent;
    font-weight: 700;
    cursor: pointer;
    margin: 6px;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
}
.project-container .react-tabs__tab-list .react-tabs__tab:first-child {
  padding-left: 0;
}
.project-container .react-tabs__tab-list .react-tabs__tab:focus {
  box-shadow: none;
}
.project-container .react-tabs__tab-list .react-tabs__tab:last-child {
  padding-right: 0;
}

.project-container .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
    opacity: 1;
}

.project-container .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected:after {
    content: "";
    position: absolute;
    left: 15px;
    bottom: 5px;
    width: 23px;
    height: 2px;
    background-color: #008dd2;
}
@media (min-width: 992px) {
  .project-container .react-tabs__tab-list .react-tabs__tab {
    padding: 15px;
  }
}

.project-container .project-item {
    margin-bottom: 30px;
}
.project-container .project-item .project-thumb {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  }
  .project-container .project-item .project-thumb:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    transition: all 0.2s ease-in-out;
    opacity: 0;
    border-radius: 10px;
  }
  .project-container .project-item .project-thumb img {
    width: 100%;
    transition: all 0.3s ease-in-out;
  }
  .project-container .project-item .project-thumb .project-content {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    padding: 20px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    text-align: left;
  }
  .project-container .project-item .project-thumb .project-content .project-content-inner {
    background-color: #fff;
    padding: 35px 25px;
    border-radius: 10px;
  }
  .project-container .project-item .project-thumb .project-content .project-content-inner .cata {
    margin-left: 32px;
    position: relative;
    margin-bottom: 0px;
    font-size: 18px;
    color: #151a1b;
  }
  .project-container .project-item .project-thumb .project-content .project-content-inner .cata:after {
    content: "";
    position: absolute;
    top: 50%;
    left: -31px;
    width: 24px;
    height: 1px;
    background-color: #008dd2;
    z-index: 1;
  }
  .project-container .project-item .project-thumb .project-content .project-content-inner .title {
    font-weight: 600;
    margin-top: 0;
    font-size: 18px;
  }
  .project-container .project-item .project-thumb .project-content .project-content-inner .title a {
    font-weight: inherit;
    color: inherit;
    transition: all 0.3s ease-in-out;
  }
  .project-container .project-item .project-thumb .project-content .project-content-inner .title a:hover {
    color: #008dd2;
  }
  .project-container .project-item:hover .project-content {
    top: 0;
    opacity: 1;
  }
  .project-container .project-item:hover .project-thumb:after {
    opacity: 1;
  }
  .project-container .project-item:hover .project-thumb img {
    transform: scale(1.3);
  }
.bz-customer-satisfection-section {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (min-width: 576px) {
    .bz-customer-satisfection-section {
      padding-left: 0;
      padding-right: 0;
    }
  }
  /* @media (min-width: 992px) {
    .bz-customer-satisfection-section {
      padding-bottom: 0;
    }
  } */
  .bz-customer-satisfection-section .section-header {
    margin-bottom: 40px;
  }
  @media (min-width: 992px) {
    .bz-customer-satisfection-section .section-header {
      margin-bottom: 0;
    }
  }
  .bz-customer-satisfection-section .image {
    margin-bottom: 40px;
    border-radius: 10px;
    overflow: hidden;
  }


/* counter up */
.bz-counter-up {
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
    padding: 30px 40px;
    margin: auto;
    border-radius: 10px;
    justify-content: center;
  }
  .bz-counter-up .counter-item {
    flex-grow: 1;
    position: relative;
    padding-left: 0;
    margin-bottom: 20px;
    text-align: center;
  }
  @media (min-width: 768px) {
    .bz-counter-up {
      display: flex;
      flex-wrap: wrap;
    }
    .bz-counter-up .counter-item {
      margin-bottom: 0;
      text-align: left;
      padding-left: 40px;
    }
  }
  .bz-counter-up .counter-item:last-child:after {
    display: none;
  }
  .bz-counter-up .counter-item:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1px;
    height: 65px;
    top: 50%;
    margin-top: -32px;
    background-color: #ececec;
    display: none;
  }
  @media (min-width: 768px) {
    .bz-counter-up .counter-item:after {
      display: block;
    }
  }
  .bz-counter-up .counter-item .count-number {
    font-size: 36px;
    font-weight: 600;
    color: #008dd2;
  }
  .bz-counter-up .counter-item .text {
    font-size: 14px;
    font-weight: 600;
    color: #2e3c40;
    text-transform: uppercase;
  }
.blog-section .section-header {
    padding-bottom: 60px;
  }
  .blog-section .custom-btn2 {
    margin-top: 30px;
    padding: 16px 54px;
    font-size: 16px;
    font-weight: 700;
  }
  @media (min-width: 992px) {
    .blog-section .custom-btn2 {
      margin-top: 70px;
    }
  }
  
  .post-item {
    margin-bottom: 30px;
  }
  @media (min-width: 992px) {
    .post-item {
      margin-bottom: 0;
    }
  }
  .post-item .post-thumb {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  }
  .post-item .post-thumb img {
    width: 100%;
    transition: all 0.3s ease-in-out;
  }
  .post-item .post-thumb:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
  }
  .post-item .post-thumb .post-content {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    padding: 15px;
    text-align: left;
    z-index: 2;
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }
  .post-item .post-thumb .post-content .post-content {
    background-color: #fff;
    padding: 25px;
    border-radius: 5px;
  }
  .post-item .post-thumb .post-content .post-content .title {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    line-height: 1.4;
  }
  .post-item .post-thumb .post-content .post-content .title a {
    color: inherit;
    transition: all 0.3s ease-in-out;
  }
  .post-item:hover .post-thumb img {
    transform: scale(1.3);
  }
  .post-item:hover .post-thumb:after {
    opacity: 1;
  }
  .post-item.flip-style {
    background-color: transparent;
  }

  .post-item.flip-style .post-content .flippy-front {
    background-color: #fff;
    padding: 25px;
    border-radius: 10px;
  }
  .post-item.flip-style .post-content .flippy-front .title {
    font-size: 16px;
    font-weight: 500;
  }
  .post-item.flip-style .post-content .flippy-front .title a {
    color: #2e3c40;
  }
  .post-item.flip-style .post-content .flippy-back {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #008dd2;
    border-radius: 10px;
  }
  .post-item.flip-style .post-content .flippy-back .read-more {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
  }



  
.meta-post {
  padding: 10px 0;
  margin-bottom: 0;
}
.meta-post li {
  margin-right: 10px;
  font-weight: 400;
  color: #8a8a8a;
}
.meta-post li i, .meta-post li span {
  margin-right: 6px;
  color: #8a8a8a;
}
.meta-post li a {
  color: #8a8a8a;
}
.meta-post li a:hover {
  color: #008dd2;
}
.meta-post li .admin {
  color: #2e3c40;
}
.meta-post li:last-child {
  margin-right: 0;
}
.meta-post.line-style {
  position: relative;
  padding-left: 40px;
  list-style: none;
  margin-bottom: 6px;
  font-size: 15px;
}
.meta-post.line-style li {
  color: #8a8a8a;
}
.meta-post.line-style:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 30px;
  height: 1px;
  background-color: #008dd2;
}
.meta-post.border-style li {
  margin-right: 20px;
  font-size: 14px;
  position: relative;
}
.meta-post.border-style li:last-child {
  margin-right: 0;
}
.meta-post.border-style li:last-child:after {
  display: none;
}
.meta-post.border-style li:after {
  content: "";
  position: absolute;
  right: -11px;
  top: 50%;
  margin-top: -5px;
  width: 1px;
  height: 15px;
  background-color: #ececec;
  transform: rotate(25deg);
}




/*  blog page */
.inner-page .post-item {
  margin-bottom: 30px;
}
.col-lg-4:last-child .post-item {
  margin-bottom: 0;
}
.bz-action-section {
    background-color: #008dd2;
  }

  .bz-action-section .action-content .subtitle {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 2px;
    color: #fff;
    margin-bottom: 0px;
  }
  .bz-action-section .action-content .title {
    color: #fff;
    margin: 0;
    letter-spacing: 2px;
    font-weight: 600;
  }
  .bz-action-section .action-content .title a {
    font-size: 20px;
    color: #fff;
    text-decoration: underline;
    letter-spacing: 2px;
    transition: all 0.3s ease-in-out;
  }
  .bz-action-section .action-content .title a:hover {
    transform: translateY(-7px);
  }

.footer.footer1 .footertop .container {
    border-bottom: 1px solid #ececec;
    padding-top: 60px;
    padding-bottom: 0px;
  }
  @media (min-width: 992px) {
    .footer.footer1 .footertop .container {
      padding-top: 25px;
      padding-bottom: 0px;
    }
  }
  .footer.footer1 .footerbottom {
    padding: 15px 20px;
  }
  @media (min-width: 768px) {
    .footer.footer1 .footerbottom {
      padding: 20px 0;
    }
  }
  .footer.footer1 .footerbottom .copy-right {
    color: #2e3c40;
    text-align: center;
  }
  .footer.footer1 .footerbottom .copy-right a {
    color: #2e3c40;
    font-weight: 100;
  }
  .footer.footer1 .footerbottom .copy-right a:hover {
    color: #008dd2;
  }
  .footer.footer1 .footerbottom .social-media-list li a {
    color: #2e3c40;
  }
  .footer.footer1 .footerbottom .social-media-list li a:hover {
    color: #008dd2;
  }
  .footer.footer1 .footer-widget {
    margin-bottom: 20px;
    text-align: center;
    padding: 0 200px;
  }
  .footer.footer1 .footer-widget p {
    margin-bottom: 0;
  }
  @media (min-width: 768px) {
    .footer.footer1 .footer-widget {
      text-align: left;
      padding: 0;
    }
  }
  @media (min-width: 992px) {
    .footer.footer1 .footer-widget {
      margin-bottom: 0;
    }
  }
  .footer.footer1 .footer-widget .footer-logo {
    margin-bottom: 15px;
    margin-top: 0px;
    display: block;
  }
  @media (min-width: 992px) {
    .footer.footer1 .footer-widget .footer-logo {
      margin-bottom: 10px;
    }
  }
  .footer.footer1 .footer-widget .address {
    font-weight: 400;
    margin-bottom: 15px;
  }
  @media (min-width: 992px) {
    .footer.footer1 .footer-widget .address {
      padding-right: 30px;
      margin-bottom: 25px;
      font-size: large;
    }
  }
  .footer.footer1 .footer-widget .footer-site-info {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 30px;
  }
  .footer.footer1 .footer-widget .footer-site-info li {
    line-height: 1.6;
    font-weight: 400;
    font-size: large;
  }
  .footer.footer1 .footer-widget .googlemap {
    text-decoration: underline;
    font-weight: 400;
  }
  .footer.footer1 .footer-widget .googlemap:hover {
    color: #008dd2;
  }
  .footer.footer1 .footer-widget .footer-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  @media (min-width: 992px) {
    .footer.footer1 .footer-widget .footer-title {
      margin-bottom: 30px;
    }
  }
  .footer.footer1 .footer-widget .footer-menu, .footer.footer1 .footer-widget .linklist {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
  }
  .footer.footer1 .footer-widget .footer-menu li a, .footer.footer1 .footer-widget .linklist li a {
    font-size: 14px;
    font-weight: 400;
    color: #444;
    line-height: 2.5;
    transition: all 0.3s ease-in-out;
  }
  .footer.footer1 .footer-widget .footer-menu li a:hover, .footer.footer1 .footer-widget .linklist li a:hover {
    color: #008dd2;
  }
  .footer.footer1 .footer-widget form {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
  }
  .footer.footer1 .footer-widget form input {
    border: none;
    border-bottom: 1px solid #ececec;
    padding: 15px 50px 15px 20px;
    outline: none;
    width: 100%;
    text-align: center;
  }
  .footer.footer1 .footer-widget form input::-webkit-input-placeholder {
    color: #2e3c40;
    font-weight: 400;
  }
  .footer.footer1 .footer-widget form input:-ms-input-placeholder {
    color: #2e3c40;
    font-weight: 400;
  }
  .footer.footer1 .footer-widget form input::-ms-input-placeholder {
    color: #2e3c40;
    font-weight: 400;
  }
  .footer.footer1 .footer-widget form input::placeholder {
    color: #2e3c40;
    font-weight: 400;
  }
  .footer.footer1 .footer-widget form input:active, .footer.footer1 .footer-widget form input:focus {
    border-color: #008dd2;
  }
  .footer.footer1 .footer-widget form .submit {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -13px;
    color: #2e3c40;
    font-size: 20px;
    outline: none;
  }
  .footer.footer1 .footer-widget form input:active ~ .submit {
    color: #008dd2;
  }
  .footer.footer1 .footer-widget form input:focus ~ .submit {
    color: #008dd2;
  }

  @media (min-width: 768px) {
    .footer.footer1 .footer-widget form input {
      text-align: left;
    }
  }
.about-project-section {
    background-color: #fbfbfb;
}



.our-mission-section {
    background-color: #008dd2;
  }
.our-mission-section h2 {
    font-size: 25px;
  }
  @media (min-width: 768px) {
    .our-mission-section h2 {
      font-size: 28px;
    }
  }
  @media (min-width: 992px) {
    .our-mission-section h2 {
      font-size: 36px;
    }
  }

.da-testimonial-container {
    overflow: hidden;
  }
  .da-testimonial-container .testimonial-body {
    padding: 30px;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 10px;
  }
  .da-testimonial-container .author-image {
    width: 90px;
    height: 90px;
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
  }
  .da-testimonial-container .author-image img {
    width: 100%;
  }
  @media (min-width: 576px) {
    .da-testimonial-container .author-image {
      margin: unset;
    }
  }
  .da-testimonial-container .testimonial-content {
    width: calc(100% - 0px);
    text-align: center;
    padding-left: 20px;
    position: relative;
  }
  .da-testimonial-container .testimonial-content .qoute-icon {
    position: absolute;
    left: 20px;
    top: -15px;
    fill: #008dd2;
    opacity: 0.2;
    font-size: 50px;
  }
  .da-testimonial-container .testimonial-content .rating {
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
    margin-top: 15px;
  }
  .da-testimonial-container .testimonial-content .rating svg {
    fill: #008dd2;
  }
  .da-testimonial-container .testimonial-content .text {
    margin-bottom: 10px;
  }
  .da-testimonial-container .testimonial-content .name {
    font-weight: 600;
  }
  .da-testimonial-container .testimonial-content .designation {
    font-size: 14px;
    color: #d1d1d1;
  }
  @media (min-width: 576px) {
    .da-testimonial-container .testimonial-content {
      width: calc(100% - 90px);
      text-align: left;
    }
    .da-testimonial-container .testimonial-content .rating {
      margin-top: 0;
    }
  }


.da-testimonial-section {
    background-color: #f8f8fb;
}

.contact-form {
    padding: 40px 20px;
    text-align: right;
  }
  @media (min-width: 992px) {
    .contact-form {
      padding: 60px;
    }
  }
  .contact-form input, .contact-form textarea {
    width: 100%;
    outline: none;
    padding: 13px 20px;
    margin-bottom: 35px;
    border: 1px solid #ececec;
    border-radius: 2px;
  }
  .contact-form input:active, .contact-form input:focus, .contact-form textarea:active, .contact-form textarea:focus {
    border-color: #008dd2;
  }
  .contact-form textarea {
    margin-bottom: 20px;
    min-height: 100px;
  }
  @media (min-width: 992px) {
    .contact-form textarea {
      margin-bottom: 60px;
    }
  }
  .contact-form .submit {
    width: 144px;
    margin-left: auto;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 0;
    background-color: #008dd2;
    border-radius: 3px;
    padding: 15px 35px;
    transition: all 0.3s ease-in-out;
  }
  .contact-form .submit:hover {
    transform: translateY(-5px);
  }

.contact-info {
    padding: 40px 60px;
  }
  .contact-item {
      text-align: left;
  }
  .contact-item .contact-icon {
    float: left;
    color: #fff;
    font-size: 20px;
  }
  .contact-item .contact-content {
    margin-left: 40px;
  }
  .contact-item .contact-content .name {
    font-size: 16px;
    color: #fff;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .contact-item .contact-content p {
    margin-bottom: 0;
    color: #fff;
    line-height: 1.7;
  }
.contact-area {
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.06);
  }
  
  .contact-info-area {
    background-color: #008dd2;
  }

.page-header {
    height: 300px;
    position: relative;
}
@media (min-width: 992px) {
    .page-header {
        height: 450px;
    }
}
  .page-header:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1;
  }
  .page-header .page-header-content {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(0%);
    width: 100%;
    text-align: center;
    z-index: 2;
  }
  @media (min-width: 768px) {
    .page-header .page-header-content {
      transform: translateY(-50%);
    }
  }
  .page-header .page-header-content .page-header-text {
    padding-left: 20px;
    padding-right: 20px;
  }
  .page-header .page-header-content .page-header-text h2 {
    color: #fff;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 0px;
    letter-spacing: 1.5px;
  }
  .page-header .page-header-content .page-header-text p {
    margin-bottom: 0;
    color: #fff;
    letter-spacing: 1px;
  }
  @media (min-width: 768px) {
    .page-header .page-header-content .page-header-text {
      padding-left: 0px;
      padding-right: 0px;
    }
    .page-header .page-header-content .page-header-text h2 {
      font-size: 42px;
    }
  }
  .page-header.about {
    background-image: url(/static/media/3page-header-bg.6dd33797.jpg);
  }
  .page-header.blog {
    background-image: url(/static/media/5page-header-bg.f2be956d.jpg);
  }
  .page-header.portfolio {
    background-image: url(/static/media/4page-header-bg.84f73416.jpg);
  }
  .page-header.contact-page {
    background-image: url(/static/media/3page-header-bg.6dd33797.jpg);
  }


  
.inner-page .section-header {
  margin-top: 0px;
  position: relative;
  z-index: 2;
  background-color: rgb(190, 56, 146);
  box-shadow: 0px 0px 10px 0px rgba(46, 60, 64, 0.05);
  padding: 60px 30px 60px 30px;
  text-align: center;
  margin-top: 60px;
  border-radius: 5px;
}
.inner-page .section-header h2 {
  font-size: 30px;
  margin-bottom: 15px;
}
.inner-page .section-header p {
  margin-bottom: 0;
  line-height: 2.1;
}
@media (min-width: 768px) {
  .inner-page .section-header {
    padding: 60px 30px 60px 50px;
    margin-top: -130px;
    text-align: left;
  }
  .inner-page .section-header h2 {
    font-size: 32px;
  }
}
@media (min-width: 1200px) {
  .inner-page .section-header {
    padding: 60px 200px 60px 100px;
  }
}
.inner-page .inner-page-header {
  background-color: #fff;
  padding: 30px 0;
  text-align: center;
}
@media (min-width: 768px) {
  .inner-page .inner-page-header {
    padding: 70px 0;
    text-align: left;
  }
}
.inner-page .inner-page-header .section-header.inner-style2 {
  margin-top: 0;
  box-shadow: none;
  padding: 30px;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .inner-page .inner-page-header .section-header.inner-style2 {
    padding: 0;
  }
}

.action-section {
    background-color: #ff6360;
  }
  .action-section.style2 {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (min-width: 992px) {
    .action-section.style2 {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .action-section.style2 .title {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 40px;
  }
  @media (min-width: 992px) {
    .action-section.style2 .title {
      margin-bottom: 0;
      font-size: 28px;
    }
  }
  .action-section.style2 .da-custom-btn.btn-solid {
    background-color: #fff !important;
    color: #ff6360;
    border-color: white !important;
  }
  .action-section.style2 .da-custom-btn.btn-solid:hover {
    color: white !important;
  }
  .action-section.style2 .da-custom-btn.btn-solid:hover:after {
    background-color: #ff6360;
  }
  .action-section .action-content .subtitle {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 2px;
    color: #fff;
    margin-bottom: 0px;
  }
  .action-section .action-content .title {
    color: #fff;
    margin: 0;
    letter-spacing: 2px;
    font-weight: 600;
  }
  .action-section .action-content .title a {
    font-size: 20px;
    color: #fff;
    text-decoration: underline;
    letter-spacing: 2px;
    transition: all 0.3s ease-in-out;
  }
  .action-section .action-content .title a:hover {
    transform: translateY(-7px);
  }

.project-details-area {
    padding: 30px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  
  .project-details li {
    line-height: 2.5;
    font-weight: 600;
    font-size: 14px;
  }
  @media (min-width: 768px) {
    .project-details li {
      font-size: 16px;
    }
  }
  .project-details li span {
    width: 150px;
    font-weight: 600;
  }

.pagination-area .pagination li a {
    width: 34px;
    height: 34px;
    background-color: #f3f3f3;
    border: 1px solid #f3f3f3;
    text-align: center;
    line-height: 32px;
    font-weight: 500;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
    margin: 3px;
    display: block;
  }
  .pagination-area .pagination li a:hover, .pagination-area .pagination li a.active {
    color: #008dd2;
    border-color: #008dd2;
    background-color: transparent;
  }
  .pagination-area .pagination li:last-child a {
    background-color: #008dd2;
    color: #fff !important;
    font-size: 20px;
    border-color: #008dd2;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .pagination-area .pagination li:last-child a:hover svg {
    fill: #fff !important;
  }
  .pagination-area .pagination li:first-child {
    display: none;
  }
  .pagination-area .pagination li:first-child a {
    background-color: #008dd2;
    color: #fff !important;
    font-size: 20px;
    border-color: #008dd2;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .pagination-area .pagination li:first-child a:hover svg {
    fill: #fff !important;
  }
  

.entry-content {
    background-color: #fff;
    position: relative;
    z-index: 1;
  }
  @media (min-width: 768px) {
    .entry-content {
      transform: translateY(-120px);
    }
  }
  .entry-content .post-content {
    padding: 40px 20px;
  }
  @media (min-width: 992px) {
    .entry-content .post-content {
      padding: 60px;
    }
  }
  .entry-content .post-content .title {
    font-size: 30px;
    position: relative;
    margin-bottom: 50px;
  }
  @media (min-width: 768px) {
    .entry-content .post-content .title {
      font-size: 42px;
    }
  }
  .entry-content .post-content .title:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -20px;
    width: 30px;
    height: 1px;
    background-color: #008dd2;
  }
  .entry-content blockquote {
    padding: 55px 20px 55px 70px;
    position: relative;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
    margin-bottom: 40px;
  }
  @media (min-width: 768px) {
    .entry-content blockquote {
      padding: 55px 65px 55px 100px;
    }
  }
  .entry-content blockquote p {
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 400;
    color: #2e3c40;
  }
  .entry-content blockquote .b-au {
    color: #008dd2;
    font-weight: 400;
  }
  .entry-content blockquote .b-au span {
    color: #a6a6a6;
    margin-left: 7px;
  }
  .entry-content blockquote .qoute-icon {
    position: absolute;
    left: 10px;
    top: 40px;
    font-size: 56px;
    color: #008dd2;
    transform: rotate(-180deg);
  }
  @media (min-width: 768px) {
    .entry-content blockquote .qoute-icon {
      left: 35px;
    }
  }
  
  .entry-footer {
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media (min-width: 992px) {
    .entry-footer {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
  .entry-footer .tags li:first-child {
    font-size: 14px;
    font-weight: 600;
    margin-right: 15px;
  }
  .entry-footer .tags li {
    margin: 10px 5px;
  }
  .entry-footer .tags li a {
    padding: 5px 20px;
    border: 1px solid #ececec;
    background-color: #fafafa;
    border-radius: 30px;
    transition: all 0.3s ease-in-out;
  }
  .entry-footer .tags li a:hover {
    background-color: #008dd2;
    border-color: #008dd2;
    color: #fff;
  }
  .entry-footer .like-share li {
    margin: 0 40px;
  }
  .entry-footer .like-share li:first-child {
    padding-left: 0;
  }
  .entry-footer .like-share li:first-child a {
    color: #008dd2;
  }
  .entry-footer .like-share li:last-child {
    padding-right: 0;
  }
  .entry-footer .like-share li a {
    font-size: 20px;
    color: #2e3c40;
  }
  
  .comments-list {
    padding: 0;
    list-style: none;
    background-color: #fff;
  }
  .comments-list .comments {
    margin-bottom: 20px;
    list-style: none;
  }
  .comments-list .comments .thumb {
    width: 80px;
    height: 80px;
    float: left;
    margin-right: 20px;
    overflow: hidden;
    display: none;
  }
  @media (min-width: 768px) {
    .comments-list .comments .thumb {
      display: block;
    }
  }
  .comments-list .comments .content {
    background-color: transparent;
    border-bottom: 1px solid #ececec;
    display: table;
    margin-bottom: 30px;
    padding-bottom: 20px;
  }
  .comments-list .comments .content .comment-heading {
    position: relative;
  }
  .comments-list .comments .content .comment-heading .name {
    font-size: 18px;
    color: #2e3c40;
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
  }
  .comments-list .comments .content .comment-heading span {
    font-size: 14px;
    color: #959494;
    display: block;
    font-weight: 600;
  }
  .comments-list .comments .content .comment-heading .reply {
    position: absolute;
    right: 0;
    top: -6px;
    border: 1px solid #4e5a62;
    padding: 2px 18px;
    border-radius: 2px;
    transition: all 0.3s ease-in-out;
  }
  .comments-list .comments .content .comment-heading .reply:hover {
    background-color: #008dd2;
    border-color: #008dd2;
    color: #fff;
  }
  .comments-list .comments .content p {
    margin-bottom: 0px;
  }
  .comments-list .comments .comment-sub-list {
    padding: 0;
    margin-left: 20px;
    list-style: none;
    margin-top: 10px;
  }
  @media (min-width: 992px) {
    .comments-list .comments .comment-sub-list {
      margin-left: 70px;
    }
  }
  .comments-list .comments .comment-sub-list li {
    padding-left: 0;
  }
  
  .mas-section {
    background-color: #fff;
    padding: 30px;
  }
  @media (min-width: 768px) {
    .mas-section {
      padding-top: 0;
      margin-bottom: 0px;
      transform: translateY(-50px);
    }
  }
  .mas-section .section-title {
    text-align: left;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: 30px;
  }
  .mas-section .section-title span {
    color: #008dd2;
  }
  .mas-section .section-title p {
    margin-top: 7px;
    margin-bottom: 0px;
    font-weight: 400;
    color: #7a7878;
  }
  .mas-section .section-title p > span {
    color: #008dd2;
  }
  
  .comment-response {
    background-color: #fff;
  }
  .comment-response form label {
    color: #646568;
    font-size: 13px;
    font-weight: 500;
  }
  .comment-response form input {
    padding: 15px 25px;
    border: 1px solid #ececec;
    margin-bottom: 20px;
    width: 100%;
    font-size: 14px;
    font-weight: 300;
  }
  .comment-response form input:focus, .comment-response form input:active {
    outline: none;
    border: 1px solid #008dd2;
  }
  .comment-response form textarea {
    padding: 30px;
    min-height: 200px;
    width: 100%;
    border: 1px solid #ececec;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 20px;
  }
  .comment-response form textarea:focus, .comment-response form textarea:active {
    outline: none;
    border: 1px solid #008dd2;
  }
  .comment-response form input[type=submit] {
    background-color: transparent;
    margin-top: 20px;
    padding: 13px 55px;
    margin-bottom: 0;
    width: auto;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1px solid #ececec;
    font-weight: 500;
  }
  .comment-response form input[type=submit]:hover {
    background-color: #008dd2;
    color: #fff;
    border-color: #008dd2;
  }
  
  .thumb-slider-container {
    width: 100%;
    overflow: hidden;
    border-radius: 15px;
  }
  .thumb-slider-container .swiper-slide {
    border-radius: 15px;
  }
  .thumb-slider-container img {
    width: 100%;
  }
  
  .entry-single .thumb {
    margin-bottom: 20px;
  }
  
.map-area {
    background-color: #444;
    height: 600px;
  }
  .map-area iframe {
    opacity: 0.6;
    width: 100%;
    height: 600px;
    border: none;
  }


.bz-pricing-section {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (min-width: 576px) {
    .bz-pricing-section {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .bz-pricing-section .section-header {
    padding-bottom: 80px;
  }
  .bz-pricing-section .section-header .title:after {
    left: 50%;
    margin-left: -17px;
  }
  .bz-pricing-section .bz-pricing-item .pricing-footer .custom-btn1:hover {
    color: #fff !important;
  }
  
  .bz-pricing-item {
    background-color: #fff;
    padding: 60px 20px;
    border: 1px solid #ececec;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(46, 60, 64, 0.05);
    margin-bottom: 30px;
    text-align: center;
  }
  @media (min-width: 768px) {
    .bz-pricing-item {
      padding: 40px 50px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      text-align: left;
    }
  }
  .bz-pricing-item:last-child {
    margin-bottom: 0;
  }
  .bz-pricing-item .pricing-head {
    margin-bottom: 20px;
    flex-grow: 1;
  }
  @media (min-width: 768px) {
    .bz-pricing-item .pricing-head {
      margin-bottom: 0;
    }
  }
  .bz-pricing-item .pricing-head .name {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 1.5;
    color: #33414a;
  }
  .bz-pricing-item .pricing-head .price {
    font-size: 48px;
    margin-bottom: 0;
    font-weight: 400;
  }
  .bz-pricing-item .pricing-head .per-time {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.5;
    color: #33414a;
  }
  .bz-pricing-item .pricing-body {
    margin-bottom: 20px;
  }
  @media (min-width: 768px) {
    .bz-pricing-item .pricing-body {
      flex-grow: 2;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-bottom: 0;
    }
  }
  .bz-pricing-item .pricing-body .item-listing {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
  }
  @media (min-width: 992px) {
    .bz-pricing-item .pricing-body .item-listing {
      width: 280px;
    }
  }
  .bz-pricing-item .pricing-body .item-listing li {
    line-height: 2.5;
  }
  .bz-pricing-item .pricing-body .item-listing li svg {
    margin-right: 10px;
    fill: #008dd2;
  }
  @media (min-width: 768px) {
    .bz-pricing-item .pricing-footer {
      flex-grow: 1;
      text-align: right;
    }
  }
  .bz-pricing-item .pricing-footer .custom-btn1 {
    border-radius: 30px;
    font-size: 16px;
    font-weight: 500;
  }
  .bz-pricing-item .pricing-footer .custom-btn1:hover {
    color: #fff !important;
  }
.search-body {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    transform: scaleY(0);
    transform-origin: top;
    transition: all 0.3s ease-in-out; 
}
.search-body .menu-search-form {
    max-width: 500px;
    width: 100%;
    position: relative;
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.5s ease-in-out; 
}
.search-body .menu-search-form input {
    width: 100%;
    padding: 20px 40px;
    font-size: 24px;
    text-align: center;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #fff;
    color: #fff;
    outline: none; 
}
.search-body .menu-search-form input::-webkit-input-placeholder {
    color: #fff; 
}
.search-body .menu-search-form input:-ms-input-placeholder {
    color: #fff; 
}
.search-body .menu-search-form input::-ms-input-placeholder {
    color: #fff; 
}
.search-body .menu-search-form input::placeholder {
    color: #fff; 
}
        
.search-body .menu-search-form i {
    position: absolute;
    right: 0;
    top: 50%;
    color: #fff;
    display: none; 
}
.search-body .close-search {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 10%;
    right: 5%;
    cursor: pointer; 
}
.search-body .close-search:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -3px;
    width: 100%;
    height: 3px;
    background-color: #fff;
    transform: rotate(45deg); 
}
.search-body .close-search:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -3px;
    width: 100%;
    height: 3px;
    background-color: #fff;
    transform: rotate(-45deg); 
}
  
.search-open .search-body {
    transform: scaleY(1); 
    background-color: rgba(39, 49, 103, 0.98);
}
.search-open .menu-search-form {
    transition-delay: 0.5s;
    opacity: 1;
    transform: translateY(0px); 
}
.search-opens .search-body {
    transform: scaleY(1); 
    background-color:#008dd2;
}
.search-opens .menu-search-form {
    transition-delay: 0.5s;
    opacity: 1;
    transform: translateY(0px); 
}

@media (min-width: 992px) {
    .search-body .menu-search-form input {
        font-size: 36px; 
    } 
}
