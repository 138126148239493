
.about-image {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

.bz-about-slider {
  max-width: 750px;
  width: 100%;
}
@media (width: 1024px) {
  .bz-about-slider {
    width: 650px;
  }
}
@media (min-width: 1200px) {
  .bz-about-slider {
    width: 750px;
    -webkit-transform: translateX(-140px);
    -ms-transform: translateX(-140px);
    transform: translateX(-140px);
  }
}
@media (min-width: 1300px) {
  .bz-about-slider {
    right: -20px;
  }
}
.bz-about-slider .swiper-pagination-fraction {
  bottom: 0px !important;
  left: 20px !important;
  width: auto !important;
  color: #e9e9e9;
}
@media (min-width: 768px) {
  .bz-about-slider .swiper-pagination-fraction {
    left: 140px !important;
  }
}
.bz-about-slider .swiper-pagination-fraction .swiper-pagination-total {
  color: #d4d4d4;
}
.bz-about-slider .swiper-pagination-fraction .swiper-pagination-current {
  color: #2e3c40;
}

.bz-about-slide-container {
  overflow: hidden;
  padding-bottom: 150px;
  position: relative;
}
@media (min-width: 576px) {
  .bz-about-slide-container {
    padding-bottom: 160px;
  }
}
@media (min-width: 768px) {
  .bz-about-slide-container {
    padding-bottom: 250px;
  }
}
.bz-about-slide-container .swiper-slide {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  opacity: 0.3;
}
.bz-about-slide-container .swiper-slide-prev {
  transform: translateX(40%);
  opacity: 0.3;
}
.bz-about-slide-container .swiper-slide-next {
  transform: translateX(-40%);
  opacity: 0.3;
}
.bz-about-slide-container .swiper-slide-active {
  z-index: 2;
  opacity: 1 !important;
  transform: scale(1.4) translateY(50px);
}
@media (min-width: 768px) {
  .bz-about-slide-container .swiper-slide-active {
    transform: scale(1.4) translateY(100px);
  }
}



.bz-about-slide-container .swiper-button-prev:after, .bz-about-slide-container .swiper-button-next:after {
  font-size: 15px;
  color: black;
}

.bz-about-slide-container .swiper-button-next {
  top: auto;
  bottom: 0px;
  left: auto;
  right: 50%;
  margin-right: -50px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 35px;
  height: 35px;
  outline: none;
  transition: all 0.3s ease;
}

.bz-about-slide-container .swiper-button-prev {
  top: auto;
  bottom: 0px;
  left: auto;
  right: 50%;
  margin-right: 0px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 35px;
  height: 35px;
  outline: none;
  transition: all 0.3s ease;
}

.bz-about-slide-container .swiper-button-next:hover,.bz-about-slide-container .swiper-button-prev:hover {
  border-color: #008dd2;
}
.bz-about-slide-container .swiper-button-prev:hover:after, .bz-about-slide-container .swiper-button-next:hover:after {
  color: #008dd2;
}

@media (min-width: 990px) {
  .bz-about-slide-container .swiper-button-next{
    right: 50px;
    margin-right: 0px;
  }
  .bz-about-slide-container .swiper-button-prev{
    right: 100px;
    margin-right: 0px;
  }
}