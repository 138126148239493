
.contact-form {
    padding: 40px 20px;
    text-align: right;
  }
  @media (min-width: 992px) {
    .contact-form {
      padding: 60px;
    }
  }
  .contact-form input, .contact-form textarea {
    width: 100%;
    outline: none;
    padding: 13px 20px;
    margin-bottom: 35px;
    border: 1px solid #ececec;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }
  .contact-form input:active, .contact-form input:focus, .contact-form textarea:active, .contact-form textarea:focus {
    border-color: #008dd2;
  }
  .contact-form textarea {
    margin-bottom: 20px;
    min-height: 100px;
  }
  @media (min-width: 992px) {
    .contact-form textarea {
      margin-bottom: 60px;
    }
  }
  .contact-form .submit {
    width: 144px;
    margin-left: auto;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 0;
    background-color: #008dd2;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 15px 35px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .contact-form .submit:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }