

.borderbottom {
    border-bottom: 1px solid #ececec !important;
}
.service-section .borderbottom {
    margin-bottom: 30px;
    margin-top: 30px;
  }
 
  
  .bz-service-area .react-tabs__tab-list {
    padding-bottom: 0px;
    border-bottom: none;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (min-width: 992px) {
    .service-section .borderbottom {
      margin-bottom: 0px;
      margin-top: 0px;
    }
    .bz-service-area .react-tabs__tab-list {
      justify-content: start;
    }
  }
  .bz-service-area .react-tabs__tab-list li {
    text-align: center;
    padding: 15px;
    outline: none;
    bottom: 0px;
    border: none;
  }
  .bz-service-area .react-tabs__tab-list li:after {
      display: none;
  }
  .bz-service-area .react-tabs__tab-list li.react-tabs__tab--selected,
  .bz-service-area .react-tabs__tab-list li:focus,
  .bz-service-area .react-tabs__tab-list li:active {
      outline: none;
      box-shadow: none;
      border: none;
      background-color: transparent;
  }
  @media (min-width: 992px) {
    .bz-service-area .react-tabs__tab-list li {
      padding: 25px;
    }
  }
  .bz-service-area .react-tabs__tab-list li:last-child {
    padding-right: 0;
  }
  .bz-service-area .react-tabs__tab-list li:first-child {
    padding-left: 0;
  }
  .bz-service-area .react-tabs__tab-list li .icon {
    display: block;
    font-size: 50px;
    color: #b5b5b6;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .bz-service-area .react-tabs__tab-list li .text {
    display: block;
    font-size: 14px;
    color: #2e3c40;
    opacity: 0.7;
    font-weight: 500;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .bz-service-area .react-tabs__tab-list li.active .icon, .bz-service-area .react-tabs__tab-list li:hover .icon {
    color: #008dd2;
  }
  .bz-service-area .react-tabs__tab-list li.active .text, .bz-service-area .react-tabs__tab-list li:hover .text {
    opacity: 1;
  }
  .bz-service-area .react-tabs__tab-panel .service-content {
    overflow: hidden;
    position: relative;
    padding-bottom: 0px;
    text-align: center;
  }
  @media (min-width: 992px) {
    .bz-service-area .react-tabs__tab-panel .service-content {
      padding-bottom: 0;
      text-align: left;
    }
  }
  .bz-service-area .react-tabs__tab-panel .service-content > p:last-child {
    margin-bottom: 0;
  }
  .bz-service-area .react-tabs__tab-panel .service-content .sc-left {
    padding-right: 0px;
    margin-bottom: 40px;
    text-align: center;
  }
  @media (min-width: 992px) {
    .bz-service-area .react-tabs__tab-panel .service-content .sc-left {
      text-align: left;
    }
    .bz-service-area .react-tabs__tab-panel:nth-child(even) .service-content .sc-left {
        order: 2;
    }
    .bz-service-area .react-tabs__tab-panel:nth-child(even) .service-content .sc-left {
        padding-left: 70px;
        padding-right: 0px !important;
    }
  }
  .bz-service-area .react-tabs__tab-panel .service-content .sc-left h5 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  /* .bz-service-area .react-tabs__tab-panel .service-content .sc-right .service-image {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 60px;
  } */
  @media (min-width: 992px) {
    /* .bz-service-area .react-tabs__tab-panel .service-content .sc-right .service-image {
      margin-bottom: 0;
    } */
  }
  /* .bz-service-area .react-tabs__tab-panel .service-content .sc-right .service-image img {
    width: 100%;
  } */
  .bz-service-area .react-tabs__tab-panel .service-content .button-control-group {
    bottom: 0px;
    left: 50%;
    margin-left: -42px;
    width: 85px;
  }
  @media (min-width: 992px) {
    .bz-service-area .react-tabs__tab-panel .service-content .button-control-group {
      bottom: 40px;
      left: 5px;
      margin-left: 0;
    }
  }
  @media (min-width: 992px) {
    .bz-service-area .react-tabs__tab-panel .service-content .sc-left, .bz-service-area .react-tabs__tab-panel .service-content .sc-right {
      width: 50%;
    }
    .bz-service-area .react-tabs__tab-panel .service-content .sc-left {
      padding-right: 70px;
      margin-bottom: 0;
    }
    .bz-service-area .react-tabs__tab-panel .service-content .sc-left .service-image {
      border-radius: 10px;
      overflow: hidden;
    }
  }