
.bz-section-header {
    text-align: center;
  }
  .bz-section-header .icon i {
    font-size: 65px;
  }
  .bz-section-header .subtitle {
    color: #008dd2;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
  }
  .bz-section-header .title {
    font-weight: 600;
    margin-bottom: 40px;
    position: relative;
  }
  .bz-section-header .title:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -20px;
    width: 35px;
    margin-left: -17px;
    height: 1px;
    background-color: #008dd2;
  }
  .bz-section-header h2 {
    font-size: 25px;
  }
  @media (min-width: 576px) {
    .bz-section-header h2 {
      font-size: 32px;
    }
  }
  .bz-section-header p {
    margin-bottom: 0;
  }
  @media (min-width: 992px) {
    .bz-section-header {
      text-align: left;
      position: relative;
      z-index: 1;
    }
    .bz-section-header .title:after {
      left: 0;
      margin-left: 0;
    }
  }


  .bz-section-header ul li {
    line-height: 2.1;
  }
  .bz-section-header ul li svg {
    color: #008dd2;
    margin-right: 10px;
  }