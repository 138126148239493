.blog-section .section-header {
    padding-bottom: 60px;
  }
  .blog-section .custom-btn2 {
    margin-top: 30px;
    padding: 16px 54px;
    font-size: 16px;
    font-weight: 700;
  }
  @media (min-width: 992px) {
    .blog-section .custom-btn2 {
      margin-top: 70px;
    }
  }
  
  .post-item {
    margin-bottom: 30px;
  }
  @media (min-width: 992px) {
    .post-item {
      margin-bottom: 0;
    }
  }
  .post-item .post-thumb {
    position: relative;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  }
  .post-item .post-thumb img {
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .post-item .post-thumb:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
  .post-item .post-thumb .post-content {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    padding: 15px;
    text-align: left;
    z-index: 2;
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .post-item .post-thumb .post-content .post-content {
    background-color: #fff;
    padding: 25px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
  .post-item .post-thumb .post-content .post-content .title {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    line-height: 1.4;
  }
  .post-item .post-thumb .post-content .post-content .title a {
    color: inherit;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .post-item:hover .post-thumb img {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
  }
  .post-item:hover .post-thumb:after {
    opacity: 1;
  }
  .post-item.flip-style {
    background-color: transparent;
  }

  .post-item.flip-style .post-content .flippy-front {
    background-color: #fff;
    padding: 25px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
  .post-item.flip-style .post-content .flippy-front .title {
    font-size: 16px;
    font-weight: 500;
  }
  .post-item.flip-style .post-content .flippy-front .title a {
    color: #2e3c40;
  }
  .post-item.flip-style .post-content .flippy-back {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #008dd2;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
  .post-item.flip-style .post-content .flippy-back .read-more {
    font-size: 18px;
    color: #fff;
    font-weight: 500;
  }



  
.meta-post {
  padding: 10px 0;
  margin-bottom: 0;
}
.meta-post li {
  margin-right: 10px;
  font-weight: 400;
  color: #8a8a8a;
}
.meta-post li i, .meta-post li span {
  margin-right: 6px;
  color: #8a8a8a;
}
.meta-post li a {
  color: #8a8a8a;
}
.meta-post li a:hover {
  color: #008dd2;
}
.meta-post li .admin {
  color: #2e3c40;
}
.meta-post li:last-child {
  margin-right: 0;
}
.meta-post.line-style {
  position: relative;
  padding-left: 40px;
  list-style: none;
  margin-bottom: 6px;
  font-size: 15px;
}
.meta-post.line-style li {
  color: #8a8a8a;
}
.meta-post.line-style:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 30px;
  height: 1px;
  background-color: #008dd2;
}
.meta-post.border-style li {
  margin-right: 20px;
  font-size: 14px;
  position: relative;
}
.meta-post.border-style li:last-child {
  margin-right: 0;
}
.meta-post.border-style li:last-child:after {
  display: none;
}
.meta-post.border-style li:after {
  content: "";
  position: absolute;
  right: -11px;
  top: 50%;
  margin-top: -5px;
  width: 1px;
  height: 15px;
  background-color: #ececec;
  -webkit-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  transform: rotate(25deg);
}




/*  blog page */
.inner-page .post-item {
  margin-bottom: 30px;
}
.col-lg-4:last-child .post-item {
  margin-bottom: 0;
}