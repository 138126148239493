
.page-header {
    height: 300px;
    position: relative;
}
@media (min-width: 992px) {
    .page-header {
        height: 450px;
    }
}
  .page-header:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1;
  }
  .page-header .page-header-content {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    width: 100%;
    text-align: center;
    z-index: 2;
  }
  @media (min-width: 768px) {
    .page-header .page-header-content {
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  .page-header .page-header-content .page-header-text {
    padding-left: 20px;
    padding-right: 20px;
  }
  .page-header .page-header-content .page-header-text h2 {
    color: #fff;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 0px;
    letter-spacing: 1.5px;
  }
  .page-header .page-header-content .page-header-text p {
    margin-bottom: 0;
    color: #fff;
    letter-spacing: 1px;
  }
  @media (min-width: 768px) {
    .page-header .page-header-content .page-header-text {
      padding-left: 0px;
      padding-right: 0px;
    }
    .page-header .page-header-content .page-header-text h2 {
      font-size: 42px;
    }
  }
  .page-header.about {
    background-image: url(../images/3page-header-bg.jpg);
  }
  .page-header.blog {
    background-image: url(../images/5page-header-bg.jpg);
  }
  .page-header.portfolio {
    background-image: url(../images/4page-header-bg.jpg);
  }
  .page-header.contact-page {
    background-image: url(../images/3page-header-bg.jpg);
  }


  
.inner-page .section-header {
  margin-top: 0px;
  position: relative;
  z-index: 2;
  background-color: rgb(190, 56, 146);
  box-shadow: 0px 0px 10px 0px rgba(46, 60, 64, 0.05);
  padding: 60px 30px 60px 30px;
  text-align: center;
  margin-top: 60px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.inner-page .section-header h2 {
  font-size: 30px;
  margin-bottom: 15px;
}
.inner-page .section-header p {
  margin-bottom: 0;
  line-height: 2.1;
}
@media (min-width: 768px) {
  .inner-page .section-header {
    padding: 60px 30px 60px 50px;
    margin-top: -130px;
    text-align: left;
  }
  .inner-page .section-header h2 {
    font-size: 32px;
  }
}
@media (min-width: 1200px) {
  .inner-page .section-header {
    padding: 60px 200px 60px 100px;
  }
}
.inner-page .inner-page-header {
  background-color: #fff;
  padding: 30px 0;
  text-align: center;
}
@media (min-width: 768px) {
  .inner-page .inner-page-header {
    padding: 70px 0;
    text-align: left;
  }
}
.inner-page .inner-page-header .section-header.inner-style2 {
  margin-top: 0;
  box-shadow: none;
  padding: 30px;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .inner-page .inner-page-header .section-header.inner-style2 {
    padding: 0;
  }
}