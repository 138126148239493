.aboutus.style2 .section-header {
    padding-left: 0;
  }
  .aboutus.style2 .section-header .desc {
    margin-bottom: 30px;
  }
  .aboutus.style2 .video-image {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }
  .aboutus.style2 .video-image:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .aboutus.style2 .video-image img {
    width: 100%;
  }
  @media (min-width: 992px) {
    .aboutus.style2 .video-image {
      -webkit-transform: translateX(-120px);
      -moz-transform: translateX(-120px);
      -ms-transform: translateX(-120px);
      -o-transform: translateX(-120px);
      transform: translateX(-120px);
    }
  }
  .aboutus.style2 .video-image .video-button {
    /* width: 70px;
    height: 70px; */
    border: none;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    text-align: center;
    /* line-height: 72px; */
    color: #2e3c40;
    background-color: #fff;
    display: inline-block;
    margin-right: 35px;
    position: absolute;
    left: 50%;
    top: 50%;
    /* margin-top: -35px;
    margin-left: -35px; */
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 2;
    width: 100px;
    height: 100px;
    line-height: 98px;
    font-size: 35px;
    margin-left: -50px;
    margin-top: -50px;
  }
  .aboutus.style2 .video-image .video-button:hover {
      background-color: #008dd2
  }
  @media (min-width: 576px) {
    .aboutus.style2 .video-image .video-button {
      width: 100px;
      height: 100px;
      line-height: 98px;
      font-size: 35px;
      margin-left: -50px;
      margin-top: -50px;
    }
  }
  .aboutus.style2 .video-image .video-button i {
    font-size: 26px;
  }
  .aboutus.style2 .video-image .video-button:hover {
    color: #fff !important;
  }






  /*   modal video  */
  .modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(90, 80, 90, 0.99);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-name: modal-video;
    animation-name: modal-video;
    -webkit-transition: opacity .3s ease-out;
    transition: opacity .3s ease-out;
}

.modal-video-body {
  max-width: 940px;
  width: 70%;
  height: 100%;
  margin: 0 auto;
  display: table;
}

.modal-video-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-name: modal-video-inner;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: -webkit-transform .3s ease-out;
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out,-webkit-transform .3s ease-out;
}

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -20px;
  right: -20px;
  display: inline-block;
  width: 25px;
  height: 25px;
  overflow: hidden;
  border: none;
  background: transparent;
}
.modal-video-close-btn:after, .modal-video-close-btn:before {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  background: #fff;
  border-radius: 5px;
  margin-top: -4px;
}
.modal-video-close-btn:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.modal-video-close-btn:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}