.bz-customer-satisfection-section {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (min-width: 576px) {
    .bz-customer-satisfection-section {
      padding-left: 0;
      padding-right: 0;
    }
  }
  /* @media (min-width: 992px) {
    .bz-customer-satisfection-section {
      padding-bottom: 0;
    }
  } */
  .bz-customer-satisfection-section .section-header {
    margin-bottom: 40px;
  }
  @media (min-width: 992px) {
    .bz-customer-satisfection-section .section-header {
      margin-bottom: 0;
    }
  }
  .bz-customer-satisfection-section .image {
    margin-bottom: 40px;
    border-radius: 10px;
    overflow: hidden;
  }


/* counter up */
.bz-counter-up {
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
    padding: 30px 40px;
    margin: auto;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    justify-content: center;
  }
  .bz-counter-up .counter-item {
    flex-grow: 1;
    position: relative;
    padding-left: 0;
    margin-bottom: 20px;
    text-align: center;
  }
  @media (min-width: 768px) {
    .bz-counter-up {
      display: flex;
      flex-wrap: wrap;
    }
    .bz-counter-up .counter-item {
      margin-bottom: 0;
      text-align: left;
      padding-left: 40px;
    }
  }
  .bz-counter-up .counter-item:last-child:after {
    display: none;
  }
  .bz-counter-up .counter-item:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1px;
    height: 65px;
    top: 50%;
    margin-top: -32px;
    background-color: #ececec;
    display: none;
  }
  @media (min-width: 768px) {
    .bz-counter-up .counter-item:after {
      display: block;
    }
  }
  .bz-counter-up .counter-item .count-number {
    font-size: 36px;
    font-weight: 600;
    color: #008dd2;
  }
  .bz-counter-up .counter-item .text {
    font-size: 14px;
    font-weight: 600;
    color: #2e3c40;
    text-transform: uppercase;
  }