
.pagination-area .pagination li a {
    width: 34px;
    height: 34px;
    background-color: #f3f3f3;
    border: 1px solid #f3f3f3;
    text-align: center;
    line-height: 32px;
    font-weight: 500;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin: 3px;
    display: block;
  }
  .pagination-area .pagination li a:hover, .pagination-area .pagination li a.active {
    color: #008dd2;
    border-color: #008dd2;
    background-color: transparent;
  }
  .pagination-area .pagination li:last-child a {
    background-color: #008dd2;
    color: #fff !important;
    font-size: 20px;
    border-color: #008dd2;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .pagination-area .pagination li:last-child a:hover svg {
    fill: #fff !important;
  }
  .pagination-area .pagination li:first-child {
    display: none;
  }
  .pagination-area .pagination li:first-child a {
    background-color: #008dd2;
    color: #fff !important;
    font-size: 20px;
    border-color: #008dd2;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .pagination-area .pagination li:first-child a:hover svg {
    fill: #fff !important;
  }
  