.bz-action-section {
    background-color: #008dd2;
  }

  .bz-action-section .action-content .subtitle {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 2px;
    color: #fff;
    margin-bottom: 0px;
  }
  .bz-action-section .action-content .title {
    color: #fff;
    margin: 0;
    letter-spacing: 2px;
    font-weight: 600;
  }
  .bz-action-section .action-content .title a {
    font-size: 20px;
    color: #fff;
    text-decoration: underline;
    letter-spacing: 2px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .bz-action-section .action-content .title a:hover {
    -webkit-transform: translateY(-7px);
    -ms-transform: translateY(-7px);
    transform: translateY(-7px);
  }