
.project-details-area {
    padding: 30px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  
  .project-details li {
    line-height: 2.5;
    font-weight: 600;
    font-size: 14px;
  }
  @media (min-width: 768px) {
    .project-details li {
      font-size: 16px;
    }
  }
  .project-details li span {
    width: 150px;
    font-weight: 600;
  }