
.action-section {
    background-color: #ff6360;
  }
  .action-section.style2 {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (min-width: 992px) {
    .action-section.style2 {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .action-section.style2 .title {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 40px;
  }
  @media (min-width: 992px) {
    .action-section.style2 .title {
      margin-bottom: 0;
      font-size: 28px;
    }
  }
  .action-section.style2 .da-custom-btn.btn-solid {
    background-color: #fff !important;
    color: #ff6360;
    border-color: white !important;
  }
  .action-section.style2 .da-custom-btn.btn-solid:hover {
    color: white !important;
  }
  .action-section.style2 .da-custom-btn.btn-solid:hover:after {
    background-color: #ff6360;
  }
  .action-section .action-content .subtitle {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 2px;
    color: #fff;
    margin-bottom: 0px;
  }
  .action-section .action-content .title {
    color: #fff;
    margin: 0;
    letter-spacing: 2px;
    font-weight: 600;
  }
  .action-section .action-content .title a {
    font-size: 20px;
    color: #fff;
    text-decoration: underline;
    letter-spacing: 2px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .action-section .action-content .title a:hover {
    -webkit-transform: translateY(-7px);
    -ms-transform: translateY(-7px);
    transform: translateY(-7px);
  }