

.banner {
    width: 100%;
    background-color: black;
    position: relative;
}

@media (min-width: 991px) {
  .banner {
    max-height: 100vh;
    overflow: hidden;
  }
}


.our-client-area {
    width: 100%;
    padding-bottom: 30px;
  }
  /* .our-client-area.style2 {
    margin-top: 50px;
  }
  .our-client-area.style2 .client {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06) !important;
  } */
  .our-client-area .our-client .client {
    background: #fff;
    padding: 10px 30px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
  @media (min-width: 1200px) {
    .our-client-area .our-client .client {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
    }
  }
  .our-client-area .our-client .client .client-container {
    overflow: hidden;
    width: 100%;
  }
  .our-client-area .our-client .client .client-container .client-logo {
    text-align: center;
  }
  .our-client-area .our-client .client .client-container .swiper-slide:after {
    content: "";
    position: absolute;
    left: -20px;
    top: 50%;
    margin-top: -15px;
    width: 1px;
    height: 42px;
    background-color: #d8d8d8;
    display: none;
  }


  @media (min-width: 1200px) {
    .banner-slider .our-client-area {
      position: absolute;
      left: 0;
      bottom: 30px;
      z-index: 2;
    }
  }
  @media (min-width: 1500px) {
    .banner-slider .our-client-area {
      bottom: 60px;
    }
  }
  .banner-slider .our-client-area .our-client {
    max-width: 940px;
    width: 70%;
    margin: auto;
  }
  @media (min-width: 1500px) {
    .banner-slider .our-client-area .our-client {
      max-width: 1090px;
      width: 70%;
      display: flex;
    }
  }
  .banner-slider .our-client-area .our-client .client {
    padding: 0;
  }
  .banner-slider .our-client-area .our-client .name {
    line-height: 50px;
    color: #fff;
    font-size: 17px;
    text-align: center;
    min-width: 110px;
  }
  @media (min-width: 992px) {
    .banner-slider .our-client-area .our-client .name {
      line-height: 80px;
      color: #2e3c40;
      font-size: 17px;
      text-align: center;
      min-width: 110px;
    }
  }
  @media (min-width: 1200px) {
    .banner-slider .our-client-area .our-client .name {
      color: #fff;
    }
  }
  
  .banner-slider .our-client-area .our-client .client .client-container .client-logo img {
    transform: scale(0.9);
  }
  @media (min-width: 1500px) {
    .banner-slider .our-client-area .our-client .client .client-container .client-logo img {
      transform: scale(0.9);
    }
  }
  @media (min-width: 992px) {
    .our-client-area {
      padding-bottom: 0;
    }
  }
  @media (min-width: 1500px) {
    .banner-slider .our-client-area .our-client .client {
      padding: 15px 30px;
      width: calc(100% - 110px);
    }
  }