
.header.transparent-header {
    background-color: black;
  }
  @media (min-width: 991px) {
    .header.transparent-header {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      z-index: 9;
      background-color:transparent;
    }
  }
  .header.style2 {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9;
    background-color:transparent;
  }
  .header.sticky .header-top {
    display: none !important;
  }
  .header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.75);
  }
  .header.transparent-header .main-menu-area {
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .header.transparent-header .main-menu-area .logo {
    margin-left: 10px;
    padding: 10px 0;
  }
  .header.transparent-header .main-menu-area .logo .navbar-brand {
    display: none;
  }
  .header.transparent-header .main-menu-area .logo .navbar-brand2 {
    display: block;
  }
  @media (min-width: 576px) {
    .header.transparent-header .main-menu-area .logo {
      margin-left: 30px !important;
    }
    .header.transparent-header .main-menu-area .logo img {
      width: unset;
    }
  }
  @media (min-width: 992px) {
    .header.transparent-header .main-menu-area .logo {
      margin-left: 0px !important;
      padding: 0;
    }
  }
  @media (min-width: 992px) {
    .header.transparent-header .main-menu-area {
      padding: 0 90px 0 90px;
    }
  }
  .header.transparent-header .main-menu-area.fixed-menu {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 99;
    background-color: #fff;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.04);
  }
  .header.transparent-header .main-menu-area.fixed-menu .logo .navbar-brand {
    display: block;
  }
  .header.transparent-header .main-menu-area.fixed-menu .logo .navbar-brand2 {
    display: none;
  }
  .header.transparent-header .main-menu-area.fixed-menu .menu-bar span {
    background-color: #fff;
  }
  .header.transparent-header .main-menu-area.fixed-menu .nav-menu li a {
    color: #2e3c40;
  }
  .header.transparent-header .main-menu-area.fixed-menu .nav-menu li .submenu li a:hover {
    color: #008dd2  !important;
  }
  .header.transparent-header .main-menu-area.fixed-menu .nav-menu li.active > a, .header.transparent-header .main-menu-area.fixed-menu .nav-menu li:hover > a {
    color: #008dd2  !important;
  }
  .header.transparent-header .main-menu-area.fixed-menu .menu-action li a {
    color: #2e3c40;
  }
  .header.transparent-header .main-menu-area.fixed-menu .menu-action li a:hover {
    color: #008dd2;
  }
  .header.transparent-header .main-menu-area.fixed-menu .mobile-menu-area {
    height: 750px;
  }
  .header.transparent-header .nav-menu li a {
    color: #9a9a9a;
  }
  .header.transparent-header .nav-menu li > a.active, .header.transparent-header .nav-menu li:hover > a {
    color: #fff;
  }
  .header.transparent-header .menu-action li a {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .header.transparent-header .menu-action li a:hover {
    color: #b74acd;
  }
  .header.transparent-header .menu-action li a.join:hover {
    color: #fff !important;
  }
  
  .open-mobile-menu .nav-menu {
    -webkit-transform: scaleY(1) !important;
    -ms-transform: scaleY(1) !important;
    transform: scaleY(1) !important;
  }
  
  .header.style1 .main-menu-area {
    position: relative;
  }
  @media (min-width: 992px) {
    .header.style1 .main-menu-area {
      padding-left: 60px;
      padding-right: 60px;
    }
    .header.transparent-header .main-menu-area .logo {
      padding: 10px 0;
    }
  }
  @media (min-width: 1500px) {
    .header.style1 .main-menu-area {
      padding-left: 110px;
      padding-right: 110px;
    }
  }
  .header.style1 .main-menu-area .menu-bar {
    background-color: #008dd2;
  }
  .header.style1 .nav-menu li a {
    color: #9a9a9a;
  }
  .header.style1 .nav-menu li.active > a, .header.style1 .nav-menu li:hover > a {
    color: #fff;
  }
  .header.style1 .nav-menu li .submenu li a:hover, .header.style1 .nav-menu li .submenu li a.active-submenu {
    color: #008dd2;
  }
  .header.style1 .menu-action li a.discover {
    color: #fff;
  }
  .header.style1 .menu-action li a.join {
    color: #fff;
  }
  .header.style1 .fixed-menu .menu-action li a.da-custom-btn {
    color: #008dd2;
    border-color: #008dd2;
  }
  .header.style1 .fixed-menu .menu-action li a.da-custom-btn:hover {
    color: white;
  }



  .nav-menu li > a {
    padding: 30px 10px 30px 10px;
    font-size: 14px;
    display: block;
  }
  @media (min-width: 1300px) {
    .nav-menu li > a {
      padding: 30px 15px 30px 15px;
      font-size: 16px;
    }
  }
  @media (min-width: 1500px) {
    .nav-menu li > a {
      padding: 30px 25px 30px 25px;
    }
  }
  .nav-menu li > a i {
    margin-left: 5px;
    font-size: 90%;
    opacity: 0.7;
  }
  .nav-menu li.dropdown {
    position: relative;
  }
  .nav-menu li.dropdown .submenu {
    min-width: 100%;
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 150%;
    padding: 7px 0;
    text-align: center;
    list-style-type: none;
    opacity: 0;
    visibility: hidden;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.08);
    z-index: 2;
    transform-origin: left top;
  }
  @media (min-width: 992px) {
    .nav-menu li.dropdown .submenu {
      min-width: 230px;
      text-align: left;
    }
  }
  .nav-menu li.dropdown .submenu .submenu {
    left: 100%;
  }
  .nav-menu li.dropdown .submenu li a {
    padding: 10px 25px;
    display: block;
    font-size: 95%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
  }
  .nav-menu li.dropdown .submenu li a:hover {
    color: #008dd2;
    padding-left: 35px;
    background-color: #f9f9f9;
  }
  .nav-menu li.dropdown .submenu li a i {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -8px;
    opacity: 0.6;
  }
  .nav-menu li.dropdown:hover > .submenu {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    top: 100%;
    opacity: 1;
    visibility: visible;
  }
  .nav-menu li.dropdown .submenu > li.dropdown:hover > a {
    color: #008dd2;
    padding-left: 35px;
  }
  .nav-menu li.dropdown .submenu > li.dropdown:hover .submenu {
    top: 0;
  }
  .nav-menu li.dropdown.active > .submenu .active > a {
    color: #008dd2;
    padding-left: 35px;
  }
  .nav-menu li.dropdown .submenu .active > a {
    color: #008dd2 !important;
  }




  .menu-action li {
    margin: 0 6px;
  }
  @media (min-width: 1300px) {
    .menu-action li {
      margin: 0 8px;
    }
  }
  .menu-action li:last-child {
    margin-right: 0;
  }
  .menu-action li:first-child {
    margin-left: 0;
  }
  .menu-action li a.da-custom-btn {
    border: 2px solid white;
    color: white !important;
    padding: 12px 30px;
    font-size: 15px;
  }
  .menu-action li a.da-custom-btn:hover {
    border-color: #008dd2;
    background-color: #008dd2;
  }
  .menu-action li a.da-custom-btn:hover:after {
    background-color: #008dd2;
  }
  
  /* .header.style1 .menu-action li a.da-custom-btn:hover:after {
    background-color: white;
  } */
  .header.style1 .menu-action li {
    margin: 0 6px;
  }
  @media (min-width: 1300px) {
    .header.style1 .menu-action li {
      margin: 0 8px;
    }
  }
  .header.style1 .menu-action li:last-child {
    margin-right: 0;
  }
  .header.style1 .menu-action li:first-child {
    margin-left: 0;
  }





  
.menu-bar {
  width: 40px;
  height: 40px;
  background-color: #008dd2;
  padding: 10px;
  position: relative;
  right: 20px;
  cursor: pointer;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.menu-bar span {
  width: 20px;
  height: 2px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -1px;
  background-color: #fff;
}
.menu-bar span:nth-child(1) {
  margin-top: -7px;
}
.menu-bar span:nth-child(3) {
  margin-top: 5px;
}

.open-mobile-menu .menu-bar span {
  transform-origin: center;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.open-mobile-menu .menu-bar span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-top: 0;
}
.open-mobile-menu .menu-bar span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-top: 0;
}
.open-mobile-menu .menu-bar span:nth-child(2) {
  opacity: 0;
}

.close-button {
  width: 40px;
  height: 40px;
  background-color: transparent;
  padding: 10px;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #5ec0e7;
  margin-top: 10px;
  right: 10px;
}
.close-button span {
  width: 20px;
  height: 2px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -1px;
  background-color: #fff;
  transform-origin: center;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.close-button span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-top: 0;
}
.close-button span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-top: 0;
}
.close-button span:nth-child(2) {
  opacity: 0;
}





.mobile-menu-area {
  max-width: 400px;
  width: 50%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  height: 100%;
  z-index: 999;
  transform: translate3d(-100%, 0, 0) scaleX(0.5);
  transform-origin: left;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.mobile-menu-area .m-menu-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
}
.mobile-menu-area .m-menu-header .close-bar {
  position: relative;
  width: 35px;
  height: 35px;
  margin-right: 25px;
  cursor: pointer;
  background-color: #008dd2;
  border-radius: 50%;
  transform: translateX(-30px);
  opacity: 0;
  transition: 0.5s ease;
}
.mobile-menu-area .m-menu-header .close-bar span {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -1px;
  margin-left: 7px;
  width: 60%;
  height: 2.5px;
  background-color: white;
  transform-origin: center;
}
.mobile-menu-area .m-menu-header .close-bar span:first-child {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.mobile-menu-area .m-menu-header .close-bar span:last-child {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.mobile-menu-area .mobile-menu {
  height: calc(100% - 60px );
  overflow-y: auto;
}
.mobile-menu-area .mobile-menu .m-menu {
  margin: 0;
  padding-bottom: 30px;
  padding-left: 0;
  list-style: none;
}
.mobile-menu-area .mobile-menu .m-menu li a {
  display: block;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 30px;
  position: relative;
  border-bottom: 1px solid #fafafa;
  text-transform: uppercase;
  text-align: left;
  color: #677294;
}
/* .mobile-menu-area .mobile-menu .m-menu > li > a.active {
  color: #008dd2;
} */
.mobile-menu-area .mobile-menu .m-menu li a .arrow {
  width: 30px;
  height: 30px;
  background-color: rgba(46, 60, 64, 0.01);
  text-align: center;
  line-height: 30px;
  color: #2e3c40;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  position: absolute;
  right: 25px;
  top: 50%;
  margin-top: -15px;
  font-weight: 200;
}
.mobile-menu-area .mobile-menu .m-menu li a .arrow svg {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.mobile-menu-area .mobile-menu .m-menu li .mobile-submenu {
  display: none;
  list-style: none;
  margin: 0;
  padding-left: 20px;
}
.mobile-menu-area .mobile-menu .m-menu li .mobile-submenu.actives {
  display: block;
}
.mobile-menu-area .mobile-menu .m-menu li .mobile-submenu li a {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  font-family: "Rubik", sans-serif;
  color: #677294;
}
.mobile-menu-area .mobile-menu .m-menu li > a.open .arrow svg {
  transform: rotate(90deg);
}
.mobile-menu-area .mobile-menu .m-menu li.open > .mobile-submenu {
  display: block;
}
.mobile-menu-area .mobile-menu .m-menu li.m-active > a {
  color: #008dd2;
}
.mobile-menu-area .mobile-menu .m-menu li.m-active > a .arrow {
  color: #008dd2;
}
.mobile-menu-area .mobile-menu .m-menu > li {
  transform: translateX(-30px);
  transition: 0.7s ease;
  opacity: 0;
}

.open-mobile-menu {
  position: relative;
  overflow: hidden;
}
.open-mobile-menu .menu-bar {
  opacity: 0.1;
}
.open-mobile-menu .close-bar {
  transform: translateX(0px) !important;
  opacity: 1 !important;
  transition-delay: 0.2s !important;
}
.open-mobile-menu .mobile-menu-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(46, 60, 64, 0.9);
  z-index: 99;
  overflow: hidden;
}
.open-mobile-menu .mobile-menu-area {
  transform: translate3d(0%, 0, 0) scaleX(1);
  width: 70%;
  animation: scale-easeOutElastic;
}
.open-mobile-menu .mobile-menu-area .m-menu > li {
  transform: translateX(0px);
  transition-delay: 0s;
  opacity: 1;
}
.open-mobile-menu .mobile-menu-area .m-menu > li:nth-child(1) {
  transition-delay: 0.2s;
}
.open-mobile-menu .mobile-menu-area .m-menu > li:nth-child(2) {
  transition-delay: 0.3s;
}
.open-mobile-menu .mobile-menu-area .m-menu > li:nth-child(3) {
  transition-delay: 0.4s;
}
.open-mobile-menu .mobile-menu-area .m-menu > li:nth-child(4) {
  transition-delay: 0.5s;
}
.open-mobile-menu .mobile-menu-area .m-menu > li:nth-child(5) {
  transition-delay: 0.6s;
}
.open-mobile-menu .mobile-menu-area .m-menu > li:nth-child(6) {
  transition-delay: 0.7s;
}
.open-mobile-menu .mobile-menu-area .m-menu > li:nth-child(7) {
  transition-delay: 0.8s;
}
.open-mobile-menu .mobile-menu-area .m-menu > li:nth-child(8) {
  transition-delay: 0.9s;
}
.open-mobile-menu .mobile-menu-area .m-menu > li:nth-child(9) {
  transition-delay: 1s;
}

@keyframes scale-easeOutElastic {
  0% {
    transform: scale(1);
  }
  16% {
    transform: scale(-0.32);
  }
  28% {
    transform: scale(0.13);
  }
  44% {
    transform: scale(-0.05);
  }
  59% {
    transform: scale(0.02);
  }
  73% {
    transform: scale(-0.01);
  }
  88% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}