


.our-mission-section {
    background-color: #008dd2;
  }
.our-mission-section h2 {
    font-size: 25px;
  }
  @media (min-width: 768px) {
    .our-mission-section h2 {
      font-size: 28px;
    }
  }
  @media (min-width: 992px) {
    .our-mission-section h2 {
      font-size: 36px;
    }
  }