.slider-area {
    position: relative;
  }
  
  .bz-banner-slider-container {
    overflow: hidden;
  }
  .bz-banner-slider-container .banner-slide-next-btn, .bz-banner-slider-container .banner-slide-prev-btn {
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background-color: rgba(255, 99, 96, 0.6);
    position: absolute;
    top: 50%;
    margin-top: -25px;
    z-index: 2;
    outline: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: none;
  }
  @media (min-width: 576px) {
    .bz-banner-slider-container .banner-slide-next-btn, .bz-banner-slider-container .banner-slide-prev-btn {
      display: block;
    }
  }
  @media (min-width: 768px) {
    .bz-banner-slider-container .banner-slide-next-btn, .bz-banner-slider-container .banner-slide-prev-btn {
      width: 50px;
      height: 50px;
      line-height: 50px;
    }
  }
  .bz-banner-slider-container .banner-slide-next-btn.swiper-button-disabled, .bz-banner-slider-container .banner-slide-prev-btn.swiper-button-disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
  .bz-banner-slider-container .banner-slide-next-btn:hover, .bz-banner-slider-container .banner-slide-prev-btn:hover {
    background-color: #008dd2;
  }
  .bz-banner-slider-container .banner-slide-prev-btn {
    right: 0;
  }
  .bz-banner-slider-container .banner-slide-next-btn {
    left: 0;
  }
  .banner-slider .carousel-item {
    overflow: hidden;
    position: relative;
    z-index: 2;
  }
  @media (min-width: 991) {
    .banner-slider .carousel-item {
      height: 100vh;
    }
  }
  .banner-slider .carousel-item:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 2;
  }
  .banner-slider .carousel-item:nth-child(1) slider-content {
    text-align: center;
  }
  @media (min-width: 992px) {
    .banner-slider .carousel-item:nth-child(1) slider-content {
      text-align: left;
    }
  }
  .banner-slider .carousel-item:nth-child(2) slider-content {
    text-align: center;
  }
  @media (min-width: 992px) {
    .banner-slider .carousel-item:nth-child(2) slider-content {
      text-align: left;
    }
  }
  .banner-slider .carousel-item:nth-child(3) slider-content {
    text-align: center;
  }
  @media (min-width: 992px) {
    .banner-slider .carousel-item:nth-child(3) slider-content {
      text-align: left;
    }
  }
  .banner-slider .carousel-item .carousel-caption {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-38%);
    -ms-transform: translateY(-38%);
    transform: translateY(-38%);
    width: 100%;
    z-index: 3;
  }
  @media (min-width: 992px) {
    .banner-slider .carousel-item .carousel-caption {
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  .banner-slider .carousel-item .carousel-caption {
    padding: 0 20px;
    text-align: center;
  }
  @media (min-width: 992px) {
    .banner-slider .carousel-item .carousel-caption {
      text-align: left;
    }
  }
  @media (width: 1024px) {
    .banner-slider .carousel-item .carousel-caption {
      text-align: center;
    }
  }
  @media (min-width: 768px) {
    .banner-slider .carousel-item .carousel-caption {
      padding: 0;
    }
  }
  .banner-slider .carousel-item .carousel-caption .large-text {
    -webkit-transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: 600;
    line-height: 1.25;
    color: #fff;
    margin-bottom: 30px;
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  @media (min-width:768px) {
    .banner-slider .carousel-item .carousel-caption .large-text {
      font-size: 30px;
    }
  }
  @media (min-width: 992px) {
    .banner-slider .carousel-item .carousel-caption .large-text {
      font-size: 36px;
    }
  }
  @media (min-width: 1300px) {
    .banner-slider .carousel-item .carousel-caption .large-text {
      font-size: 42px;
    }
  }
  @media (min-width: 1500px) {
    .banner-slider .carousel-item .carousel-caption .large-text {
      font-size: 48px;
    }
  }
  .banner-slider .carousel-item .carousel-caption .small-text {
    -webkit-transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
    display: block;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.7;
    margin-bottom: 40px;
    color: #fff;
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
    display: none;
  }
  @media (min-width: 992px) {
    .banner-slider .carousel-item .carousel-caption .small-text {
      font-size: 18px;
      display: block;
    }
  }
  .banner-slider .carousel-item .carousel-caption .video-button-area {
    opacity: 0;
    -webkit-transition: all 0.8s ease-in-out;
    -moz-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out;
    -webkit-transform: translateY(60px);
    -moz-transform: translateY(60px);
    -ms-transform: translateY(60px);
    -o-transform: translateY(60px);
    transform: translateY(60px);
    display: none;
  }
  @media (min-width: 768px) {
    .banner-slider .carousel-item .carousel-caption .video-button-area {
      display: block;
    }
  }
  .banner-slider .carousel-item .carousel-caption .video-button-area span {
    color: #fff;
  }
  .banner-slider .carousel-item .carousel-caption .video-button-area .video-button:hover {
    color: inherit;
  }
  .banner-slider .carousel-item .carousel-caption .custom-btn {
    padding: 15px 45px;
    background-color: #fff;
    color: #333;
    border-radius: 30px;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: none;
  }
  @media (min-width: 768px) {
    .banner-slider .carousel-item .carousel-caption .custom-btn {
      display: inline-block;
    }
  }
  .banner-slider .carousel-item .carousel-caption .video-button:hover,
  .banner-slider .carousel-item .carousel-caption .custom-btn:hover {
    background-color: #008dd2;
    color: #fff;
  }
  .banner-slider .carousel-item .carousel-caption .button-area {
    opacity: 0;
    -webkit-transition: all 0.8s ease-in-out;
    -moz-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out;
    -webkit-transform: translateY(60px);
    -moz-transform: translateY(60px);
    -ms-transform: translateY(60px);
    -o-transform: translateY(60px);
    transform: translateY(60px);
  }
  .banner-slider .carousel-item img{
    transition: all 15s ease;
  }
  .banner-slider .carousel-item.active img {
    transform: scale(1.5);
  }
  .banner-slider .carousel-item.active .large-text {
    opacity: 1;
    -webkit-transition-delay: 0.6s;
    -moz-transition-delay: 0.6s;
    -ms-transition-delay: 0.6s;
    -o-transition-delay: 0.6s;
    transition-delay: 0.6s;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
  .banner-slider .carousel-item.active .small-text {
    opacity: 1;
    -webkit-transition-delay: 0.9s;
    -moz-transition-delay: 0.9s;
    -ms-transition-delay: 0.9s;
    -o-transition-delay: 0.9s;
    transition-delay: 0.9s;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
  .banner-slider .carousel-item.active .video-button-area {
    padding-top: 30px;
    opacity: 1;
    -webkit-transition-delay: 1.2s;
    -moz-transition-delay: 1.2s;
    -ms-transition-delay: 1.2s;
    -o-transition-delay: 1.2s;
    transition-delay: 1.2s;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
  .banner-slider .carousel-item.active .button-area {
    opacity: 1;
    -webkit-transition-delay: 1.1s;
    -moz-transition-delay: 1.1s;
    -ms-transition-delay: 1.1s;
    -o-transition-delay: 1.1s;
    transition-delay: 1.1s;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }



  .banner-slider .carousel-item .carousel-caption {
    position: absolute;
    left: 0;
    bottom: auto;
    width: 100%;
    transform: translateY(-50%);
    top: 50%;
  }


  
.video-button {
  width: 80px;
  height: 80px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
  line-height: 82px;
  color: #008dd2;
  background-color: #fff;
  display: inline-block;
  font-size: 24px;
  margin-right: 35px;
  position: relative;
  border: none;
  outline: none;
}
@media (min-width: 1500px) {
  .video-button {
    width: 100px;
    height: 100px;
    line-height: 95px;
  }
  .video-button svg {
    font-size: 30px;
    margin-left: 5px;
  }
}

.effect-dhew {
  overflow: visible;
}
.effect-dhew .anim1 {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #008dd2;
  z-index: -1;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  animation: dhew 2s infinite;
  animation-timing-function: ease-out;
}
.effect-dhew .anim2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #008dd2;
  z-index: -1;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  animation: dhew 3s infinite;
  animation-timing-function: ease-out;
}
.effect-dhew .anim3 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #008dd2;
  z-index: -1;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  animation: dhew 3s infinite;
  animation-timing-function: ease-out;
  animation-delay: 1s;
}
.effect-dhew .anim4 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #008dd2;
  z-index: -1;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  animation: dhew 5s infinite;
  animation-delay: 1s;
}

@keyframes dhew {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}




/* slider controller  */
.banner-slider .carousel-control-next, .banner-slider .carousel-control-prev {
  width: 50px;
  height: 50px;
  bottom: auto;
  top: 50%;
  margin-top: -25px;
  z-index: 2;
  background-color: #008dd2;
  border-radius: 3px;
  display: none;
}
.banner-slider .carousel-control-next {
  right: 10px;
}
.banner-slider .carousel-control-prev {
  left: 10px;
}



@media (min-width: 991px) {
  .banner-slider .carousel-control-next, .banner-slider .carousel-control-prev {
    display: flex;
  }
  .banner-slider .carousel-indicators {
    display: none;
  }
}